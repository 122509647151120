.needSpaceFromTop {
    margin-top: 2.5rem;
}
.BankAccForm {
    padding: 22px 36px 30px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 10px 0 rgba(112, 144, 176, 0.16);
    cursor: pointer;
    .Top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Left {
            display: flex;
            align-items: center;
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            column-gap: 10px;
            .bankName {
                margin: 0;
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
            .deleteIcon {
                width: 27px;
                height: 27px;
                box-shadow: 0px 2px 8px 0px #00000013;
                background-color: #ffffff;
                position: relative;
                cursor: pointer;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .ODaccount {
                background-color: #f1f6ff;
                padding: 5px 10px;
                border-radius: 7px;
                width: 106px;
                height: 27px;
                text-align: center;
                position: relative;
                div {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        font-family: gilroy-medium, sans-serif;
                        color: #000000;
                        margin: 0;
                    }
                }
            }
        }

        .Right {
            display: flex;
            align-items: center;

            .Edit {
                margin-top: 4px;
                cursor: pointer;
                border: none;
                background-color: white;
                color: #2f8fff;
                font-family: 'Gilroy-Medium';
                font-size: 16px;
                margin-right: 20px;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.mutualFundsForm {
    padding: 22px 36px 30px;
    margin: 30px 0;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 10px 0 rgba(112, 144, 176, 0.16);
    cursor: pointer;
    .Top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Left {
            display: flex;
            align-items: center;
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            column-gap: 10px;
            .bankName {
                margin: 0;
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
            .deleteIcon {
                width: 27px;
                height: 27px;
                box-shadow: 0px 2px 8px 0px #00000013;
                background-color: #ffffff;
                position: relative;
                cursor: pointer;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .ODaccount {
                background-color: #f1f6ff;
                padding: 5px 10px;
                border-radius: 7px;
                width: 106px;
                height: 27px;
                text-align: center;
                position: relative;
                div {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        font-family: gilroy-medium, sans-serif;
                        color: #000000;
                        margin: 0;
                    }
                }
            }
        }

        .Right {
            display: flex;
            align-items: center;

            .Edit {
                margin-top: 4px;
                cursor: pointer;
                border: none;
                background-color: white;
                color: #2f8fff;
                font-family: 'Gilroy-Medium';
                font-size: 16px;
                margin-right: 20px;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.RightWrapper {
    column-gap: 20px;
}

.NoCard {
    padding: 0px;
    box-shadow: none;
}

.RemoveButton {
    margin-left: 15px;
    margin-bottom: 2px;
    border: none;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 4px 16px;
    color: #eb4a51;
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
}

.FormDiv {
    position: relative;

    .FormInput {
        padding: 10px;
        margin-bottom: 25px;
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
        outline: none;
        border: none;
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        border-radius: 6px;
        background-color: #f9f9f9;

        &:focus,
        &:valid {
            border: 0.5px solid #4c8dff;
            background-color: #ffffff;
        }

        &:focus ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }

        &:valid ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
        &:disabled ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
    }

    .FormLabel {
        position: absolute;
        top: 11px;
        pointer-events: none;
        left: 10px;
        font-size: 12px;
        font-weight: 300;
        color: #c3c3c3;
        transition: 0.5s;
    }

    .SelectDiv {
        border-radius: 6px;
        background-color: #f8fff8;
        padding: 10px;
        margin-bottom: 40px;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
    }

    .FormSelect {
        padding: 10px;
        margin-bottom: 25px;
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
        outline: none;
        border: none;
        font-weight: 300;
        color: #c3c3c3;
        background-color: #f9f9f9;
        font-size: 12px;
        border-radius: 6px;
        height: 38px;

        & ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }

        & * {
            color: #000000;
            background-color: '#FFFFFF';
        }
    }

    .FormSelectValue {
        border: 0.5px solid #4c8dff;
        font-weight: 500;
        color: #000000;
        background-color: #ffffff;
    }
}

.FetchRetry {
    border: none;
    color: #4c8dff;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
}

.FetchBtn {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #c3c3c3;
    font-size: 12px;
    font-weight: 300;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
}

.FetchBtnBlue {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #4c8dff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.FetchBtnGreen {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #00bd00;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #fcfcfc;
}

.Detail {
    color: #14143c;
    font-size: 16px;
    font-family: gilroy-medium, sans-serif;
    font-weight: 600;
}

.OdfdContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}
.Odfd {
    color: #14143c;
    font-size: 12px;
    font-family: 'Gilroy-Medium';
    line-height: 1.5rem;
    margin: 1.5%;
    align-items: center;
    display: flex;
}

.OdfdDisabled {
    color: rgba(22, 21, 19, 0.4);
    font-size: 12px;
    font-family: 'Gilroy-Medium';
    line-height: 17px;
    margin: 1.5%;
    align-items: center;
    display: flex;
}

.OdOrFdContainer {
    margin: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    padding: 24px;
    border-radius: 5px;
}

.DocumentText {
    color: #14143c;
    font-size: 12px;
    font-family: 'Gilroy-Semibold';
    line-height: 20px;
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
}

.lienAmount {
    margin-right: 0rem;
    position: relative;
}

.Blue {
    color: #4c8dff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.UploadManuallyBlue {
    color: #2f8fff;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.UploadBankStmtText {
    color: #000;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
}
.fetchText {
    color: #007bff;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    padding: 5px;
}

.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.noOfAccound {
    text-align: center;
    padding: 8px 10px;
    background-color: #efefef;
    border-radius: 7px;
    margin-bottom: 30px;
    p {
        margin: 0;
        font-family: gilroy-medium, sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        &[aria-label='grey'] {
            color: #979797;
        }
        &[aria-label='black'] {
            color: #000000;
        }
    }
}
.netbanking {
    padding: 10px 30px;
    background: #2f8fff;
    border: 1px solid #2f8fff;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    width: 100%;
    font-family: gilroy-medium, sans-serif;
    &:disabled {
        border: 1px solid #d8d8d8;
        background: #d8d8d8;
        color: #ffffff;
        cursor: not-allowed;
    }
}
.manually {
    padding: 10px 30px;
    background: #ffffff;
    border: 1px solid #2f8fff;
    color: #2f8fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    width: 100%;
    font-family: gilroy-medium, sans-serif;
    &:disabled {
        border: 1px solid #d8d8d8;
        color: #d8d8d8;
        cursor: not-allowed;
    }
}
.DetailWrapper {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    column-gap: 10px;
    padding: 10px;
    background-color: #f1f6ff;
    border-radius: 5px;
    img {
        width: 20px;
        height: 20px;
    }
    p {
        color: #000;
        font-family: gilroy-medium, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
    }
}
.checkBoxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #f9f9f9;
    padding: 12px 30px;
    border-radius: 4px;
    input[type='checkbox'] {
        border: 1px solid #2f8fff;
    }
    label {
        color: #000;
        font-family: gilroy-medium, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin: 0;
        cursor: pointer;
        user-select: none;
    }
}

.AnimateLeft {
    animation: slideLeft 500ms linear;
}

.AnimateRight {
    animation: slideRight 500ms linear;
}

@keyframes slideLeft {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }

    40% {
        opacity: 0;
        transform: translateX(-50px);
    }

    60% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 0;
        transform: translateX(50px);
    }

    60% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
