.mainContainer {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: white;
    visibility: hidden;
    .hubspotChat {
        height: 500px;
        width: 300px;
    }
}

.closeButton {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    border: 1px solid #cdd9e4;
    background-color: #fff;
    border: none;
    div {
        display: flex;
        align-self: center;
        justify-content: center;
    }
}
