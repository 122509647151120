.Heading {
    background-color: #f9f9f9;
    z-index: 1;
    margin-left: -65px;
    padding: 0px;
    height: 100vh;
    .HeadingBox {
        padding-left: 65px;
        background-color: #f9f9f9;
    }
}
.HeadingText {
    color: #233299;
    font-size: 28px;
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    font-family: 'Gilroy-Bold';
}
