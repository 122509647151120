.mobileNavHeader {
    display: none;
}
@media only screen and (max-width: 1025px) {
    .mobileNavHeader {
        position: sticky;
        top: 0;
        background-color: white; // Set the background color as needed
        z-index: 1000; // Set an appropriate z-index value
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add a box shadow
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }
}
