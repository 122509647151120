@import '../../../../../sass/variables';
.Font_Family_Bold {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    margin-bottom: 15px;
}
.Font_Family_Bold_Investor {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    margin-bottom: 15px;
    color: $investor;
}
.PayType {
    color: #323232;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
}
.CardHeader {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    letter-spacing: 0.000319489px;
    color: #000000;
}
.RecentRepaymentCardAmount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.RecentRepaymentLeftValue {
    display: flex;
    align-items: center;
}

.DetailsHeader {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-right: 10px;
    letter-spacing: 0.000279553px;
    color: #323232;
}
.RepaymentCount {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.000239617px;
    color: #979797;
    color: #6b6a6a;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    float: left;
    padding-top: 4px;
    min-width: 80px;
}
.Line {
    border-right: 1px solid #000000;
    height: 24px;
    margin: 0 14px;
}
.PayValue {
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
}
.ShowAll {
    color: #2f8fff;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    cursor: pointer;
}
.OverviewDropDown {
    height: 34.5px;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 205.5px;
    padding: 0 7px;
    border: 0.5px solid #4c8dff;
    border-radius: 4px;
    position: relative;
    img {
        width: 16px;
    }
}
.OverviewDropDownOpen {
    width: 205px;
    margin-top: 4px;
    position: absolute;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    padding: 10px 0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    z-index: 1;
    div {
        margin-bottom: 7px;
        padding: 5px 10px;
        &:hover {
            background-color: #f2f2f2;
        }
    }
}
.RightArrow {
    transform: rotate(270deg);
}
.LeftValue {
    min-width: 100px;
    width: fit-content;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.000279553px;
    color: #000000;
}
.NoActiveTrade {
    height: 177px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    margin: 25px 35px 25px 25px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
}

.barPrincipal {
    background: #d3b3ff;
}

.barOverdue {
    background: #ffc9b8;
}

.barLegends {
    width: 7px;
    height: 7px;
    display: inline-block;
    margin-right: 3px;
}

.legendsStyle {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 340px;
    float: right;
}

.barLegendsText {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    display: inline-block;
    align-items: center;
    letter-spacing: 0.000279553px;

    color: #000000;
}
.redColor {
    color: #df666a;
}
.RightArrowButton {
    border: none;
    flex-direction: left;
    background: transparent;
    margin-right: 30px;
    cursor: pointer;
}
.RighButtonImg {
    display: block;
    width: 5px;
    height: 5px;
}
.ProgressValue {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 16.0001px;
    color: #323232;
}
.ProgressPercentage {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #03c203;
}
.circularProgressbarDottedCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    border: 2px dotted #a5a5a5;
    border-radius: 80%;
    pointer-events: none;
    box-sizing: border-box;
    clip-path: polygon(0% 0%, 100% 0%, 100% 93%, 0% 95%);
}

.RepaymentValue {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.000126397px;
    color: #161513;
    min-width: 90px;
    text-align: left;
    padding-top: 4px;
    padding-left: 4px;
}
.UpcommingRepaymentValue {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.000126397px;
    color: #161513;
    min-width: 90px;
    text-align: left;
    padding-top: 4px;
    justify-content: left;
}
.ViewAllButton {
    flex-grow: 1;
    width: 100%;
    height: 40px;
    border: 0.5px solid #6021b3;
    border-radius: 4px;
    color: #6021b3;
    background-color: #ffffff;
}
.RecentRepaymentValue {
    width: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Pay {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    height: 50px;
    background-color: #f7f7f7;
    border-radius: 4px;
    margin-top: 20px;
    padding: 10px;
}
.Card {
    width: 4rem; /* Width of 4 */
    min-width: 200px; /* Fixed minimum width */

    /* Other card styles */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.DelayTooltip {
    font-size: 10px;
    font-family: gilroy-semibold;
    border-radius: 4px !important;
    box-shadow: 1.5px 1px 1.5px 1px #ccc;
}

.displayBlock {
    display: block;
}

.paddingBottom {
    padding-bottom: 25px;
}
