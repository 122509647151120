// theme colors
$black: #000000;
$white: #ffffff;
$investee: #2f8fff;
$investor: #521b97;

// color palette
$colors: (
    'black': $black,
    'white': $white,
    'investee': $investee,
    'investor': $investor,
    'grey1': #e6e6e6,
    'grey2': #f8f8f8,
    'grey3': #f2ffec,
    'grey4': #f7f7f7,
    'grey5': #fff7e9,
    'grey6': #f1f1f1,
    'grey7': #b9b9b9,
    'grey8': #bcbcbc,
    'grey9': #c7c7c7,
    'grey10': #a2a2a2,
    'grey11': #a4a4a4,
    'grey12': #b79cdd,
    'grey13': #999999,
    'grey14': #7f7f7f,
    'grey15': #8d8d8d,
    'grey16': #707070,
    'grey17': #696969,
    'grey18': #626262,
    'grey19': #575757,
    'grey20': #7c7c7c,
    'grey21': #414141,
    'grey22': #4f4f4f,
    'grey23': #343434,
    'grey24': #aaaaaa,
    'grey25': #f0f0f0,
    'grey26': #686868,
    'grey27': #535b62,
    'grey28': #9c9c9c,
    'grey29': #606060,
    'grey30': #939393,
    'grey31': #f9f9f9,
    'grey32': #909090,
    'black1': #1c1c1c,
    'black2': #070707,
    'blue1': #20c1c3,
    'blue2': #20c1c3,
    'blue3': #75c2ff,
    'blue4': #4c8dff,
    'blue5': #345b9c,
    'blue6': #233396,
    'blue7': #00457f,
    'blue8': #14143c,
    'blue9': #233299,
    'blue10': #004071,
    'green1': #72ff8c,
    'green2': #58d76b,
    'green3': #00bd00,
    'green4': #00ad00,
    'green5': #00aa00,
    'green6': #4cb71a,
    'green7': #49a71b,
    'yellow1': #fcbf07,
    'yellow2': #f2b216,
    'yellow3': #ffe234,
    'yellow4': #bd903a,
    'orange1': #d09413,
    'orange2': #ff6600,
    'pink1': #d95656,
    'pink2': #d06d6d,
    'red1': #d70505,
    'red2': #ba0000,
    'red3': #ce121d,
    'red4': #c80000,
    'swift': #ab3fc0,
    'scale': #6b3af8,
);

// spacing
$base-padding: 2px !default;
$base-margin: 2px !default;

// borders
$base-border-thickness: 1px !default;
$base-border-radius: 2px !default;

// box-shadow
$base-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06) !default;

// font sizes
$base-font-size: 2px !default;

$colorLightRed: #df666a;
$colorGrey: #989898;
