.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: rgb(20, 20, 60);
    font-size: 16px;
    font-weight: 600;
}

.FormContainer {
    .Field {
        position: relative;
        margin-top: 30px;

        .FormInput {
            padding: 16px 12px;
            width: 100%;
            box-sizing: border-box;
            box-shadow: none;
            outline: none;
            border: none;
            color: #000000;
            font-size: 12px;
            font-weight: 500;
            border-radius: 6px;
            background-color: #f9f9f9;
            border: 0.5px solid transparent;
            z-index: 1;

            // border-color: ${(props) => (props.errorBorder ? '#CE121D !important' : 'none')};
            &:focus,
            &:valid {
                border: 0.5px solid #4c8dff;
                background-color: #ffffff;
            }

            &:focus ~ label {
                top: -15px;
                left: 0;
                font-size: 10px;
                color: #8d8d8d;
                font-weight: 300;
            }

            &:valid ~ label {
                top: -15px;
                left: 0;
                font-size: 10px;
                color: #8d8d8d;
                font-weight: 300;
            }

            &:-webkit-autofill {
                box-shadow: 0 0 0 30px white inset;
                -webkit-box-shadow: 0 0 0 30px white inset;
            }

            &:disabled {
                background-color: #f8fff8 !important;
                border: 0.5px solid white;
                box-shadow: 0 0 0 30px #f8fff8 inset;
            }
        }

        .InputError {
            border-color: #ce121d !important;
        }

        .FormLabel {
            position: absolute;
            pointer-events: none;
            left: 10px;
            transition: 0.5s;
            top: 16px;
            font-size: 12px;
            color: #c3c3c3;
            // top: ${(props) => (props.toDisableOrNot || props.compName ? '-15px' : '16px')};
            // font-size: ${(props) => (props.toDisableOrNot || props.compName ? '10px' : '12px')};
            font-weight: 300;
            // color: ${(props) => (props.toDisableOrNot || props.compName ? '#414141' : '#C3C3C3')};
            transition: 0.5s;
            margin-bottom: 0px;
        }

        .LabelTop {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }

        .MultipleGstins {
            position: absolute;
            right: 10px;
            height: 100%;
            top: 0;
            transition: 0.5s;
            font-size: 10px;
            font-family: 'Gilroy-SemiBold';
            color: #4c8dff;

            cursor: pointer;

            & > span {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}

.VerifyButton {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 500;
    margin: 30px 0 24px 0;
}

.VerifyDisabled {
    background-color: #d9d9d9;
    color: #ffffff;
    cursor: not-allowed;
    pointer-events: none;
}

.VerifyEnabled {
    color: #ffffff;
    background-color: #4c8dff;
    cursor: pointer;
}

.VerifyComplete {
    color: #00bd00;
    // font-weight: 500;
    box-shadow: none;
    background-color: #fcfcfc;
    cursor: not-allowed;
    pointer-events: none;
}

.VerifyLoading {
    color: #ffffff;
    background-color: black;
    cursor: not-allowed;
}

.DirectorDetailsContainerAnimation {
    transform: translateY(0%);
    animation: slideUp 0.5s ease-in-out;
}
.DirectorDetailsContainer {
    position: relative;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 2.5rem;
    width: 100%;
    box-shadow: rgba(112, 144, 176, 0.16) 0px 1px 10px 0px;
}
@keyframes slideUp {
    0% {
        transform: translate(0%, 200%); /* Start off-screen at the bottom */
    }
    100% {
        transform: translate(0%, 60); /* Slide to the top */
    }
}
.FetchDetailAnimation {
    position: absolute;
    visibility: hidden;
    top: 1000%;
    width: 100%;
    transition: all ease-in 2s;
}
.AnimationContainer {
    position: absolute;
    visibility: hidden;
    top: 100%;
    width: 42%;
    transition: bottom ease-in 0.2s;
}
.AnimationContainerShow {
    position: absolute;
    top: 42%;
    visibility: visible;
    width: 42%;
    transition: top ease-out 2s;
}

.Badge {
    font-family: 'Gilroy-SemiBold';
    position: absolute;
    right: 22px;
    top: 16px;
    font-size: 12px;
}

.NotVerified {
    color: #ce121d;
}

.UnderVerification {
    color: #787878;
}

.Verified {
    color: #00bd00;
}

.Invalid {
    color: #ce121d;
}

.CallSchedule {
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    color: #7f7f7f;

    a {
        color: #2f8fff !important;
        cursor: pointer;
        margin: 0 3px;

        &:hover {
            color: #184d89 !important;
        }
    }
}

.Hide {
    display: none;
}

.Spin {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
