.InviteTeamMember {
    background: #f9f9f9;
    border: 0;
    font-size: 12px !important;
    height: 44px;
    padding: 10px 15px;
    font-family: 'Gilroy-SemiBold', serif;
    border-radius: 6px;
    margin: 15px 0;
    display: flex;
    align-items: center;
    outline: none !important;
}
.InviteTeamMemberOthers {
    background: #f9f9f9;
    border: 0;
    font-size: 12px !important;
    height: 44px;
    padding-left: 15px;
    font-family: 'Gilroy-SemiBold', serif;
    border-radius: 6px;
    outline: none !important;
}
.IndividualCoborrower {
    padding: 16px 12px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-family: gilroy-bold, sans-serif;
    box-shadow: none;
    outline: none;
    border: 0.5px solid #cecece;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
}
.IndividualCoborrowerOther {
    padding: 16px 12px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-family: gilroy-bold, sans-serif;
    box-shadow: none;
    outline: none;
    border: 0.5px solid #cecece;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
}
.AuthSignatory {
    padding: 10px;
    margin-bottom: 30px !important;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    border: 0.5px solid #f9f9f9;
    background-color: #f9f9f9;
}
.OtherAuthSignatory {
    padding: 10px;
    margin-bottom: 30px !important;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    border: 0.5px solid #f9f9f9;
    background-color: #f9f9f9;
}
