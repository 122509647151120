.NavContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    background-color: #f9f9f9;
    padding: 2rem;
}

.UserNameText {
    font-family: Gilroy-bold, sans-serif;
    font-size: 28px;
    color: #233299;
}

.SearchNotificationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2rem;
}

.NavSearchBar {
    color: rgba(91, 86, 82, 1);
    font-family: Gilroy-medium, sans-serif;
    font-size: 16px;
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    background: url('https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/SearchIcon.svg') no-repeat
        #efefef;
    background-size: 1rem;
    background-position: 2% 48%;
    padding: 0.5rem 2.5rem 0.5rem 1.5rem;
}

.NotificationsButton {
    margin-left: 1rem;
    font-family: gilroy-semibold, sans-serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
    cursor: pointer;
    position: relative;
    padding: 0.5rem;
    &:hover {
        border-radius: 8px;
        background-color: #efefef;
    }
}

.UserNameBlock {
    background-color: black;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    font-size: 12px;
    align-items: center;
    color: white;
    border-radius: 6px;
    font-family: Gilroy-semibold, sans-serif;
    width: 24px;
    height: 24px;
}
.UserDropdownListitem {
    font-family: gilroy-semibold, sans-serif;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.7rem 1rem;
    cursor: pointer;
    &:hover {
        background-color: #efefef;
    }
}
.UserDropdownTooltip {
    opacity: 1 !important;
    height: fit-content;
    padding: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    position: absolute !important;
    left: 86% !important;
    top: 6.5% !important;
    bottom: 100% !important;
}
.UserDropdownTooltip::after {
    display: none;
}
.UserDropdownTooltip::before {
    display: none;
}
.UserNameButtonContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
    h3 {
        font-family: gilroy-semibold, sans-serif;
        font-size: 16px;
        margin: 0;
    }
    &:hover {
        border-radius: 8px;
        background-color: #efefef;
    }
}
.NotificationTooltipHeaderText {
    font-family: gilroy-semibold, sans-serif;
    font-size: 16px;
    margin: 1rem 0 2rem 0;
}
.NotificationRowItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: gilroy-bold, sans-serif;
    font-size: 12px;
}
.NotificationTooltipSubtext {
    // truncate text
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: gilroy-semibold, sans-serif;
    color: rgba(22, 21, 19, 0.65);
    font-size: 12px;
    margin-bottom: 0.3rem;
}
.NotificationTooltipContentContainer {
    margin-bottom: 1rem;
    border-bottom: 1px solid #efefef;
}
.NotificationTooltip {
    opacity: 1 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
}
.NotificationTooltipBtn {
    background: transparent;
    color: #2f8fff;
    border: none;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    bottom: 0;
    left: 0;
}

.NotificationTooltipContainer {
    position: absolute;
    margin-top: 15rem;
    padding: 8px;
    z-index: 3;
    color: #000;
    width: 300px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.NotificationCount {
    position: absolute;
    background-color: #d63b25;
    color: white;
    width: 18px;
    left: 15%;
    top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    height: 19px;
    border-radius: 8px;
}
.NotificationBox {
    position: absolute;
    margin-top: 0.2rem;
    right: 0;
    padding: 8px;
    width: 23rem;
    z-index: 5;
    color: #000;
    padding: 1rem;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.0466018);
    border-radius: 8px;
}
.UserDropdownBox {
    position: absolute;
    margin-top: 0.2rem;
    width: inherit;
    left: 10%;
    width: 10rem;
    font-size: 14px;
    z-index: 5;
    color: #000;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.0466018);
    border-radius: 8px;
}
.CloseContainer {
    position: fixed;
    background: transparent;
    border: none;
    height: 100vh;
    z-index: 4;
    width: 100vw;
    margin-left: -10%;
}
.HideCloseContainer {
    display: none;
}
.NoNotificationsText {
    font-family: gilroy-regular, sans-serif;
    font-size: 16px;
    margin: 0 0 1rem 0;
}
.TruncatedUsername {
    width: 5rem;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media only screen and (max-width: 1025px) {
    .NavContainer {
        display: none;
    }
}
