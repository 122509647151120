.yellowLine {
    display: flex;
    margin-left: -65px;
    align-items: center;
    z-index: 2;
    width: calc(100% + 65px);
    position: absolute;
    justify-content: center;
    border-top: 7px solid #ffdc69;
    .yellowLowerLine {
        display: flex;
        align-items: center;
        background-color: #ffdc69;
        font-family: 'Gilroy-Medium';
        font-size: 12px;
        padding: 0 15px 3px 15px;
        margin-top: -2px;
        height: 24px;
        position: relative;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            border: 12px solid transparent;
            border-top-color: #ffdc69;
        }
        &::before {
            right: 99%;
            border-right-color: #ffdc69;
            border-bottom-right-radius: 7px;
        }
        &::after {
            left: 99%;
            border-left-color: #ffdc69;
            border-bottom-left-radius: 7px;
        }

        span {
            span {
                &:nth-child(2) {
                    color: #2f8fff;
                    &:hover {
                        color: #002b78;
                    }
                }
            }
        }

        .Demo_tooltip {
            position: relative;
            display: contents;
            cursor: pointer;
            border-bottom: 1px dotted black !important;
            .Demo_tooltiptext {
                padding: 10px;
                text-align: left;
                position: absolute;
                z-index: 1;
                inset: auto;
                margin-top: 180px;
                right: 0;
                margin-right: -102px;
                width: 254px;
                border-radius: 6px;
                background-color: #ffffff;
                box-shadow: 0 0 16px 5px rgb(0 0 0 / 5%);
                font-size: 10px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 15px;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border: 10px solid transparent;
                    border-bottom-color: #fff;
                }
            }
        }
    }
}

@media only screen and (max-width: 1025px) {
    .yellowLine {
        display: none;
    }
}
