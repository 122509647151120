// variables & functions
// @import 'functions';
@import '../src/sass/variables';

// layout
@import '../src/sass/breakpoints';
@import '../src/sass/grid';

// colors
@import '../src/sass/colors';

// components (button)
@import '../src/sass/components/button';

// utilities (margin, padding, opacity, etc)
@import '../src/sass/utilities';

@mixin Tradebook-Status {
    border-radius: 4px;
    font-weight: 500;
    padding: 5px 0;
    min-width: 100px;
    text-transform: uppercase;
    float: left;
    text-align: center;
    font-size: 10px;
}
@mixin Team_Access {
    border-radius: 4px;
    font-weight: 500;
    padding: 5px 0;
    min-width: 130px;
    text-transform: uppercase;
    float: left;
    text-align: center;
    font-size: 10px;
    font-family: 'Gilroy-Medium';
    body {
        // font-family: gilroy-regular, sans-serif;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: gilroy-bold, sans-serif;
    }
}
.modal {
    z-index: 100000000;
}
.modal-backdrop {
    z-index: 100000;
}
#hubspot-conversations-inline-parent {
    height: 100%;
    border: 1px solid #cdd9e4;
    border-radius: 8px;
    iframe {
        height: 100%;
        width: 100%;
        border-radius: 8px;
    }
}

.grey-page {
    font-family: 'Gilroy-Bold';
    background-color: #f9f9f9;
    z-index: 1;
    margin-left: -65px;
    padding: 0px;
    height: 100%;
    min-height: 100vh;
    .paddingLeftSection {
        padding-left: 65px;
    }
}
.negativeMargin {
    margin-top: -20px;
}
a:hover {
    text-decoration: none;
}
.ff {
    font-family: 'Gilroy-Medium';
}
.FFSB {
    font-family: 'Gilroy-SemiBold';
}
.cp {
    cursor: pointer;
}
.dflex-baseline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.dflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dflex-c {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dflex-ac {
    display: flex;
    align-items: center;
}
.dflex-ss {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
}
.dflex-sa {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.wmaxc {
    width: max-content;
}
.maxw100 {
    max-width: 100px;
}
.maxw50 {
    max-width: 50px;
}
.pl0 {
    padding-left: 0px !important;
}
.pl10 {
    padding-left: 10px;
}
.hr1 {
    border-top: 2px solid #ededed;
    width: 100%;
}
.fr {
    float: right;
}
.ffgm {
    font-family: gilroy-medium, sans-serif;
}
.ffgr {
    font-family: gilroy-regular, sans-serif;
}
.ffgsb {
    font-family: gilroy-semibold, sans-serif;
}
.ffgb {
    font-family: gilroy-bold, sans-serif;
}
/* Common CSS */
#main-body {
    font-family: 'Gilroy' !important;
    margin: 0px !important;
    padding: 0px !important;
    box-sizing: border-box;
}
.page-head {
    padding: 20px 25px 5px;
    align-items: center;
    height: 80px;
}
.finance-duration {
    background-image: linear-gradient(181.68deg, #232323 0%, #4c8dff 65.56%, #f700f3 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}
.FD .no-border {
    background-color: transparent;
    border: none;
}
.apply-btn {
    width: 218px;
    height: 40px;
    border-radius: 6px;
    background-color: #4c8dff;
    border: none;
    color: white;
}
.apply-btn:disabled {
    width: 218px;
    height: 40px;
    border-radius: 6px;
    background-color: #b7b7b7;
    border: none;
    color: white;
}
.fin-hr1 {
    width: 50px;
    margin: 12px 0px 0px 0px;
    border-top: 2px solid #e6e6e6;
}
.fin-hr2 {
    width: 50px;
    margin: 0px 0px 12px 0px;
    border-top: 2px solid #e6e6e6;
}
.clear {
    width: 16px;
    margin-top: -2px;
    margin-left: 7px;
}
.FD .page-head-right-side {
    width: 27%;
}
.page-head-right-side {
    width: 34%;
}
.page-head-right-side div:nth-child(1) .tooltiptexts {
    left: auto;
    right: auto;
    margin-left: -167px;
}
.page-head-right-side div:nth-child(2) .tooltiptexts {
    left: auto;
    right: auto;
    margin-left: -167px;
}
.page-head-right-side div:nth-child(3) .tooltiptexts {
    left: auto;
    right: auto;
    margin-left: -167px;
}
.page-head-right-side div:nth-child(3) .tooltiptexts::after {
    left: 78% !important;
}
.remaining-tool-after::after {
    left: 80% !important;
}
.number {
    color: #6a6a6a;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}
.forgot-login {
    position: relative;
}
.page-title {
    color: #1e3c71;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0px;
    padding: 0px;
}
.page-title-sub-para {
    color: #5b5b5b;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 40px;
    font-size: 12px;
    margin: auto;
}
.page-head-fa {
    background-color: #f9f9f9;
    border: none;
    width: 31.5%;
    padding: 10px;
    margin-top: 0px;
    margin-right: -9px;
    height: 60px;
}
.page-head-fa div {
    text-align: left;
}
.page-head-fa div p {
    color: #a3a3a3;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 5px;
    font-weight: 300;
}
.page-head-fa span {
    color: #73c96e;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
}
.page-head-btn {
    border: none;
    background: transparent;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
    align-items: center;
    margin-left: -6px;
}
.page-head-btn:focus {
    outline: none !important;
}
.page-head-btn img {
    height: 13px;
    width: 13px;
}
.page-head-btn-logout {
    /* color: #9DA1A7; */
    color: #ffffff;
    margin: 10% 0px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 300;
    width: 65px;
    margin-left: 20px;
    fill: currentColor;
}
.page-head-btn-logout:hover {
    font-weight: 300;
    color: #ffffff !important;
    text-decoration: none !important;
}
/* .page-head-btn:hover .page-head-btn-logout{
    color: #FFFFFF !important;
} */
.page-head-btn:hover svg path,
.page-head-btn svg path {
    fill: #ffffff !important;
}
.page-head-bottom-border {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
}
.round-border {
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
    /* border: 1px solid #e9e9e9; */
    border-radius: 10px;
    // box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);
}
.dashboard-withdraw-schedule-border {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 12px 20px !important;
}
.light-round-border {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);
}
.page-body {
    padding: 0px;
    // margin: 0px;
}
.page-body-margin {
    margin: 20px 28px;
    font-family: 'Gilroy-Medium';
    cursor: pointer;
}
.page-body-margin2 {
    margin: 25px;
}
.modal-content {
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px 4px rgba(0, 0, 0, 0.23);
    overflow: hidden;
}
.modal-title {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin: auto 0px;
}
.modal-dialog {
    margin-top: 8%;
}

.tally-modal .modal-header {
    padding-bottom: 10px;
}
.tally-modal {
    width: 530px;
}
.payout-modal .modal-content {
    width: 446px;
}
.payout-modal .modal-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
}
.payout-modal .modal-body {
    padding: 15px 1.5rem 30px 1.5rem;
}

/*Company Onboarding Head css */
.CO-head-contactBtn {
    height: 35px;
    width: 97px;
    background-color: #ffffff;
    border: 1px solid #017aff;
    border-radius: 6px;
    color: #4c8dff;
    font-weight: 300;
}
.help-head-fontsize {
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 16px;
    align-self: center;
}

/* Table Css */
.data-table {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0px;
    /* min-width: max-content; */
    margin: 30px 25px;
    /* text-align: center; */
}
.data-table1 {
    border: 1px solid #e9e9e9;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0px;
}
.data-table2 {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0px;
    border-left: none;
    border-right: none;
}
.admin-data-table {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0px;
    /* min-width: max-content; */
    margin: 25px 0px;
}
.team {
    color: #000000;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 0px;
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0px;
}

.team-head {
    font-size: 20px;
    vertical-align: middle;
}
.team-body {
    font-size: 16px;
    cursor: pointer;
}
.team-body-auto {
    font-size: 16px;
}

.team-body-auto tr td,
.investor-rows tr td {
    font-size: 12px;
    font-weight: 300;
    vertical-align: middle;
}

.team-body tr td,
.investor-rows tr td {
    font-size: 12px;
    font-weight: 300;
    vertical-align: text-top;
}
.investor-rows tr td {
    padding: 10px 10px;
}
.table > :not(:first-child) {
    border-top: none !important;
}
.table td,
.table thead th {
    font-size: 12.5px;
    font-weight: 500;
    box-sizing: border-box;
}
.table thead th {
    padding: 6px 0px;
    border-top: 0px solid #dee2e6;
    border-bottom: 0px solid #dee2e6;
    vertical-align: middle;
}
.table td {
    padding: 20px 10px;
    border-bottom: 0px solid #dee2e6;
}
.table tbody tr:nth-child(1) {
    border-top: 0px;
}
.table-row-border-right {
    border-right: 1px solid #979797;
}
/* Tradebook status starts */
.Listed-bg {
    @include Tradebook-Status;
    background-color: #f2e8ff;
    color: #6021b3;
}
.Active-bg {
    @include Tradebook-Status;
    background-color: #e5fcde;
    color: #49a71b;
}
.DD-Requested-bg {
    @include Tradebook-Status;
    background-color: #fdf7ce;
    color: #d09c6d;
}
.Active-Swap-bg {
    @include Tradebook-Status;
    background-color: #e5fcde;
    color: #49a71b;
}
.Liquidated-bg {
    @include Tradebook-Status;
    background-color: #fff5ee;
    color: #d09c6d;
}
.Completed-bg {
    @include Tradebook-Status;
    background-color: #f4f4f4;
    color: #525151;
}
.NA-bg,
.Scheduled-bg {
    @include Tradebook-Status;
    background-color: #f4f4f4;
    color: #989898;
}
.PAID_DELAYED-bg,
.Paid-bg,
.Recieved-bg,
.Received-bg {
    @include Tradebook-Status;
    background-color: #e5fcde;
    color: #49a71b;
}
.Processing-bg {
    @include Tradebook-Status;
    background-color: #e4eeff;
    color: #4c8dff;
}
.Delayed-bg,
.Swapped-bg {
    @include Tradebook-Status;
    background-color: #fff5ee;
    color: #d09c6d;
}
/* Tradebook status ends */
.Primary-User-bg {
    @include Team_Access;
    background-color: #e5fcde;
    color: #49a71b;
}
.Manager-bg {
    @include Team_Access;
    background-color: #fdf4ff;
    color: #9e3cb9;
}
.Co-Borrower-bg {
    @include Team_Access;
    background-color: #fdf4ff;
    color: #9e3cb9;
}
.Read-Only-bg,
.View-Only-bg {
    @include Team_Access;
    background-color: #f4f4f4;
    color: #989898;
}
.Deal-Chest-Only-bg {
    @include Team_Access;
    background-color: #e4eeff;
    color: #4c8dff;
}
.Data-Collaborator-bg {
    @include Team_Access;
    background-color: #fff7e9;
    color: #ca9d17;
}
.Order-Cancelled-bg {
    border-radius: 4px;
    background-color: #b7b7b7;
    padding: 5px 18px;
    color: #5f5f5f;
    font-weight: 400;
}
.Default-bg {
    border-radius: 4px;
    background-color: #d06d6d;
    padding: 5px 25px;
    color: #ffffff;
    font-weight: 300;
}
.Expired-bg,
.Cancelled-bg {
    border-radius: 4px;
    background-color: #b7b7b7;
    padding: 5px 18px;
    color: #5f5f5f;
    font-weight: 500;
}
.Complete-bg {
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 5px 16.5px;
    color: #525151;
    font-weight: 500;
}
.black-on-hover:hover {
    color: #000000;
}
.darkblue-on-hover:hover {
    color: #0056b3;
}
.bold-table-cel {
    color: #000000;
    font-weight: 400 !important;
}
.blue-table-cel {
    color: #4c8dff;
}
.darkblue-table-cel {
    color: #135e9c;
    /* font-weight: 700; */
}
.status-open-table-cel {
    color: #135e9c;
    /* font-weight: 700; */
}
.status-uploaded-table-cel {
    color: #135e9c;
    /* font-weight: 700; */
}
.status-approved-table-cel {
    color: #279c13;
    /* font-weight: 700; */
}
.status-not-approved-table-cel {
    color: #cc0707;
    /* font-weight: 700; */
}
.status-on-hold-table-cel {
    color: #000000;
    /* font-weight: 700; */
}
.green-clr {
    color: #085a08;
}
.blue-clr {
    color: #08437b;
}
.grey-clr {
    color: #525252 !important;
}
.grey-clr2 {
    color: #4c4c4c;
}
.grey-clr3 {
    color: #575757 !important;
}
.purple-color {
    color: #812abe;
}
.grey-blue-color {
    color: #9199ca;
}
.green-bg {
    background-color: #27ba27;
}
.grey-bg {
    background-color: #e4e4e4;
}
.grey1-bg {
    background-color: #747474;
}
.blue-bg {
    background-color: #4c8dff;
}
.blue-bg:hover {
    background-color: #002b78;
}
.blk-bg {
    background-color: #000000;
}
.purple-bg:hover {
    background-color: #39057b;
}
.remove-decor:hover {
    text-decoration: none !important;
}
.remove-decor {
    font-weight: 300;
}
.tor-btn-bg {
    background-color: #000000;
}
.tor-btn-bg:hover {
    background-color: #7741f2;
}
.exportBeforeImg {
    border-radius: 9px;
    padding: 5px 8px;
    border: 0.5px solid #e3e3e3;
    background-color: #ffffff;
}
.exportAfterImg {
    border-radius: 9px;
    padding: 5px 8px;
    border: 0.5px solid #f0f0f0;
    background-color: #f0f0f0;
}
.exportCSV img {
    margin-right: 10px;
}
.exportCSV {
    position: fixed;
    right: 55px;
    /* background: white; */
    border: 0.4px solid #dadada;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 5px rgb(117 117 117 / 6%);
    z-index: 1;
    height: 32px;
    color: #898989;
    font-size: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
}
.exportCSV:focus {
    outline: none;
}

th:after,
th:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
}

.no-filter-head th:after {
    bottom: -1px;
    border-bottom: 1px solid #dee2e6;
}
/* Table Css Ends */

.expand:hover {
    overflow: visible;
    white-space: pre-wrap;
}
.text-truncate {
    cursor: pointer;
}

/* Tooltip css starts */
.tooltips,
.tooltips-ba {
    position: relative !important;
    display: contents !important;
    cursor: pointer;
    border-bottom: 1px dotted black !important;
}

.tooltips .tooltiptexts,
.tooltips-ba .tooltiptexts-ba {
    visibility: hidden !important;
    /* width: 120px; */
    /* background-color: black; */
    /* color: #fff; */
    border-radius: 6px;
    padding: 10px;
    text-align: left;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 36%;
    /* margin-left: -60px; */
    width: 175px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 16px 5px rgb(0 0 0 / 6%);
    color: #575757;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
}
.tooltips .after1::after,
.tooltips-ba .after1::after {
    left: 50% !important;
}
.tooltips .after2::after,
.tooltips-ba .after2::after {
    left: 20% !important;
}
.tooltips .tooltiptexts::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 80%;
    margin-left: -5px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
}
.tooltips-ba .tooltiptexts-ba::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 80%;
    margin-left: -5px;
    border: 10px solid transparent;
    border-top-color: #fff;
}

.tooltips:hover .tooltiptexts,
.tooltips-ba:hover .tooltiptexts-ba {
    visibility: visible !important;
}

.tooltips-disabled {
    pointer-events: none;
}
.AuthSignTooltip .repayment-tooltip {
    max-width: 330px;
}
.ReactTooltip .__react_component_tooltip {
    width: 210px;
    padding: 10px;
}
.auth-signatory-tooltip {
    width: 60% !important;
}
.Companyprogress-tooltip,
.repaymentBar-tooltip {
    padding: 0px !important;
    width: 210px;
    opacity: unset !important;
    &::before {
        border-top: 8px solid lightgrey !important;
    }
}
/* Tooltip css ends */

/* Rechart Tooltip starts */
.overview-new-graph .recharts-tooltip-wrapper {
    text-align: center;
    cursor: pointer !important;
    border: 0.2px solid #cacaca !important;
    background-color: #ffffff !important;
    border-radius: 4px;
    height: 43.5px;
    width: 119.5px;
    top: -30%;
    left: -9% !important;
    opacity: 0.87 !important;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.13);
}
.fin-graph .recharts-wrapper,
.fin-graph1 .recharts-wrapper,
.recharts-wrapper svg {
    width: 100% !important;
}
.pie-chart {
    text-align: -webkit-right;
}
.overview-pie .hr {
    border-top: 0.5px solid #696969;
    margin: 10px 0;
}
.overview-pie {
    max-width: 260px;
    margin: auto;
}
.overview-pie .recharts-wrapper svg {
    position: relative;
}
.overview-pie .recharts-tooltip-wrapper {
    opacity: 1 !important;
    text-align: left;
    right: 300px !important;
    left: unset !important;
    top: -30px !important;
    width: 180px;
    border: 0.5px solid #cacaca;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 15px;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.13);
}
.overview-new-graph .recharts-tooltip-cursor {
    fill: white;
}

.overview-new-graph-cust .recharts-tooltip-wrapper {
    cursor: pointer !important;
    border: 0.2px solid #cacaca !important;
    background-color: #ffffff !important;
    border-radius: 4px;
    height: 43.5px;
    width: 119.5px;
    top: -30%;
    left: -9% !important;
    opacity: 0.87 !important;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.13);
}

.overview-new-graph-cust .recharts-tooltip-cursor {
    fill: white;
}

#drawdownGraph:hover {
    fill: url(#gradientDrawdownHover);
}

// .overview-new-graph .recharts-rectangle:hover {
//     fill: url(#gradientHover);
// }

.overview-pie .recharts-pie-sector path {
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
}
/* Rechart Tooltip ends */

/* ScrollBar Css Starts */
.tableFixHead,
.tableFixHead1 {
    display: block;
    position: relative;
    overflow-y: scroll;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
    scrollbar-width: thin !important;
    max-height: calc(100vh - 175px) !important;
}
.contract-ht {
    max-height: calc(100vh - 350px) !important;
}
.width20per td,
.contract-head tr th:nth-child(1),
.contract-head tr th:nth-child(2),
.contract-head tr th:nth-child(3),
.contract-head tr th:nth-child(4),
.contract-head tr th:nth-child(5) {
    width: 20%;
}
.investor-table-ht {
    display: block;
    position: relative;
    overflow: overlay;
    max-height: calc(100vh - 150px) !important;
}
.tableFixHead thead th,
.tableFixHead1 thead th,
.investor-table-ht thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    vertical-align: middle;
}
.added-bank-account::-webkit-scrollbar,
.added-bank::-webkit-scrollbar,
.tableFixHead::-webkit-scrollbar,
.tableFixHead1::-webkit-scrollbar,
.investor-table-ht::-webkit-scrollbar {
    width: 10px !important;
    scroll-padding-right: 100px !important;
}

.tableFixHead::-webkit-scrollbar-track,
.investor-table-ht::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 0px !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    margin-top: 40px;
    margin-bottom: 2px;
}

.added-bank-account::-webkit-scrollbar-track,
.added-bank::-webkit-scrollbar-track,
.tableFixHead1::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 0px !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    margin-top: 0px;
    margin-bottom: 2px;
}

.added-bank-account::-webkit-scrollbar-thumb,
.added-bank::-webkit-scrollbar-thumb,
.tableFixHead::-webkit-scrollbar-thumb,
.tableFixHead1::-webkit-scrollbar-thumb,
.investor-table-ht::-webkit-scrollbar-thumb {
    background-color: rgb(168, 168, 168) !important;
    border-radius: 0px !important;
    border-left: 0px solid transparent !important;
    border-right: 0px solid white !important;
}

/* Modal CSS */
.modal-submit-btn-investor,
.modal-submit-btn-investee {
    border-radius: 4px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
}

.modal-submit-btn-investee {
    background-color: #4c8dff;
}
.modal-submit-btn-investee:disabled {
    background-color: #d4d4d4;
}
.modal-submit-btn-investee:disabled:hover {
    background-color: #d4d4d4;
}
.error-deposit {
    margin-top: 5px;
    height: 24px;
    width: 273px;
    color: #eb5e00;
    font-size: 9px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
}
.modal-submit-btn-investee:hover {
    background-color: #002b78;
}
.modal-submit-btn-investor {
    background-color: #521b97;
}
.modal-submit-btn-investor:hover {
    background-color: #7741f2;
}
.subtitle-normal {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 300;
}
.learn-more {
    color: #4c8dff;
}
.withdraw-modal-flex-container {
    display: flex;
    margin-bottom: 16px;
}
.withdraw-modal-back-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.modal-input-container {
    background-color: rgb(248, 248, 248);
    border-radius: 5px;
    padding: 15px;
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.input-div-label {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
.enter-rupee {
    border: none;
    font-weight: 600;
    font-size: 15px;
    padding: 5px 7px;
    margin-right: 2px;
    background-color: transparent;
}
.modal-input-container .modal-input {
    font-weight: 300;
    font-size: 12px;
    width: 85%;
    padding: 5px;
    color: #e7e7e7;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    margin-bottom: 0px !important;
}
.subtitle-small {
    font-size: 8px;
    float: right;
    margin-right: 25px;
}
.subtitle-small:hover {
    cursor: pointer;
}
.input-div-label-small {
    font-size: 10px;
    font-weight: 300;
    color: #9b9b9b;
    margin: 0;
    max-width: 190px;
}
.modal-container-item {
    flex: 1;
}
.item-label {
    font-size: 10px;
    font-weight: 300;
    color: #000000;
}
.item-data {
    font-size: 10px;
    font-weight: 400;
}
.modal-input-container-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 4px;
}
.modal-image-small {
    width: 30px;
    border-radius: 2px;
    margin-right: 2px;
}

/* ScrollBar Css Ends */

/* Right click for Filter Starts */
.without-filter {
    padding: 15px 10px;
    height: 33px;
    display: inline-flex;
    align-items: center;
}
.right-click-filter th .filter-div {
    padding: 15px 10px;
    border-radius: 8px;
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 33px; /* CODE RED-> DON'T CHANGE THIS HEIGHT */
}
.filter-hidden {
    visibility: hidden;
}
.filter-div:hover span {
    visibility: visible;
}
.filter-div:hover::before {
    display: block;
}
.right-click-filter th .filter-div span img:nth-child(2) {
    margin-top: -10px;
}
.right-click-filter th .filter-div span {
    margin-left: 10px;
}
.right-click-filter th .filter-div span img:nth-child(1) {
    margin-top: 1px;
}
.right-click-filter th .filter-div:hover {
    background-color: #f0f0f0;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.17);
}
.right-click-filter th div::before {
    content: 'Right click for filters';
    position: absolute;
    transform: translateY(230%);
    height: 22px;
    width: 114px;
    border-radius: 4px;
    background-color: #334461;
    box-shadow: 0 2px 5px -1px rgb(0 0 0 / 50%);
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    color: #ffffff;
    padding-top: 4px;
    display: none;
    margin: -42px 0px 0px -19px;
}
/* Right click for Filter Ends */

/* Applied Filter Starts */
.applied-filter {
    color: #6598f0;
    height: 29px !important;
    padding: 10px 0px;
    border-radius: 8px;
    background-color: #f4f8ff;
    box-shadow: inset 0 1px 4px 0 rgba(84, 84, 84, 0.17);
}
.applied-filter-icon {
    margin-right: 5px;
    margin-left: -20px;
}
.applied-filter-icon2 {
    margin: 0px;
    visibility: visible !important;
    img {
        width: 15px !important;
    }
}
.rotate180 {
    transform: rotate(180deg);
}
/* Applied Filter Ends */

/* No data in table body */
.empty-row,
.no-data-in-table tr td,
.no-data-in-table td {
    text-align: center;
    padding: 100px 0px !important;
    font-size: 14px;
    font-weight: 300;
}
.no-data-in-table tr td span,
.no-data-in-table td span {
    color: #000000;
}
.no-data-in-table tr td span:hover a {
    color: #4c8dff;
    font-weight: 300 !important;
    text-decoration: none;
}
/* No data in table body Ends */

/* .Blue-hover:hover{
    background-color: #4C8DFF;
 } */

/* Common CSS Ends */
@media only screen and (max-width: 1000px) {
    .page-head {
        display: block;
    }
    .page-title-sub-para {
        font-size: 15px;
    }
    .page-head-btn {
        padding: 0px;
    }
}
@media only screen and (max-width: 1320px) {
    /* .page-title-sub-para {
        line-height: inherit;
    } */
}

.logged-in-wrapper {
    margin: 0;
    width: 100%;
    margin-left: 181px;
    position: relative;
    background-color: #f9f9f9;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}

.status-tooltip {
    width: 200px;
    border: 1px solid #dddddd !important;
    border-radius: 5px !important;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    opacity: unset !important;
    background-color: #fff;
    white-space: normal;
}
.tot-tooltip {
    max-width: 300px;
    min-width: 200px;
    font-size: 10px;
    font-family: gilroy-regular, sans-serif;
}
.repayment-tooltip {
    width: fit-content;
    border: 1px solid #c4c4c4 !important;
    border-radius: 5px !important;
}
.fcg {
    color: #7b7b7b;
}
.repayment-tooltip.place-top::before,
.status-tooltip.place-top::before {
    border-top: 8px solid #c4c4c4 !important;
}
.repayment-tooltip.place-bottom::before,
.status-tooltip.place-bottom::before {
    border-bottom: 8px solid #c4c4c4 !important;
}
.repayment-tooltip.place-left::before,
.status-tooltip.place-left::before {
    border-left: 8px solid #c4c4c4 !important;
}
.repayment-tooltip.place-right::before,
.status-tooltip.place-right::before {
    border-right: 8px solid #c4c4c4 !important;
}
.withdraw-tooltip {
    padding: 9px !important;
    letter-spacing: 0;
    line-height: 12px;
    font-size: 10px;
    color: #575757;
    width: max-content;
}

.payout-tooltip {
    width: 210px;
    border: 1px solid #c4c4c4 !important;
    border-radius: 5px !important;
}
.payout-tooltip.place-top::before {
    border-top: 8px solid #c4c4c4 !important;
}
.payout-tooltip.place-bottom::before {
    border-bottom: 8px solid #c4c4c4 !important;
}
.payout-tooltip.place-left::before {
    border-left: 8px solid #c4c4c4 !important;
}
.payout-tooltip.place-right::before {
    border-right: 8px solid #c4c4c4 !important;
}

/* FINBOX */
/* .sc-fvhFOF {
  height: 600px !important;
} */
.cVMtyy {
    margin-bottom: 30px !important;
}

/* ADMIN start */
/* Built-in css Starts */
.MuiTab-root {
    /* padding: 15px 12px !important;
    margin: 0px 25px !important; */
    padding: 15px 0px !important;
    margin: 0px 15px !important;
    min-width: 145px !important;
    max-width: 145px !important;
    text-transform: unset !important;
    font-family: 'Gilroy' !important;
}
.PrivateTabIndicator-root-3 {
    min-width: 127px !important;
    max-width: 127px !important;
}
.makeStyles-indicator-2 {
    height: 5px !important;
    background: grey;
}
/* Built-in css Ends */

/* Custom Css Starts */
/* Admin Header css Starts */
.admin-tag-common {
    margin: 0px 25px;
}
.admin-recur-logo {
    height: 55px;
    width: 82px;
}
.blk-clr {
    background-color: #1f1f1f !important;
}
.heit {
    height: 79px;
}
.border1
/* Admin Header css Ends */

#admin {
    font-family: 'Gilroy' !important;
}
#admin-company {
    padding: 20px 27px;
    font-family: 'Gilroy' !important;
}
/* Custom Css Ends */

/* Companies Page css Starts */
.has-search .form-control {
    padding-left: 2rem;
    width: 242px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    height: 30px;
    opacity: 0.4;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.admin-company-btn {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    height: 40px;
    width: 118px;
    background-color: #000000;
    border: 1px solid #979797;
    border-radius: 6px;
}
.admin-company-btn img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
}
/* Companies Page css Ends */

.btn-close {
    width: 14px !important;
    height: 14px !important;
}

/* Trades Page css Starts */
.app-btn {
    height: 30px;
    border-radius: 6px;
    background-color: transparent;
    margin-right: 20px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    font-weight: 500;
}
.btn-approve {
    width: 109px;
    border: none;
    background-color: #279c13;
    color: #ffffff;
}
.btn-approve img {
    height: 14px;
    width: 18px;
    margin-right: 10px;
}
.btn-no-approve {
    width: 149px;
    background-color: #980d0d;
    color: #ffffff;
    border: none;
}
.btn-no-approve img {
    height: 14px;
    width: 14px;
    margin-right: 10px;
    margin-top: 0px;
}
.btn-hold {
    width: 109px;
    background-color: #9f9f9f;
    color: #ffffff;
    border: none;
}
.btn-hold img {
    height: 17px;
    width: 18px;
    margin-right: 10px;
    margin-top: 0px;
}
/* Trades Page css Starts */

.request-data-table {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0px;
    margin: 25px 0px;
}
.admin-contract-head tr th:nth-child(1) {
    padding-left: 5px;
}
.admin-contract-head tr th {
    font-size: 10px;
}
.admin-clubaccess tr th:nth-child(1) {
    padding-left: 25px;
}
.admin-help tr th:nth-child(1) {
    padding-left: 47px;
}

/* Contract Detail Page starts */
.admin-contract-table {
    border: 1px solid #d9d9d9;
    border-spacing: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-collapse: unset;
    overflow: hidden;
    color: #878787 !important;
}
.admin-contract-table thead tr th,
.admin-contract-table tbody tr td {
    font-weight: 300 !important;
}
.admin-contract-table thead {
    background-color: #e1e1e1;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 10px;
}
.admin-contract-table thead tr th:nth-child(1),
.admin-contract-table tbody tr td:nth-child(1) {
    padding-left: 25px;
}
.admin-contract-table tbody {
    background-color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
}
.contract-detail-row2 {
    padding: 20px 60px 35px 50px;
}
.pay-schedule-table {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    margin-top: 20px;
    border-spacing: 0px;
    overflow: hidden;
    font-size: 12px;
}
.contract-BR {
    border-right: 1px solid #979797;
}
.contract-BB {
    border-bottom: 1px solid #979797 !important;
}
.contract-bold {
    font-weight: 500 !important;
}
.pay-schedule-table thead {
    background-color: #f1f1f1;
}
.pay-schedule-table thead:nth-child(2) tr th {
    color: #878787 !important;
    font-weight: 300;
}
.pay-schedule-table tbody {
    background-color: #f4f4f4;
    color: #878787 !important;
}
/* Contract Detail Page ends */
/* ADMIN ends */

/* AUTOINVEST start */
.AutoInvest .page-head-right-side {
    width: 25%;
    justify-content: space-around !important;
}
.autoInvest {
    height: 64px;
    // padding: 0 25px;
    border-radius: 6px;
    background-color: #f9f9f9;
}
.autoinvest-table-ht {
    max-height: calc(115vh - 350px) !important;
}
.define,
.fs14 {
    font-size: 14px;
}
.define1,
.fs18 {
    font-size: 18px;
}
.define2,
.fs16 {
    font-size: 16px;
}
.fs13 {
    font-size: 13px;
}
.fs12 {
    font-size: 12px;
}
.fs10 {
    font-size: 10px;
}
.fs8 {
    font-size: 8px;
}
.fs9 {
    font-size: 9px;
}
.blk-btn {
    box-sizing: border-box;
    height: 40px;
    width: 146px;
    border: 1px solid #000000;
    border-radius: 6px;
    background-color: $investor;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.white-btn {
    background-color: #ffffff;
    height: 40px;
    border: 1px solid #000000;
    border-radius: 6px;
}
.purple-btn {
    background-color: #ffffff;
    height: 40px;
    color: $investor;
    border: 1px solid $investor;
    border-radius: 6px;
}
/* Create Auto Invest Css starts */
.createAIform {
    background-color: #ffffff;
    box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.05);
    padding: 20px 30px;
    border-radius: 8px;
}
.fwb {
    font-weight: bold;
}
.steps span:nth-child(1),
.fw6 {
    font-weight: 600 !important;
}
.steps {
    font-weight: 300;
}
.fw5 {
    font-weight: 500 !important;
}
.fw4 {
    font-weight: 400 !important;
}
.fw3 {
    font-weight: 300 !important;
}
.text-clr1 {
    color: #b9b9b9;
}
.text-clr2 {
    color: #999999;
}
.text-clr3 {
    color: #7c7c7c;
}
.text-clr4 {
    color: #ffffff;
}
.text-clr5 {
    color: #4f4f4f;
}
.text-clr6 {
    color: #eb4a50;
}
.border-r {
    border-radius: 2px;
}
.border1 {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.border2 {
    height: 28px;
    width: 100%;
    border: 0.5px solid #d2d2d2;
    border-radius: 4px;
    background-color: #ffffff;
}
.typeinp {
    width: 100%;
    color: #000000;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 13px;
    padding-left: 5px;
    height: 23px;
    margin-top: 7px;
}
.typeinp:disabled {
    color: #a7a7a7;
}
.w55 {
    width: 55%;
}
.w50 {
    width: 50%;
}
.w45 {
    width: 45%;
}
.w48 {
    width: 48%;
}
.w35 {
    width: 35% !important;
}
.w32 {
    width: 32%;
}
.w-max-content {
    min-width: max-content;
}
.h-max-content {
    min-height: max-content;
}
.w70 {
    width: 70%;
}
.w61 {
    width: 61%;
}
.custom-hr {
    border-top: 1px solid #f2f2f2;
    width: 100%;
}
.check {
    height: 15px;
    width: 15px;
    background-color: #4c8dff;
}
.check:disabled ~ label {
    color: #707070;
}
.bluebox {
    height: 28px;
    padding: 5px 8px;
    min-width: max-content;
    border: 1px solid #4c8dff;
    border-radius: 4px;
    background-color: #f4f8ff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: 'pointer';
}
.minht90 {
    min-height: 90px;
}
.ht90 {
    height: 90px;
}
.ht41 {
    height: 41px;
}
.show-more-ai {
    background-color: #f7f7f7;
    border: none;
    font-size: 12px;
    font-weight: 500;
}
.ai-dropdown {
    border: 0.5px solid #d2d2d2;
    border-radius: 4px;
    color: #aeaeae;
    font-size: 12px;
    height: 28px;
}
/* Create Auto Invest Css ends */

/* Input Slider css starts */
.input-range .input-range--disabled {
    background: #eaeaea;
}

.input-range--disabled .input-range__slider {
    display: none !important;
}

.input-range--disabled .input-range__track--active {
    background: #a7a7a7 !important;
}

.input-range__track--active {
    background: linear-gradient(213.68deg, #006dff 0%, #4c8dff 51.26%, #002f92 98.64%) !important;
}

.input-range__track--background {
    height: 6px !important;
    border-radius: 7px !important;
    background-color: #eaeaea !important;
}

.input-range__slider {
    height: 20px !important;
    width: 9px !important;
    margin-top: -0.75rem !important;
    border: 2px solid #ffffff !important;
    border-radius: 4.5px !important;
    background-color: #29153b !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5) !important;
}
.input-range__label-container {
    display: none;
}

/* Input Slider css ends */

/* Available volume starts */
.view-mrkt {
    cursor: pointer;
    height: 41px;
    background-color: #f9f9f9;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* Available volume ends */

/* In Case of Disabled starts */
.disable-switch {
    background-color: #565656;
    color: #fff;
}
/* disable-select can be used in place of bluebox class */
.disable-select {
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    background-color: #f8f8f8;
    color: #707070;
    height: 28px;
    padding: 5px 8px;
    min-width: max-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: 'not-allowed';
}
.disable-select img {
    display: none;
}
/* In Case of Disabled Ends   */

.css-ssi0ig-container {
    pointer-events: revert;
}
/* AUTOINVEST ends */

/* COMPANIESDETAIL start */
#company-detail {
    font-family: 'Gilroy-Medium';
    /* font-family: 'Aktiv-Grotesk'; */
}
/* 1st row css starts */
.company-detail-row1 {
    background-color: #f0f0f0;
    padding: 20px 60px 35px 50px;
}
.btn-bg-clr {
    height: 40px;
    border-radius: 6px;
    border: none;
    background-color: #000000;
}
.company-big-btn {
    height: 40px;
    width: 129px;
    color: #ffffff;
}
.company-small-btn {
    box-sizing: border-box;
    height: 36px;
    width: 82px;
    opacity: 0.87;
    color: #f0f0f0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    margin-right: 15px;
}

.small-fonts {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 300;
}
.medium-fonts {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}
.big-fonts {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}
.grey-text {
    color: #8d8d8d;
}
.grey-text span {
    color: #037bff;
}
.black-text {
    color: #000000;
}
.black-text span {
    color: #279c13;
    font-weight: 500;
}
.last-edit {
    opacity: 0.53;
    color: #000000;
    font-weight: 500;
    margin-top: 20px;
    margin-right: 50px;
}
.last-edit span {
    font-weight: 300;
}
.price-irr-fees {
    opacity: 0.37;
    color: #222e43;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 10px;
}
.price-irr-number {
    height: 44px;
    width: 129px;
    border-radius: 4px;
    background-color: #e2e2e2;
    padding: 8px;
}
.company-name {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin: 20px 0px;
}
/* 1st row css ends */

/* 2st row css starts */
.page-head-bottom-border {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
}
.cmp-row2-head {
    opacity: 0.37;
    color: #222e43;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 300;
    margin-top: 20px;
}
.more-width {
    width: 303px;
    height: 44px;
    outline: none;
}
.less-width {
    width: 214px;
    height: 44px;
    outline: none;
}
.cmp-row2-field {
    border-radius: 4px;
    border: none;
    background-color: #f1f1f1;
    max-width: 100%;
}
.cmp-overview {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 30px;
    height: 208px;
    /* width: 470px; */
}
.cmp-logo {
    height: 126px;
    width: 100%;
    object-fit: cover;
    margin-top: 50px;
}
.company-detail-row2,
.company-detail-row3 {
    background-color: transparent;
    padding: 35px 40px 35px 35px;
    /* width: 1465px; */
    margin: 0 auto;
    box-sizing: border-box;
}
/* 2st row css ends */

/* Table css starts */
.company-table {
    margin-bottom: 0px;
}
.company-table thead tr th {
    padding: 4px !important;
    opacity: 0.27;
}
.company-file-name {
    color: #0179ff;
    text-decoration: underline;
}
.cmp-row2-table {
    border-radius: 4px;
    border: none;
    background-color: #f1f1f1;
}
.cmp-row3-table {
    box-sizing: border-box;
    border: 1px solid #979797;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.cmp-tbl-head {
    color: #000000 !important;
    font-size: 10px !important;
    letter-spacing: 0 !important;
    line-height: 12px !important;
    padding: 3px;
    opacity: 0.27;
}
.cmp-table-row {
    color: #000000 !important;
    font-size: 12px !important;
    letter-spacing: 0 !important;
    line-height: 14px !important;
    padding: 0px 30px;
}
.cmp-table-row td,
.cmp-table-row {
    opacity: 0.7;
    padding: 8px;
    /* border-top: 1px solid #000000 !important; */
}
.company-addition-doc {
    opacity: 0.7;
    font-size: 12px !important;
    letter-spacing: 0 !important;
    line-height: 14px !important;
    padding: 0px 30px;
}
.company-row1 {
    margin: 0 auto;
}
.saas-matric {
    opacity: 0.5;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    border-top: 1px solid #242424;
    margin: 0px;
    padding: 3px 16px 0px 16px;
}
.saas-matric-head {
    border-top: 1px solid #242424;
}
/* Table css ends */

/* Companies Edit Page css starts */
.company-edit-cancel {
    height: 40px;
    width: 85px;
    color: #d71818;
    border: 1px solid #dc1212;
    border-radius: 6px;
    margin-right: 30px;
}
.company-edit-save {
    height: 40px;
    width: 70px;
    border: none;
    color: #ffffff;
}
.edit-dropdown {
    box-sizing: border-box;
    height: 44px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 8px;
}
.edit-upload-addition-btn {
    color: #000000 !important;
    font-size: 10px !important;
    letter-spacing: 0 !important;
    line-height: 12px !important;
    padding: 3px;
}
.dropdown-width1 {
    max-width: 100%;
}
.dropdown-width2 {
    min-width: 100%;
}
.price-irr-width {
    width: 129px;
}
.upload-btn {
    height: 26px;
    width: 111px;
    border-radius: 3px;
    border: none;
    background-color: #000000;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
}
.remove-btn {
    box-sizing: border-box;
    height: 26px;
    width: 111px;
    border: 1px solid #d0021b;
    border-radius: 3px;
    background-color: #ffffff;
    color: #d0021b;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    position: absolute;
    top: 80%;
    right: 13%;
}
.upload-btn-onimg {
    position: absolute;
    top: 60%;
    right: 13%;
}

/* Companies Edit Page css ends */
@media only screen and (max-width: 1465px) {
    .company-row1,
    .company-detail-row2,
    .company-detail-row3 {
        width: 100%;
    }
}
@media (min-width: 1024px) and (max-width: 1250px) {
    .cmp-row2-field {
        max-width: 90%;
    }
    .cmp-overview {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        padding: 30px;
        height: auto;
        text-align: justify;
        /* width: 470px; */
    }
    .upload-btn,
    .remove-btn {
        margin: 0 auto;
        width: 70%;
    }
}
@media (min-width: 1251px) and (max-width: 1500px) {
}
@media (min-width: 1550px) and (max-width: 1750px) {
    .company-row1,
    .company-detail-row2,
    .company-detail-row3 {
        width: 100%;
    }
}
/* COMPANYDETAIL ends */

/* DASHBOARD start */
.no-active-m {
    margin: 12px 0 19px 20px;
}
.no-active-m1 {
    margin: 12px 0 19px 15px;
}
.dash-height {
    height: 200px;
}
.dash-height2 {
    height: 145px;
}
.dashboard-duration {
    opacity: 0.91;
    color: #3b3b3b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    height: 25px;
    width: 36px;
    border: 1px solid #535353;
    background-color: transparent;
    border-radius: 2px;
    text-align: center;
    padding: 1px;
    margin: 2px;
}
.dashboard-duration:focus,
.dashboard-duration:hover {
    border: 1px solid #4c8dff;
    background-color: #eaf3ff;
    color: #4c8dff;
    outline: none;
}
.dashboard-margin {
    margin: 22px 0px;
}
.min-max-width1 {
    min-width: 200px;
    max-width: 200px;
    margin: 15px;
    padding: 0px;
    overflow: hidden;
}
.min-max-width2 {
    min-width: 430px;
    max-width: 430px;
    margin: 15px;
    padding: 0px;
    overflow: hidden;
}
.dashboard-name1 {
    font-family: gilroy-bold, sans-serif;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.dashboard-name {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
    margin: 12px 0px 2px 20px;
    font-family: 'Gilroy-Medium';
}
.dashboard-total {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    margin: 5px 0px 19px 20px;
    span {
        &:nth-child(2) {
            color: #a1a1a1;
            font-family: 'Gilroy-Medium';
        }
    }
}
.limit {
    margin-left: 22px;
}
.dashboard-total-lock {
    color: #ff6600;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    border-radius: 4px;
    background-color: #ffede1;
    width: max-content;
    padding: 0 8px;
}
.dashboard-total-lock img {
    height: 16px;
    margin-left: 8px;
}
.dashboard-graph {
    width: 111% !important;
}
.dashboard-graph-new {
    width: 124% !important;
    height: 140px !important;
}
.dashboard-graph svg {
    margin-left: -6px;
    height: 120%;
}
.dashboard-graph-new svg {
    margin-left: -6px;
    height: 120%;
}
.dashboard-graph svg .recharts-layer path:nth-child(1) {
    /* fill: #dcebff; */
    fill-opacity: unset;
}
.dashboard-graph-new svg .recharts-layer path:nth-child(1) {
    /* fill: #dcebff; */
    fill-opacity: unset;
}
.dashboard-graph svg .recharts-layer path:nth-child(2) {
    /* stroke: #2d65e7; */
    stroke-width: 2.5px;
    fill-opacity: unset;
}
.dashboard-graph-new,
svg .recharts-layer path:nth-child(2) {
    /* stroke: #2d65e7; */
    stroke-width: 1px;
    fill-opacity: unset;
}

/* .dash-stroke{
    stroke-dasharray: 57 1000;
    stroke: #511F8F;
    stroke-dashoffset: -144;
    stroke-width: 2.5px;
} */

/* .dash-rechart .recharts-responsive-container .recharts-wrapper .recharts-surface .recharts-bar .recharts-bar-rectangle{
    stroke: #511F8F !important;
    stroke-width: 2.5px;
} */

/* View Schedule box starts*/
.dash-VS {
    padding: 12px 20px;
    background-color: #f9f9f9;
}
.View {
    color: #000000;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    font-family: 'Gilroy-Medium';
}
.available-fund {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-family: 'Gilroy-Semibold';
}
.upcoming-payment-card {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-family: 'Gilroy-Medium';
}
.upcoming-payment-card-margin {
    margin-top: 20px;
    margin-bottom: 8px;
}
.fund {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
}
.fund-green {
    color: #73c96e;
}
.dash-grey {
    color: #686868;
    font-size: 12px;
    font-weight: 500;
    padding-left: 26px;
}
.add-funds {
    background-color: #73c96e;
}
.add-funds:hover {
    background-color: #266c23;
}
.withdraw-btn {
    background-color: #4c8dff;
}
.withdraw-investor {
    background-color: $investor;
}
.withdraw-investor-small {
    background-color: $investor;
    padding: 5px 0;
    width: 120px;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
}
.withdraw {
    padding: 5px 0;
    width: 100%;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
}
.withdraw1 {
    padding: 5px 0;
    height: 28px;
    width: 100%;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    margin-top: auto;
    font-size: 10px;
    font-weight: 500;
}
.withdraw-btn-disabled,
.withdraw-btn-disabled:hover {
    background-color: #b7b7b7;
    cursor: no-drop !important;
}
.recent-pay-line {
    height: 24px;
    width: 100%;
    border: 0.5px solid #c0c0c0;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 3px 6px;
}
.no-recent-pay {
    border: 0.5px solid #c0c0c0;
    border-radius: 4px;
    background-color: #ffffff;
    font-weight: 300;
    font-family: 'Gilroy' !important;
    height: 24px;
    width: 100%;
    font-size: 11px;
    align-items: center;
    margin: 15px 0px;
    padding: 13px;
}
.dash-recent-left {
    font-size: 10px;
    align-items: center;
    display: flex;
}
.dash-recent-right {
    font-size: 8px;
    align-items: center;
    display: flex;
}
/* View Schedule box ends*/

/* Traded CTA  */
.Traded-CTA {
    position: relative;
}
.Traded-CTA-btn {
    position: absolute;
    top: 68%;
    left: 9.5%;
    border-radius: 4px;
    background: linear-gradient(213.68deg, #3c89ff 0%, #4c8dff 5.89%, #f700f3 100%);
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.27);
    border: none;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-align: center;
    width: 80%;
    justify-content: center;
    padding: 10px;
}
.Traded-CTA-btn img {
    width: 10px;
    height: 10px;
    margin-left: 5px;
}
/* DASHBOARD ends */

/* FILTER start */
/* Filters css starts */
/* calender css starts */
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: none !important;
    font-family: 'Gilroy' !important;
    opacity: 0.7;
}
.react-calendar__navigation__label {
    font-size: 12px !important;
}
.react-calendar__tile {
    font-size: 10px;
}
.react-calendar__tile--now,
.react-calendar__tile--active {
    background: white !important;
    border: 1px solid #000000 !important;
    border-radius: 100% !important;
    color: #000000 !important;
}
.react-calendar__month-view__weekdays {
    font-weight: 300 !important;
}
abbr[title] {
    font-size: 10px !important;
    text-decoration: none !important;
}
.filter-calender {
    width: 266px;
}
/* calender css ends */

/* Searchbar css starts */
.filter-search {
    height: 87px;
    width: 266px;
}
.filter-searchbar {
    margin-top: 14px;
}
.filter-searchbar .filter-search-input {
    padding-left: 2rem;
    width: 100%;
    border: 1px solid #d2d2d2;
    height: 30px;
    border-radius: 4px;
    background-color: #f4f4f4;
    color: #d2d2d2;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    outline: none;
}
.filter-searchbar .filter-search-icon {
    position: absolute;
    z-index: 2;
    display: block;
    line-height: 29px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    padding-left: 10px;
}
/* Searchbar css ends */

/* Range Slider css starts */
.filter-slider {
    height: 136px;
    width: 266px;
    top: 0px;
}
.term-filter-slider {
    height: 205px;
    width: 266px;
}
.card #slider {
    position: relative;
    margin: 0 auto;
    width: 234px;
    height: 8px;
    background: #d5d5d5;
    border-radius: 5px;
    cursor: pointer;
}
.card #slider #min {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 0;
    height: 8px;
    border-radius: 5px;
    background: #d5d5d5;
}
.card #slider #min:hover::before {
    opacity: 1;
}
.card #slider #min:hover::after {
    opacity: 1;
}
.card #slider #min::before {
    opacity: 0;
    content: attr(data-content);
    display: block;
    position: absolute;
    top: -40px;
    right: -23px;
    width: 40px;
    padding: 3px;
    text-align: center;
    color: white;
    background: #d5d5d5;
    border-radius: 20px;
}
.card #slider #min::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    top: -18px;
    right: -8px;
    border-top: 8px solid #274684;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}
.card #slider #min #min-drag {
    position: absolute;
    right: -7.5px;
    top: -8px;
    box-sizing: border-box;
    height: 20px;
    width: 9px;
    border: 2px solid #ffffff;
    border-radius: 4.5px;
    background-color: #29153b;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    background: #071739;
    transition: all 0.3s;
}
.card #slider #min #min-drag:hover {
    box-shadow: 0 0 0 6px rgba(39, 70, 132, 0.2);
}
.card #slider #max {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 0;
    height: 8px;
    border-radius: 5px;
    background: #4c8dff;
}
.card #slider #max:hover::before {
    opacity: 1;
}
.card #slider #max:hover::after {
    opacity: 1;
}
.card #slider #max::before {
    opacity: 0;
    content: attr(data-content);
    display: block;
    position: absolute;
    top: -40px;
    right: -23px;
    width: 40px;
    padding: 3px;
    text-align: center;
    color: white;
    background: #274684;
    border-radius: 20px;
}
.card #slider #max::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    top: -18px;
    right: -8px;
    border-top: 8px solid #274684;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}
.card #slider #max #max-drag {
    position: absolute;
    right: -7.5px;
    top: -8px;
    box-sizing: border-box;
    height: 20px;
    width: 9px;
    border: 2px solid #ffffff;
    border-radius: 4.5px;
    background-color: #29153b;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    background: #071739;
    transition: all 0.3s;
}
.card #slider #max #max-drag:hover {
    box-shadow: 0 0 0 6px rgba(39, 70, 132, 0.2);
}
.filter-value[type='number']::-webkit-outer-spin-button,
.filter-value[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.filter-value[type='number'] {
    -moz-appearance: textfield;
}

/* Range Slider css ends */

/* Selector css starts */
.filter-selector {
    height: auto;
    width: min-content;
    /* width: 350px; */
}
.input-selected {
    border-radius: 4px;
    min-width: max-content;
    padding: 3px 8px;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    margin: 0px 8px 8px 0px;
    font-weight: 300;
    align-items: center;
}
.input-selected img {
    margin-right: 3px;
    height: 10px;
    width: 10px;
}
.active-input-select {
    background-color: #eff3f8;
    border: 1px solid #4c8dff;
}
.inactive-input-select {
    border: 1px solid #d2d2d2;
    background-color: #f4f4f4;
}
/* Selector css ends */

/* Common css in all filters starts */
.filter-value {
    box-sizing: border-box;
    height: 23px;
    width: 100px;
    border: none;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    outline: none;
    opacity: 0.44;
    color: #000000;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
}
.filter-value:hover,
.filter-value:focus {
    border: 1px solid #4c8dff !important;
    background-color: #eff4fb !important;
}
.filter-type {
    opacity: 0.65;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: left;
}
.border-shadow {
    padding: 15px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 17px 4px rgba(0, 0, 0, 0.13);
}
.mr1 {
    margin-right: 3px;
}
.reset-filter {
    cursor: pointer;
}
.wmaxc {
    width: max-content;
}
.w130 {
    width: 115px;
}
.ht20 {
    height: 20px;
}
/* Common css in all filters ends */
/* Filters css ends */
/* FILTERS end */

/* FINANCES start */
.differ-pay-type {
    color: #323232;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin: 15px 0px;
    /* opacity: 0.90; */
    font-weight: 300;
    font-family: 'Gilroy' !important;
}
.pay-trade {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0px 0px 15px 0px;
    min-width: max-content;
    font-family: 'Gilroy' !important;
}
.fin-padding {
    padding: 25px;
}
.fin-graph {
    height: 250px;
}

.fin-graph1 {
    opacity: 0.9;
    margin-bottom: 3rem;
    height: 250px;
}
.fin-graph1 .recharts-bar-rectangle path {
    fill: #e7f8e6 !important;
    border-bottom: 2px solid #006200;
}
.pay {
    height: 50px;
    border-radius: 4px;
    padding: 13px;
    margin: 15px 0px;
}
.finance-border {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    height: 45px;
    min-height: 45px !important;
    align-items: center;
}
.finance-border .MuiButtonBase-root,
.finance-border .MuiTab-root,
.finance-border .MuiTab-textColorInherit,
.finance-border .Mui-selected {
    height: 45px;
    align-items: center;
    outline: none;
    min-height: 45px !important;
    color: #000000 !important;
    font-weight: 700;
    text-decoration: none;
}
.finance-border .MuiTab-root {
    padding: 0 90px 0 11px !important;
    height: 45px !important;
    text-transform: none !important;
}
.finance-border .MuiTab-wrapper {
    margin-left: 14px;
}
.finance-border .MuiTabs-indicator {
    background-color: transparent;
}
.finance-border .MuiTouchRipple-root {
    color: white;
}
.finance-border .MuiTab-textColorInherit {
    color: #000000;
    opacity: 0.4;
}
.finance-border .MuiTab-textColorInherit:hover {
    opacity: 1;
}
.red-cel {
    color: #d78383;
}
.blue-color {
    color: #08437b;
}
.blue {
    color: #345b9c;
}
.blue1 {
    color: #4c8dff;
}
.green {
    color: #005f00;
}
.green1 {
    color: #49a71b;
}
.black {
    color: #000000;
}
.green-color {
    color: #085a08;
}
.grey-color {
    color: #8d8d8d;
}
.upcoming-pay {
    background-color: #f6faff;
    font-weight: 300;
    font-family: 'Gilroy' !important;
}
.upcoming-pay-investor {
    background-color: #f8fdf8;
    font-weight: 300;
    font-family: 'Gilroy' !important;
}
.recent-payout-investor {
    background-color: #f6faff;
    font-weight: 300;
    font-family: 'Gilroy' !important;
}
.recent-pay {
    background-color: #f9fafc;
    font-weight: 300;
    font-family: 'Gilroy' !important;
}
.recent-payout {
    background-color: #f8fdf8;
    font-weight: 300;
    font-family: 'Gilroy' !important;
}
.no-pay {
    color: #b0b0b0;
}
.no-upcoming,
.no-pay,
.no-payout {
    font-weight: 400;
}
.left-style {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 14px;
    display: flex;
    justify-content: flex-start;
    width: 35%;
    align-items: center;
    font-family: 'Gilroy' !important;
}
.right-style {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    font-family: 'Gilroy-Medium' !important;
    width: 50%;
    align-items: center;
}
.finance-table-head tr th {
    padding: 6px 0px;
    color: #000000;
    font-weight: 500;
    border-spacing: 0px;
    font-family: 'Gilroy-Medium';
}
.finance-table-head tr th:nth-child(1) {
    padding-left: 15px;
}
.finance-table-head tr th::after {
    border: none;
}
.finance-transaction-row td {
    font-family: 'Gilroy-Medium';
    padding: 15px 0px 15px 10px;
    font-size: 12px;
    font-weight: 300;
    vertical-align: middle;
}
.finance-transaction-row td:nth-child(1) {
    padding-left: 15px;
}
.finance-table-ht {
    max-height: calc(100vh - 280px) !important;
}
.finance-as-table-ht {
    max-height: calc(100vh - 350px) !important;
}
.finance-as-table-ht {
    max-height: calc(100vh - 350px) !important;
}
.finance-as-table-ht {
    max-height: calc(100vh - 315px) !important;
}
/* Account Tab Starts */
.lb tr th::after {
    bottom: -1px;
    border-bottom: 1px solid #dee2e6;
}
.p-20 tr th {
    padding: 14px 10px;
}
.grey-BG {
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 30px;
}
.go-btn {
    height: 28px;
    width: 58px;
    border-radius: 4px;
    /* background-color: #000000; */
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    margin: 29px 0 0 12px;
    border: none;
}
.download-btn {
    height: 28px;
    width: 27px;
    border-radius: 4px;
    /* background-color: #4C8DFF; */
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    /* text-align: center; */
    margin-top: 29px;
    border: none;
}
.h-28 {
    height: 28px;
}
.w-45 {
    width: 45% !important;
}
.m-35-20 {
    margin: 13px 25px 30px 25px;
}
.f-10 {
    font-size: 10px;
}
.f-12 {
    font-size: 12px;
}
input[type='date']::-webkit-calendar-picker-indicator {
    margin-inline-start: 10px;
}
.dash-VS-fin {
    height: 20px;
    width: 22px;
    margin: -4px 20px 0px 0px;
}
.as .MuiBox-root {
    padding: 0px;
}
/* Account Tab Ends */

/* Transaction Tabs new design css starts */

.transaction-tab {
    width: max-content;
    margin: -10px 0px auto;
    padding: 7px;
    border-radius: 8px;
    background-color: #f8f7f8;
}
.transaction-tab .nav-tabs {
    border: none;
    padding: 0px;
}
.transaction-tab .nav-tabs .nav-link {
    width: 163px;
    color: #939393;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    font-weight: 500;
    padding: 6px;
    margin-right: 0px;
}
.transaction-tab .nav-tabs .nav-link.active {
    border-radius: 7px;
    color: #ffffff !important;
    background-color: #4c8dff;
}

/* Transaction Tabs new design css ends */

/* Invoice Tab css starts */
.invoice tr th:nth-child(1) {
    width: 12%;
    padding-left: 10px;
}
.invoice tr th:nth-child(2) {
    width: 12%;
}
.invoice tr th:nth-child(3) {
    width: 10%;
}
.invoice tr th:nth-child(4) {
    width: 12%;
}
.invoice tr th:nth-child(5) {
    width: 10%;
}
.invoice tr th:nth-child(6) {
    width: 10%;
}
.invoice tr th:nth-child(7) {
    width: 10%;
}
.invoice tr th:nth-child(8) {
    width: 10%;
}
.invoice tr th:nth-child(9) {
    width: 14%;
}

.DV-Signed-Doc-Signed,
.invoices-status-Paid {
    @include Tradebook-Status;
    background-color: #e5fcde;
    color: #49a71b;
}
.invoices-status-Pending {
    @include Tradebook-Status;
    background-color: #fffad7;
    color: #d6bb0a;
}

.invoice-invest tr th:nth-child(1),
.invoice-invest tr th:nth-child(2),
.invoice-invest tr th:nth-child(3) {
    width: 12%;
    padding-left: 10px;
}
.invoice-invest tr th:nth-child(4),
.invoice-invest tr th:nth-child(5) {
    width: 12%;
    padding-left: 10px;
}
.invoice-invest tr th:nth-child(6) {
    width: 11%;
    padding-left: 10px;
}
.invoice-invest tr th:nth-child(6) {
    width: 10%;
    padding-left: 10px;
}
.invoice-invest tr th:nth-child(7) {
    width: 11%;
    padding-left: 8px;
}
.wtspc {
    white-space: nowrap;
}
.invoice-invest tr th:nth-child(8) {
    width: 9%;
    padding-left: 10px;
}
.invoice-invest tr th:nth-child(9) {
    width: 8%;
}
/* Invoice Tab css ends */

@media (min-width: 600px) {
    .finance-border .MuiTab-root {
        min-width: unset;
    }
}
/* FINANCES end */

/* FONTS start */
/* font Size Starts */
.fs65 {
    font-size: 65px;
}
.fs48 {
    font-size: 48px;
}
.fs40 {
    font-size: 40px;
}
.fs36 {
    font-size: 36px;
}
.fs28 {
    font-size: 28px;
}
.fs26 {
    font-size: 26px;
}
.fs24 {
    font-size: 24px;
}
.fs22 {
    font-size: 22px;
}
.fs21 {
    font-size: 21px;
}
.fs20 {
    font-size: 20px;
}
.define1 {
    font-size: 18px;
}
.define {
    font-size: 14px;
}
.define2 {
    font-size: 16px;
}
.fs12 {
    font-size: 12px !important;
}
.fs10 {
    font-size: 10px;
}
.fs9 {
    font-size: 9px;
}
.fs8 {
    font-size: 8px;
}

/* font Weight Starts */
.fw6 {
    font-weight: 600;
}
.fw5 {
    font-weight: 500;
}
.fw4 {
    font-weight: 400;
}
.steps,
.fw3 {
    font-weight: 300 !important;
}
.fw2 {
    font-weight: 200 !important;
}

/* font Color Starts */
.text-clr1 {
    color: #b9b9b9;
}
.text-clr2 {
    color: #999999;
}
.text-clr3 {
    color: #7c7c7c;
}
.text-clr4 {
    color: #ffffff;
}
.text-clr5 {
    color: #4f4f4f;
}
.clr7 {
    color: #070707;
}
.clr8 {
    color: #414141;
}
.clr9 {
    color: #4c8dff;
}

/* text-center */
.textc {
    text-align: center;
}
/* height */
.h85vh {
    height: 85vh;
}
/* width */
.w-maxc {
    width: max-content;
}
.w50px {
    width: 50px;
}
/* vertical align center */
.vc {
    top: 50%;
    transform: translateY(-50%);
}
/* FONTS end */

/* HELP starts */
/* New design css starts */
.help-new {
    margin: 28px;
}
.help-partner-div {
    width: 250px;
    height: 298px;
    background-color: #ffffff;
    border: 1px solid #ededed;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    margin-right: 35px;
}
.help-partner-img {
    width: 74px;
    height: 74px;
    background-color: #ffffff;
    box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    margin-bottom: 14px;
}
.help-partner-img img {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.06);
}
.help-partner-icons {
    width: 15px;
}
.help-div,
.help-div-investor {
    height: 135px;
    width: 250px;
    border: 1px solid #ededed;
    border-radius: 6px;
    margin-bottom: 30px;
    padding: 20px;
}
.help-new .help-div:nth-child(2) img {
    width: 51px;
    height: 51px;
}
.help-new .help-div:nth-child(2) {
    margin-bottom: 0px;
}
/* New design css ends */

.page-body-heading {
    color: #1c1c1c;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 20px;
    padding-left: 20px;
}
.form-height {
    /* height: 206px; */
    margin: 30px;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    background-color: #ffffff;
}
.my-custom-scrollbar {
    position: relative !important;
    height: calc(100vh - 250px) !important;
    overflow: auto !important;
}
.table-wrapper-scroll-y {
    display: block;
}
.my-custom-scrollbar ::-webkit-scrollbar {
    width: 10px !important;
}
::-webkit-scrollbar-track {
    background-color: white !important;
    border-radius: 0px !important;
    border-left: 0px solid white !important;
    border-right: 10px solid white !important;
    margin-top: 10px;
    margin-bottom: 10px;
}
::-webkit-scrollbar-thumb {
    background: #e6e6e6 !important;
    border-radius: 0px !important;
    border-left: 0px solid white !important;
    border-right: 12px solid white !important;
}

.form-label {
    opacity: 0.45;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
}
.form-label-ans {
    opacity: 0.7;
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
}
.form-input,
.form-input:focus {
    border-bottom: 1px solid #979797;
    opacity: 0.3;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-bottom: 20px;
    color: #000000;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    width: 100%;
    margin-bottom: 15px;
    outline: none;
}
.form-padding {
    padding: 35px 0px;
}
.side-link {
    border-radius: 6px;
    background-color: #f8f8f8;
    margin: 35px 40px 0px;
    padding: 25px;
}
.side-btn,
.side-btn:focus {
    color: #4c8dff !important;
    font-size: 16px;
    fill: currentColor;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    /* margin: 35px 40px;
    padding: 25px;
    width: 80%; */
    border: none;
    border-radius: 6px;
    background-color: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    outline: none;
}
/* .side-btn:hover{
    color:#4C8DFF;
}
.side-btn svg{
    width: 21px;
    height: 21px;
    fill: currentColor;
    margin-left: 12px;
} */
.form-link,
.form-link:hover {
    color: #348cff !important;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 500;
    opacity: 0.49;
    text-decoration: none;
    /* padding: 15px 0px !important; */
}
.help-dialog {
    margin-top: 10%;
}
.help-modal {
    height: max-content;
    width: 540px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px 4px rgb(0 0 0 / 23%);
}

.modal-form {
    font-family: 'Gilroy-Medium';
    /* border: 0.5px solid #ebebeb; */
    border: 1px solid #ced4da;
    border-radius: 4px;
    /* color: #878787; */
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    padding: 15px;
    width: 100% !important;
    margin-bottom: 25px !important;
    max-width: 100%;
    outline: none;
}
.modal-form select {
    color: #878787 !important;
}
.modal-form option[value=''][disabled] {
    display: none !important;
}
.modal-form option:not(:first-of-type) {
    color: black !important;
}
input[type='text']:focus {
    color: #000000;
    outline: none;
}
.textarea-height {
    width: 100%;
    height: 180px;
}
.modal-submit-btn,
.investor-modal-submit-btn {
    border-radius: 4px;
    width: 100%;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
}
.modal-submit-btn {
    background-color: #4c8dff;
}
.modal-submit-btn:hover {
    background-color: #002b78;
}
.ans-border {
    border-bottom: 1px solid #f0f0f0;
    margin: 25px 0px;
}
.TOTModal .modal-content {
    width: 538px !important;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .form-link {
        font-size: 15px !important;
    }
    .side-link {
        margin: 35px 15px 0px;
    }
    .help-modal {
        width: 100%;
    }
}
@media only screen and (max-width: 900px) {
    .form-link {
        font-size: 15px;
    }
}
/* HELP ends */

/* INVESTEETRADE starts */
.trade h3 {
    color: #1e3c71;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
}
.trade p {
    opacity: 0.8;
    color: #303030;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}
.contracts {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: solid 1px #e9e9e9;
    border-right: solid 1px #e9e9e9;
    border-left: solid 1px #e9e9e9;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: none;
    color: #303030;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    font-weight: 300;
    /* width: 90%; */
}
.contracts-upload-sec:hover {
    color: #000000 !important;
    font-weight: 300;
}
.numbers {
    opacity: 0.55;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
.contracts a {
    color: #4c8dff;
}
.add-new-card {
    /* width: 328px; */
    height: 162px;
    position: relative;
    /* right: 88px; */
    top: 30px;
    border: 1px solid #e9e9e9;
}
.trade-card {
    position: relative;
    /* right: 90px; */
    padding: 22px;
    background-color: #f9f9f9 !important;
    margin-top: 20px;
    /* width: 340px; */
    border-radius: 6px;
}
.trade-done-card {
    /* background-image: url(./assets/trade-done-img.png);
    position: relative; */
    padding: 20px;
    background-color: #f8fdf8;
    margin-top: 22px;
    /* width: 330px; */
    border-radius: 6px;
    height: 450px;
    margin-left: auto;
}
.trade-card h5 {
    padding-bottom: 20px;
}
.table-content {
    overflow-y: auto;
}
.table-company1 {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}
/* .progress {
    height: 10px;
    box-shadow:none;
    border-bottom-right-radius: 200px;
    border-top-right-radius: 200px;
    border-bottom-left-radius: 200px;
    border-top-left-radius: 200px;
    -webkit-box-shadow:none;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(230,230,230,0.3)),to(rgba(230,230,230,0.8)), color-stop(.2,#fff) );
} */
.progress-disable-btn {
    height: 21px;
    width: 21px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-color: #a3a3a3;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.5);
    position: absolute;
    margin-top: -5px;
}
/* .progress-bar {
    box-shadow:none;
    -webkit-box-shadow:none;
    border-bottom-right-radius: 200px;
    border-top-right-radius: 200px;
    object-fit: contain;
    background: linear-gradient(0.25turn,  #232323, #4c8dff, #f700f3);

} */
.gradient-text {
    font-size: 18px;
    font-weight: 700;
    background: linear-gradient(0.25turn, #232323, #4c8dff, #f700f3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.net-pay-value {
    font-size: 18px;
    font-weight: 500;
    color: #58c351;
}
.trading-button {
    width: 91%;
    height: 40px;
    margin: auto;
    padding: 13px 79px 11px;
    opacity: 0.53;
    border-radius: 6px;
    background-color: #cecece;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
}
.trading-button1 {
    margin: 0 5px 0 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    color: #ffffff;
    margin: 32px 0 0;
    padding: 13px 62px 11px;
    border-radius: 6px;
    background-color: #4c8dff;
    border: none;
}
.trading-button1:hover {
    background-color: #002b78;
    color: #ffffff;
}
.trading-button1-disabled {
    margin: 0 5px 0 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    width: 100%;
    color: #ffffff;
    margin: 32px 0 0;
    padding: 13px 62px 11px;
    border-radius: 6px;
    background-color: #b7b7b7;
    border: none;
    cursor: not-allowed !important;
}
.place-order {
    margin: 0 5px 0 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    color: #ffffff;
    margin: 32px 0 0;
    padding: 13px 62px 11px;
    border-radius: 6px;
    background-color: #000000;
    border: none;
    cursor: not-allowed !important;
    width: 272px;
}
.Your-Contracts h3 {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    /* color: #575757; */
    color: #000000;
    position: relative;
    left: 20px;
    top: 30px;
}
.Contracts-add-new h3 {
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #1c1c1c;
    position: relative;
    left: 2px;
    top: 28px;
    height: 24px;
    width: 61px;
    border: 0.5px solid #000000;
    border-radius: 4px;
    padding: 6px;
    align-items: center;
}
.Your-Contract h3 {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #575757;
    position: relative;
    top: 30px;
}
.Your-Contracts p {
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #575757;
    position: relative;
    left: 20px;
    top: 30px;
}
.Your-Contract p {
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #575757;
    position: relative;
    top: 30px;
}
.line-over {
    text-decoration-line: line-through;
    padding-right: 5px;
    color: #a2a2a2;
    font-weight: 300;
    font-size: 12px;
}

.Selected-Contracts p {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 10px 0px;
    font-weight: 300;
}
.upload-page-blur-color {
    color: #c4c4c4;
}
.Selected-Contracts-table p {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    letter-spacing: 0px;
    color: #575757;
    display: flex;
    align-items: center;
}
.table-remaining-price {
    text-align: end;
    padding-top: 10px;
    font-weight: 300;
    font-size: 12px;
    color: #575757;
}
.upload-documents {
    border: 1px solid #979797;
    border-style: dashed;
    text-align: center;
    padding: 50px;
}
.delete-file {
    position: absolute;
    right: 170px;
}
.upload-file {
    position: relative;
    left: 113px;
}
.upload {
    margin-top: 20px;
    padding: 12px 88px 12px 82px;
    border-radius: 6px;
    background-color: #4c8dff;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    color: #ffffff;
    height: 40px;
    width: 100%;
    border: none;
}
.upload-del {
    border: 1px solid #000000;
    border-radius: 6px;
    color: #000000;
    background: #ffffff;
}
.upload-up {
    border-radius: 6px;
    background-color: #4c8dff;
    color: #ffffff;
    border: none;
}
.upload-up:hover {
    background-color: #002b78;
}
.custom-csv {
    position: relative;
}
.custom-csv div div {
    background: none !important;
    color: #4c8dff !important;
    width: max-content;
}
/* .custom-csv div div:nth-child(1):before{
    content:url(./assets/des.svg);
}  */
.custom-csv div div span {
    font-weight: 300 !important;
}
.custom-csv div {
    border-color: #d3d3d3 !important;
}
.csv-icon {
    position: absolute;
    top: 28%;
    left: 22%;
    width: 38px;
    height: 38px;
}
.Sync-your-contracts {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #1c1c1c;
    position: absolute;
}
.Download-sample-CSV {
    opacity: 0.8;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #4c8dff;
    padding-top: 30px;
}
.Rectangle {
    width: 140px;
    height: 40px;
    padding: 13px 18px 11px 19px;
    border-radius: 6px;
    background-color: #4c8dff;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
}
.confirm-back-btn a:hover {
    color: #4c8dff !important;
    fill: #4c8dff !important;
}
.trade-back-btn {
    transform: rotate(180deg);
    margin: 11px 0px;
}
.back :hover {
    color: #4c8dff !important;
}
.Rectangle:hover {
    background-color: #002b78;
    color: #ffffff;
}
.Combined-Shape {
    width: 77px;
    height: 77px;
    margin: 26px 0px;
}
.modal-footer {
    padding: 0px;
}
.Listed-on-Market {
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #00c73c;
    top: 45px;
}
.Contracts-listed-f {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    color: #575757;
    z-index: 1;
    margin-top: 32px;
}
.Amount-will-be-trans {
    height: 32px;
    margin: 70px 4px 35px 5px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0px;
    text-align: center;
    color: #b0b0b0;
}
.trade-done-row {
    padding: 0px;
    margin: 35px 0 0 0;
}
.Manage-in-Tradebook {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0px;
    color: #000000;
}
.Trade-More {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0px;
    color: #000000;
}
.Trade-More:hover {
    color: #4c8dff !important;
}
.Manage-in-Tradebook:hover .Manage-in-Tradebook svg use {
    fill: #4c8dff;
}

.Trade-More:hover .Trade-More svg use {
    fill: #4c8dff;
}

.blue-hover:hover {
    color: #4c8dff;
    text-decoration: none !important;
}
.Trade-More:hover {
    text-decoration: none !important;
    color: #4c8dff;
}
.Contract-With-Company {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #000000;
}
.Contract-With-Company span {
    display: block;
}
.Contract-With-Company-Sync {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #4c4c4c;
}
.Contract-Table-Row-Sync {
    /* text-align: center; */
    color: #4c4c4c;
}
.review-order {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}
.trade-modal-height {
    height: 320px;
    width: 540px;
}
.back {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0px;
    color: #000000;
}
.review-order-price p {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    text-align: right;
    text-align: end;
}

.trade-available-table td,
.trade-available-table thead th {
    padding: 20px 22px;
}
.trade-done-table td,
.trade-done-table thead th {
    padding: 20px 18px;
}

/* width */
.table-content::-webkit-scrollbar {
    width: 13px !important;
    height: 20px;
}

#myDivId::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
.table-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.Bitmap {
    width: 124%;
    height: 296px;
    object-fit: contain;
    position: relative;
    right: 31px;
}
.range {
    position: relative;
    height: 10px;
    width: 100%;
    background-color: #ececec;
    animation: fadeIn 1s ease;
}
.range__trace {
    display: block;
    background: linear-gradient(0.25turn, #232323, #4c8dff, #f700f3);
    height: 100%;
    width: 0;
    border-radius: 30px;
}
.range__handler {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 25%;
    background-color: #000000;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border: 2px solid #ffffff;
}
.investee-scroll {
    /* height: 800px; */
    overflow-y: scroll;
    display: block;
}
.header-fixed > tbody::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    margin-right: 20px;
    margin-left: 0px;
}
.header-fixed > tbody::-webkit-scrollbar-track {
    margin: 0px;
}
.header-fixed > tbody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e6e6e6;
}
.confirm-check-head {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
}
.confirm-checkbox {
    margin-top: 2px;
    margin-right: 10px;
}
.click-drag {
    color: #a7a7a7;
    font-weight: 300;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.header-fixed {
    width: 100%;
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
    display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.header-fixed > tbody {
    overflow-x: hidden;
    overflow-y: auto;
}

.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
    float: left;
}
/* .fixed-width > thead > tr > th:nth-child(1) span,
.fixed-width > tbody > tr > td:nth-child(1) span {
  min-width: 50px;
  width: 50px;
  max-width: 50px;
}
.fixed-width > thead > tr > th:nth-child(1),
.fixed-width > tbody > tr > td:nth-child(1) {
  padding-left: 12px;
}
.fixed-width > thead > tr > th:nth-child(2),
.fixed-width > tbody > tr > td:nth-child(2) {
  width: 21%;
}
.fixed-width > thead > tr > th:nth-child(3),
.fixed-width > tbody > tr > td:nth-child(3) {
  width: 15%;
}
.fixed-width > thead > tr > th:nth-child(4),
.fixed-width > tbody > tr > td:nth-child(4) {
  width: 20%;
}
.fixed-width > thead > tr > th:nth-child(5),
.fixed-width > tbody > tr > td:nth-child(5) {
  width: 15%;
} */

.contract-table-head tr th {
    padding: 15px 12px;
}
.contract-table-head thead tr th:nth-child(1) {
    padding-left: 45px;
}
.contract-table-head thead tr th:nth-child(3) {
    margin-left: 0px;
}
.trade-slider .MuiSlider-root .MuiSlider-thumb {
    box-shadow: 0 2px 6px 1px rgb(0 0 0 / 50%);
}
.trade-slider .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 12px rgb(63 81 181 / 16%);
}
.VS {
    text-align: end;
    margin-top: -10px;
}
.VS:hover span {
    color: #017aff !important;
    cursor: pointer;
}
.VS:hover .VS-icon svg use {
    fill: #017aff !important;
}
.VS:hover .VS-icon svg g {
    fill: #017aff !important;
}
.VS-icon,
.grey-VS {
    padding-right: 2px;
}
.VS-icon svg,
.grey-VS svg {
    margin-top: -4px;
}
.grey-VS svg use,
.grey-VS svg g {
    fill: #c4c4c4 !important;
}
.VS:hover .VS-tooltiptext,
.VS:hover .VS-tooltiptext-ba {
    color: #000000 !important;
}
/* Tooltip css starts */
.VS-tooltip,
.VS-tooltip-ba {
    position: relative;
    display: contents;
    cursor: pointer;
    /* border-bottom: 1px dotted black; */
}

.VS-tooltip .VS-tooltiptext,
.VS-tooltip-ba .VS-tooltiptext-ba {
    visibility: hidden;
    /* width: 120px; */
    /* background-color: black; */
    /* color: #fff; */
    text-align: center;
    text-align: left;
    position: absolute;
    z-index: 1;
    top: 35px;
    right: -16px;
    margin-left: -60px;
    width: 283px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 16px 5px rgb(0 0 0 / 6%);
    color: #575757 !important;
    font-size: 9px;
    letter-spacing: 0;
    line-height: 12px;
}

.VS-tooltip .VS-tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
}
.VS-tooltip-ba .VS-tooltiptext-ba::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 57%;
    margin-left: -5px;
    border: 10px solid transparent;
    border-top-color: #fff;
}

.VS-tooltip:hover .VS-tooltiptext,
.VS-tooltip-ba:hover .VS-tooltiptext-ba {
    visibility: visible;
}
.vs-tooltip-c {
    border-top: 1px solid #eaeaea;
    padding: 10px;
}
.vs-pay-schedule {
    padding: 10px;
    text-align: center !important;
    color: #000000 !important;
}
.vs-tooltip-lc {
    color: #4c4c4c;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
}
/* Tooltip css ends */
.syc-op {
    /* opacity: 0.60; */
    color: #bdbdbd;
}
.syc-op img {
    opacity: 0.6;
}
.upload-billing {
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
}
.upload-billing span {
    cursor: pointer;
    color: #4c8dff;
}
.upload-billing:hover {
    font-weight: 300;
    line-height: 17px;
}
.waved {
    margin-top: -5px;
}
@media only screen and (max-width: 1200px) {
    .Amount-will-be-trans br {
        display: none;
    }
}
/* INVESTEETRADE ends */

/* INVESTOR starts */
.Cart-Slider .MuiSlider-thumb {
    height: 21px;
    width: 21px;
    border: 2px solid #ffffff;
    background-color: #232323;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.5);
}
.Cart-Slider .MuiSlider-track,
.Cart-Slider .MuiSlider-rail {
    height: 11px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
.Cart-Slider .MuiSlider-track {
    background: linear-gradient(213.68deg, #232323 0%, #4c8dff 51.26%, #f700f3 100%);
}
/* .slider-color{
    background: linear-gradient(213.68deg, #006DFF 0%, #4C8DFF 51.26%, #002F92 98.64%) !important;
} */
.cart-table-row td input {
    width: 18px;
    height: 18px;
}
.cart-table tr th:nth-child(1) {
    width: 30%;
}
.cart-table tr th:nth-child(2) {
    width: 13%;
}
.cart-table tr th:nth-child(7) {
    width: 12%;
}

.investbook-table-row td {
    padding: 20px 0px;
}

.place-order-investor {
    margin: 0 5px 0 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    color: #ffffff;
    /* margin: 25px 0 0; */
    padding: 13px 13px 13px;
    border-radius: 6px;
    border: none;
    width: 100%;
}
.place-order-investor-not {
    cursor: not-allowed !important;
}
.place-order-investor:hover {
    color: #ffffff;
}
.place-order-investor:focus {
    outline: none !important;
    box-shadow: none;
}
.purple-hover:hover {
    background-color: #39057b !important;
}
.purple-hover-text {
    font-family: gilroy-semibold, sans-serif;
}
.purple-hover-text:hover {
    color: #39057b !important;
    text-decoration: none !important;
    font-weight: 300;
}
.page-title-investor {
    color: #29153b;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: -10px;
}
.page-title-sub-para-investor {
    color: #7f7f7f;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
    font-size: 12px;
    margin: auto;
}
.investor-slider div:nth-child(n + 2) div div div {
    display: none;
    background-color: #29153b !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5) !important;
}
.trade-card-investor {
    position: relative;
    /* right: 90px; */
    padding: 28px;
    background-color: #f9f9f9 !important;
    /* width: 340px; */
    border-radius: 6px;
}
/* .cart-table tr th::after{
    border: none;
} */
.market-table-head tr th {
    padding: 6px 0px;
}
.market-show-more span {
    font-size: 14px;
}
.market-show-more {
    background-color: transparent;
    border: none;
    padding: 0px;
}
.market-show-more:focus,
.market-filter-select:focus {
    outline: none;
}
.market-filter-select {
    min-height: 27px;
    width: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 2px;
    background-color: #ffffff;
    color: #b1afaf;
    font-size: 12px;
    font-weight: 300;
}
.market-filter-select-opt {
    width: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 2px;
    background-color: #ffffff;
    color: #b1afaf;
    font-size: 12px;
    font-weight: 300;
    padding: 7px;
}
.ml {
    margin-left: 10px;
}
.mt20 {
    margin-top: 20px;
}
.mt30 {
    margin-top: 30px;
}
.mt40 {
    margin-top: 40px;
}
.market-table-row {
    cursor: pointer;
}
.market-table-active-row {
    background-color: #f9f9f9;
    /*color: #000000;
    font-weight: 500; */
    cursor: pointer;
}
.display-content {
    display: contents;
}
.t-14 {
    top: 14%;
    width: 100%;
}
.t-58 {
    top: 58%;
    width: 100%;
}
.logo-in-tbl {
    width: 35px;
    height: 35px;
    object-fit: contain;
    border-radius: 5px;
    margin: 0 5px;
}

.logo-in-tbl-border {
    width: 35px;
    height: 35px;
    object-fit: contain;
    padding: 2px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin: 0 5px;
}

/* Market CP QV */
.graph .recharts-wrapper svg,
.graph .recharts-wrapper {
    width: 100% !important;
}
.company-profile-QV {
    font-family: 'Gilroy-Medium';
    padding: 0px 0px 30px 0px;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgb(0 0 0 / 6%);
    background-color: #ffffff;
    height: max-content;
}
.CPQV-head {
    color: #ffffff;
    background-image: url('./assets/company-logo3.jpg');
    background-size: cover;
    background-position: center;
    object-fit: contain;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.QV-irr {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500 !important;
    letter-spacing: 0;
    line-height: 16px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.QV-company-name {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    text-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
}
.QV-value {
    display: flex;
    align-items: center;
    color: #00df9a;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.QV-value span {
    margin: 0 2px;
    height: 10px;
    width: 10px;
    border-radius: 2px;
    background-color: #00df9a;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.padding20 {
    padding: 18px 28px 0;
}
.padding18 {
    padding: 12px 28px;
}
.QV-company-logo {
    margin-top: -33px;
    width: 77px;
    height: 77px;
    border-radius: 6px;
    object-fit: contain;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
}
.VPD button {
    color: #4c8dff !important;
    background-color: transparent;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    padding: 20px 20px;
    text-align: left;
    border: none;
    cursor: pointer;
}
.VPD button a:hover {
    color: #4c8dff;
}
.contact-us-btn-review {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    border-radius: 4px;
    background-color: #2f8fff;
    padding: 6px 0;
    text-decoration: none;
}
.contact-us-btn-review:hover {
    color: #ffffff;
}
.invest {
    height: 40px;
    width: 85%;
    border-radius: 6px;
    background: linear-gradient(226.98deg, #3c89ff 0%, #4c8dff 29.04%, #f700f3 100%);
    color: #ffffff;
    fill: currentColor;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    border: none;
}
.invest:hover {
    background: #000000;
}
.QV-arr {
    /* opacity: 0.7; */
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 300;
}
.QV-border1 {
    border: 1px solid #e0dcdc;
    border-radius: 6px;
    margin: 30px 30px 0px 30px;
}
.border-top {
    border-top: 1px solid #242424;
}
.arr-padding {
    padding: 10px;
}
.visit-web {
    margin-top: -2px;
    margin-right: 5px;
}
/* Market CP QV Ends*/

/* Market CP DV Starts */
.company-DV {
    color: #ffffff;
    background-image: url('./assets/group-8.jpg');
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 150px;
    background-size: cover;
    background-position: center;
    object-fit: contain;
    margin: 0 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.CPDV-img {
    height: 58px;
    width: 58px;
    margin: 15px;
    /* width: 77px;
    height: 77px; */
    border-radius: 6px;
    object-fit: contain;
    box-shadow: 0 0 10px 5px rgb(0 0 0 / 6%);
    background-color: #fff;
}
.bck-to-mrk {
    padding: 20px;
    color: #29153b;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
}
.DV-img-right {
    color: #ffffff;
    margin-top: auto;
    margin: 19px 15px 15px 0px;
}
.DV-website {
    font-size: 12px;
    line-height: 27px;
    font-weight: 300;
    color: #ffffff;
}
.DV-website:hover {
    color: #ffffff;
}
.DV-company-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
}
.Mrk-CPDV-tab {
    border: none !important;
    padding: 14px 20px !important;
}
.Mrk-CPDV-tab .nav-link.active {
    border: 1px solid #3c3d3e;
    border-radius: 8px;
    background-color: #3c3d3e;
    font-weight: 300 !important;
}
.Mrk-CPDV-tab .nav-item .nav-link {
    height: 30px !important;
    padding: 0px 23px;
    margin-right: 30px;
    color: #ffffff !important;
    font-size: 14px !important;
    letter-spacing: 0 !important;
    font-weight: 300;
    line-height: 30px !important;
    text-align: center !important;
}
.CPDV-add-cart {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0px;
    /* margin: 25px 0 0; */
    background: transparent;
    padding: 13px 0px;
    border: none;
}
.CPDV-add-cart:hover {
    color: #4c8dff;
}
.CPDV-add-cart:hover #shopping_cart_black_24dp path {
    fill: #4c8dff;
}
.CPDV-add-cart-not,
.CPDV-add-cart-not:hover {
    cursor: not-allowed !important;
    color: rgb(165, 162, 162);
}
.CPDV-add-cart-not svg #shopping_cart_black_24dp path,
.CPDV-add-cart-not:hover svg #shopping_cart_black_24dp path {
    fill: rgb(165, 162, 162);
}

/* Market CP DV Ends */

/* Order Placed starts*/
.investor-order-placed {
    background-color: #f8fdf8;
    text-align: center;
    /* max-width: 330px; */
}
.order-place {
    font-size: 26px;
    margin: 20px 0 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 35px;
}
.trade-id {
    color: #000000;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 10px;
}
.order-placed-bg {
    padding: 20px;
}
.our-team-reach {
    color: #b0b0b0;
    font-size: 12px;
    margin-top: 50%;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
}
.order-placed-btn a {
    color: #000000;
}
/* Order Placed ends*/

/* Add to Cart Starts*/
.investor-add-to-cart {
    /* max-width: 330px; */
    text-align: center;
    background-color: #f9f9f9;
}
.add-cart-review {
    color: #b0b0b0;
    width: 250px;
    margin: auto;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}
.continue-cart {
    height: 40px;
    width: 173px;
    border-radius: 6px;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    border: none;
}
.add-cart-back-btn {
    cursor: pointer;
    color: black;
    font-weight: 400;
    font-size: 14px;
}
.add-cart-back-btn:hover {
    color: #4c8dff;
}
.add-cart-back-btn:hover svg path {
    fill: #4c8dff;
}
/* Add to Cart Ends*/

.repayment-table tr td {
    padding: 10px;
}

.investor-green1 {
    color: #00c73c;
}

.Cart-Slider .MuiSlider-rail {
    background-color: #e7e7e7;
}
.back-to-market {
    color: #000000 !important;
    font-weight: 600;
}
.back-to-market:hover {
    color: #4c8dff !important;
    font-weight: 600;
}
.width-1 {
    width: 230px;
}
/* INVESTOR ends */

/* MOBILEVIEW starts */

.mobile_view {
    padding-top: 50px;
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .mobile_view {
        padding-top: 57px;
    }

    .mobile_view h4 {
        padding-top: 55px !important;
    }

    .mobile_view img {
        height: 250px !important;
    }

    /* .mobile_view_button{
        margin-top: 65px;
    } */
}

@media screen and (min-device-width: 411px) and (max-device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) {
    .mobile_view h4 {
        padding-top: 55px !important;
    }

    .mobile_view img {
        height: 250px !important;
        margin-top: 50px !important;
    }
}
/* MOBILEVIEW ends */

/* ONBOARDING starts */
.main-onboarding {
    min-height: calc(100vh - 160px);
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
    scrollbar-width: thin !important;
}
.main-onboarding::-webkit-scrollbar {
    width: 10px !important;
    scroll-padding-right: 100px !important;
}
.main-onboarding::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 0px !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    margin-top: 0px;
    margin-bottom: 2px;
}
.main-onboarding::-webkit-scrollbar-thumb {
    background-color: rgb(168, 168, 168) !important;
    border-radius: 0px !important;
    border-left: 0px solid transparent !important;
    border-right: 0px solid white !important;
}
body {
    margin: 0;
    font-family: 'Gilroy-Medium';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ff {
    font-family: 'Gilroy-Medium';
}
.padding1 {
    padding: 20px;
}
.hmaxc {
    height: max-content;
}
.bgt {
    background: transparent;
}
.bg1 {
    background: #ffffff;
    overflow: overlay;
}
.bg2 {
    background-color: #f9f9f9;
}
.dot-bg {
    position: static;
    margin-top: -775px;
    margin-right: 304px;
}
.dflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.OB-border {
    border-radius: 5px;
    border: solid 0.5px #dcdcdc;
    background-color: #fff;
}
.OB-upload-failed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ce121d;
    border-radius: 4px;
    padding: 16px;
    margin-top: 12px;
}
.OB-upload-failed .failed {
    padding-right: 8px;
}
.OB-column-miss {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #fbeeee;
    color: #d06d6d;
    font-size: 12px;
    padding: 8px 16px;
}

/* Search Bar Starts*/
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.search-input {
    border-radius: 4px;
    border: solid 0.5px #dcdcdc;
    background-color: #fff;
}
/* Search Bar Ends */

/* Step3 Css Starts */
.last-child-step3:last-child {
    margin-bottom: 20px !important;
}
/* Step3 Css Ends */
.zoho-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border: solid 0.5px #dadada;
    border-radius: 2px;
    width: 100px;
    border-radius: 2px;
    cursor: pointer;
}
.zoho-img.selected {
    background-color: #f3f8ff;
    border-color: #4c9eff;
}
.zoho-img.completed {
    border-color: #00bd00;
    background-color: #f2ffec;
}

.zoho-img.success {
    background-color: #f2ffec;
    border-color: #00bd00;
}
.zoho-img.disabled {
    pointer-events: none;
    opacity: 0.4;
    background-color: #f8f8f8;
}
.zoho-img .done-icon {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: -6px;
    right: -6px;
}
.zoho-img.completed .done-icon {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: -6px;
    right: -6px;
}
.green-border {
    border: solid 0.5px #4cb71a;
    padding: 15px 20px;
    border-radius: 5px;
    height: 65px;
    position: relative;
}
/* .zoho-img img{
      height: 30px;
    } */
.other-banks-img {
    border: solid 0.5px #dadada;
    padding: 12px 8px;
    border-radius: 2px;
}
/* .other-banks-img img{
      height: 30px;
    } */
.no-file {
    /* margin-top: 15px;
      margin-left: 37px; */
    color: #7f7f7f;
    width: 79%;
}
.Application Button img {
    display: none;
}
.dot {
    position: sticky;
    margin-top: -45px;
    margin-right: -45px;
}
.dot1 {
    position: sticky;
    margin-top: -45px;
    margin-left: -45px;
}
.dot2 {
    position: sticky;
    margin-top: -89px;
    margin-right: -45px;
}
.ApplicationLowerCard2 {
    padding: 150px 65px 0 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ApplicationLowerCard2 .recur-info {
    margin-top: -350px;
}
.icon-margin img {
    margin-left: 10px;
}
.disable-submit {
    background-color: #b7b7b7;
}
.pls-confirm button:last-child img {
    display: none;
}
.confirm2-modal .modal-dialog {
    margin-top: 7.2%;
}
.confirm3-modal .modal-dialog {
    margin-top: 5.5%;
}
.confirm2-modal.fade.show {
    background: rgba(34, 46, 67, 0.57);
}
/* Form css starts */
/* .box input,
  .box textarea,
  .box select{
    padding: 10px 0;
    margin-bottom: 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    border: none;
    font-size: 14px;
    border-bottom: 1px solid #8f8e8e;
  }
  .box form div{
    position: relative;
  } */
.box form div button {
    position: absolute;
    top: -5px;
    right: 0;
}
/* .box form div span{
    position: absolute;
    top: 3px;
    right: 0;
    color: #4cb71a;
  }
  .box form div label{
    position: absolute;
    top: 14px;
    pointer-events: none;
    left: 0;
    font-size: 14px;
    font-weight: 300;
    color: #8f8e8e;
    transition: .5s;
  }
  .box input:focus ~ label,
  .box textarea:focus ~ label,
  .box input:valid ~ label,
  .box input:disabled ~ label,
  .box textarea:valid ~ label{
    top: -12px;
    left: 0;
    font-size: 10px;
    color: #414141;
    font-weight: 300;
  } */

/* Form css ends */

/* Status Bar Starts */
.progressbar {
    counter-reset: step;
}

.progressbar li {
    position: relative;
    list-style: none;
    float: left;
    width: 33.33%;
    color: #7f7f7f;
    text-align: center;
}

/* Circles */
.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 33px;
    height: 33px;
    border: 1px solid #e4e4e4;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    line-height: 28px;
}

.progressbar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #ededed;
    top: 13px; /*half of height Parent (li) */
    left: -50%;
    z-index: -1;
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.active:before {
    background: #4cb71a;
    /* content: "✔"; */
    content: url('./components/Icons/Done2.svg');
    color: #fff;
}
.warning:before {
    background: #fcc003;
    content: '\26A0';
    color: #fff;
}
.progressbar li.active + li:after {
    background: #4cb71a;
}
.progressbar li.active + li::before {
    -webkit-box-shadow: 0 0 10px #e1f3d9;
    box-shadow: 0px 0px 10px 10px #e1f3d9;
    background-color: #4cb71a;
    color: #fff;
}

/* Status Bar Ends */
.shadow-border {
    border-radius: 10px;
    box-shadow: 0 0 16px 5px rgb(0 0 0 / 5%);
}
/* Prepare Starts */
#onboarding-border {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 16px 5px rgb(0 0 0 / 5%);
}
.onboarding {
    margin: 31px;
    position: absolute;
    top: 25%;
    width: -webkit-fill-available;
}
.padding2 {
    padding: 20px 53px;
}
.margin1 {
    margin-top: 30px;
}
.mt14 {
    margin-top: 14px;
}
.font15 {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
}
.font14 {
    color: #414141;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
.font11 {
    color: #414141;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 15px 0px;
    font-weight: 300;
}
.circularBG {
    max-height: 22px !important;
    max-width: 22px !important;
    background-color: #c1d2ec;
    border-radius: 50%;
    text-align: center;
}
.onboard-btn1 {
    height: 40px;
    width: 156px;
    border-radius: 6px;
    background-color: #4c8dff;
    color: #ffffff;
    border: none;
    text-align: center;
    font-weight: 300 !important;
    padding-top: 2px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 40px;
    margin-top: 20px;
}
/* Prepare Ends */

/* Company Detail Starts */
.center-icon {
    text-align: center;
}
.on-textarea-ht {
    height: 80px;
}
.gray-bg-field {
    background-color: #f8f8f8;
    color: #000000;
}
.on-CD-btn {
    height: 40px;
    width: 120px;
    border: 1px solid #4c8dff;
    border-radius: 6px;
    color: #4c8dff;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.on-CD-btn-row .on-CD-btn:nth-child(1) img {
    margin-right: 5px;
}
.on-CD-btn-row .on-CD-btn:nth-child(2) img {
    margin-left: 5px;
}
.on-CD-btn-row button:nth-child(1) {
    margin-right: 30px;
}
/* Company Detail Ends */
/* ONBOARDING ends */

/* PROFILE starts */
/* Manage Profile Starts */

/* Manage Profile Ends */

/* Tab Css */
.nav-tabs .nav-link {
    font-size: 14px;
    border: none;
    padding: 0px;
    margin-right: 115px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    font-weight: 500;
}
.nav-link.active,
.profile-tabs a:hover {
    color: #000000 !important;
    font-weight: 500;
    text-decoration: none;
}
.nav-link {
    color: lightgray;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 17px;
}
.nav-tabs {
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    padding: 14px 28px;
}
/* Tab Css Ends*/

/* Account css */
.account {
    margin: 0px 20px;
}
.account-btn {
    margin: 0px;
    justify-content: space-between;
    padding-bottom: 80px;
    max-width: 340px;
    min-width: 340px;
}
.account label {
    opacity: 0.37;
    color: #222e43;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 10px;
}
.user-profile-email {
    background-color: #eeeeee;
}
.account input {
    height: 54px;
    width: 340px;
    max-width: 400px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    color: #222e43;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    padding: 10px 20px;
    margin-bottom: 25px;
    outline: none;
    font-weight: 400;
    opacity: 0.7;
}
.account-form {
    margin-right: 35px;
    margin-left: -6px;
    /* margin-top: 30px; */
    /* max-width: 400px; */
}
.acc-form-max-width {
    max-width: 400px;
}
.user-name {
    background-color: #eeeeee;
    opacity: 0.44;
    color: #222e43;
}
.save-btn {
    height: 40px;
    width: 145px !important;
    border-radius: 6px;
    background-color: #4c8dff;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
.save-btn:hover,
.team-invite .send-invite:hover {
    background-color: #002b78;
}
.investor-save-btn {
    height: 40px;
    width: 158px;
    border-radius: 6px;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
.reset-btn {
    box-sizing: border-box;
    height: 40px;
    width: 145px !important;
    border: 1px solid #000000;
    border-radius: 6px;
    background: transparent;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
.reset-btn-investor {
    box-sizing: border-box;
    height: 40px;
    width: 145px !important;
    border: 1px solid $investor;
    border-radius: 6px;
    background: transparent;
    color: $investor;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
.btn-investor {
    background-color: $investor;
}
.border-investor {
    border: 1px solid $investor;
}
.color-investor {
    color: $investor;
}
/* Account css Ends */

/* Company Profile Css Starts */

.border10px {
    border-radius: 10px;
    background-color: #f9f9f9;
}
.company-border2 {
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}
.company-logo {
    height: 47px;
    width: 47px;
    margin: 0px 18px 0px 9px;
    border-radius: 6px;
    background-color: #ffffff;
}
.company-profile-row1 {
    height: 64px;
}
.company-profile-name {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 0px;
}
.company-last-update {
    opacity: 0.51;
    color: #545454;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: left;
    margin-bottom: 0px;
}
.need-update-CP {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    margin-right: 35px;
    cursor: pointer;
}
.need-update-CP span {
    color: #4c8dff;
}
.company-arr-graph {
    height: 200px;
    padding: 20px;
}
.company-arr-graph h3 {
    color: #323232;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}
.graph {
    height: 120px;
}
.graph-waterfall {
    height: 150px;
}
.arr-detail p {
    color: #717070;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}
.arr-incr {
    height: 11.27px;
    width: 11.5px;
    border: 1px solid #d6d6d6;
    border-radius: 1px;
    background-color: #e4e4e4;
    margin-right: 7px;
}
.founded {
    color: #9d9d9d;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 0px;
}
.founded-subtext {
    color: #1c1c1c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    margin: 6px 0px 26px;
}
.CP-overview {
    font-weight: 300;
    color: #171717;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 6px 0px 0px;
    text-align: justify;
}
.ques-icon {
    height: 12px;
    width: 12px;
    margin-left: 10px;
}
.ques-m {
    margin-top: -3px;
}
.add-doc p {
    color: #348cff;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 0px;
    margin-top: 20px;
}
.CP-metrics {
    opacity: 0.27;
    color: #000000;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 4px 0px 4px 5px;
    background-color: #d8d8d8;
    height: 22px;
}
.metrics-LT {
    /* opacity: 0.7; */
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 300;
    padding: 10px 0px 10px 32px;
}
.metrics-LT span {
    opacity: unset;
}
.metrics-RT {
    /* opacity: 0.7; */
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    padding: 7px 0px 7px 15px;
}
.metrics-row {
    border-top: 1px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9;
}
/* Company Profile Css Ends */

/* Team Css */
.MP-team {
    cursor: default;
}
.team-table {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0px;
    min-width: max-content;
}
.team-invite {
    margin-top: 30px;
}
.invite-svg:hover {
    fill: #ffffff;
}
.team-edit-btn {
    cursor: pointer;
}
.team-edit-btn button:nth-child(2) {
    padding: 0px;
}
.team-edit-btn button:nth-child(1) {
    border: none;
    background-color: white;
    width: 22px;
    padding: 0 0 0 0.25rem;
}
.team-input {
    border: 0.5px solid #4c8dff;
    border-radius: 4px;
    width: 100% !important;
    height: 30px !important;
    color: #000000 !important;
    padding: 0 5px !important;
    opacity: unset !important;
    font-size: 12px !important;
    font-weight: 300 !important;
}
.team-input select:focus {
    border: 0.5px solid #4c8dff;
}
.team-span {
    height: 30px;
    float: left;
    display: flex;
    align-items: center;
}
.Trash-modal .modal-content {
    width: 446px;
}

.Trash-modal .modal-dialog {
    margin-top: 8% !important;
}

.team-invite button,
.btn-info,
.btn-info:hover,
.btn-info:focus {
    height: 40px;
    width: 119px;
    border: 1px solid #000000;
    border-radius: 6px;
    background: transparent;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    box-shadow: none;
}
.modal-height {
    height: 365px;
    width: 540px;
}

.modal-header {
    border-bottom: none;
}
.modal-header .close {
    padding: 0px;
    margin: 0px;
    border: none;
    width: 14px;
    opacity: 0.46;
    font-size: 25px;
}
.modal-body {
    margin: auto;
    width: 100%;
    padding: 0px 1.5rem 1.5rem 1.5rem;
}
.modal-body input {
    width: 100%;
    margin-bottom: 15px;
    max-width: 100%;
}

.modal-body .update-data input {
    width: 12.5px !important;
    height: 12.5px;
    max-width: 10% !important;
    margin-bottom: 0px !important;
    margin-right: 10px;
}
.update-data {
    margin-left: 32px;
}

.team-invite .send-invite {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    background-color: #4c8dff;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}
.team-invite .investor-team-send-invite {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    background-color: #000000;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}
.team-invite .investor-team-send-invite:hover {
    /* background-color: #39057B; */
    background-color: #7741f2;
}
.investor-send-invite {
    height: 50px;
    width: 280px;
    border-radius: 6px;
    background-color: #000000;
    border: transparent;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-left: 25%;
    margin-right: 25%;
}
/* Team Css Ends */
/* Bank Accounts CSS */
.account .added-bank {
    max-height: calc(100vh - 180px);
    overflow: auto;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
    scrollbar-width: thin !important;
}
.account .added-bank-account {
    width: '50%';
    height: 205px;
    overflow: auto;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
    scrollbar-width: thin !important;
}
.account .added-bank-account div:nth-child(1) {
    margin-top: 0px !important;
}
.account .active-nach-mandate {
    max-height: 300px;
    min-height: 205px;
    overflow: auto;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
    scrollbar-width: thin !important;
    margin-top: 50px;
}
.bank-accounts-container {
    max-width: 1160px;
    padding: 30px;
}
.add-button {
    width: 65px;
    margin-left: 12px;
    padding: 5px;
    border: 0.5px solid black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.plus-dark-icon {
    height: 10px;
    width: 10px;
}
.pencil-grey-icon {
    height: 13px;
    width: 13px;
}
.button-content {
    font-size: 10px;
    line-height: 14px;
    margin-left: 5px;
}
.payout-button {
    width: 110px;
    margin-left: 100px;
    padding: 5px;
    border: 0.5px solid black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.payout-button-content {
    width: 110px;
    font-size: 10px;
    line-height: 14px;
    /* margin-left: 5px; */
}
.first-row-title {
    font-size: 12px;
    width: 150px;
    margin-right: 30px;
    line-height: 12px;
    font-weight: 500;
}
.account-holder-type {
    font-size: 12px;
    width: 100px;
    color: #959595;
    margin-right: 30px;
    line-height: 12px;
    font-weight: 500;
}
.ifsc-code {
    font-size: 12px;
    /* margin-right: 30px; */
    line-height: 12px;
    font-weight: 300;
    margin: auto;
}
.first-row-content {
    font-family: 'Gilroy-Medium';
    width: 300px;
    font-size: 12px;
    font-weight: 300;
    word-break: break-all;
    margin-left: auto;
    padding-right: 30px;
}
.tick-img {
    width: 15px;
    height: 15px;
    align-self: center;
}
.second-row {
    margin-top: 80px;
}
.user-details-container {
    font-size: 10px;
}
.second-row-title {
    color: #ffffff;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    font-weight: 600;
    width: 100px;
    /* flex-basis: 25%; */
}
.second-row-value {
    color: #ffffff;
    font-size: 12px;
    font-family: 'Gilroy-Medium';
    font-weight: 100;
    flex-basis: 20%;
}
.account-detail-copy {
    color: #ffffff;
    font-size: 12px;
    font-family: 'Gilroy-Medium';
    font-weight: 300;
    max-width: 170px;
    flex-basis: 66%;
}
.account-detail-copy1 {
    color: #ffffff;
    font-size: 12px;
    font-family: 'Gilroy-Medium';
    font-weight: 300;
    max-width: 210px;
    /* flex-basis: 66%; */
}
.modal-form {
    padding: 12px;
}
.two-fields-in-one {
    flex-basis: 47.5%;
}
.encryption {
    color: #adadad;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
}
.icon-arrow-forward {
    height: 15px;
    width: 15px;
}
.lock-icon {
    width: 10px;
    height: 13px;
    margin-bottom: 4px;
}
::placeholder {
    color: #adadad;
}
/* Bank Accounts CSS Ends */
@media only screen and (max-width: 580px) {
    .account-form {
        margin-right: 0px;
    }
    .account {
        margin: 0px;
    }
    .team-body tr td {
        font-size: 11px;
    }
    .table td,
    .table thead th {
        padding: 20px 0px;
    }
    .modal-height {
        width: 100%;
    }
    .team-invite .send-invite {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .save-btn,
    .reset-btn {
        width: 126px;
    }
    .nav-tabs .nav-link {
        padding: 10px 0px;
        margin-right: 0px;
    }
    .nav {
        display: block;
    }
}
@media only screen and (max-width: 820px) {
    .nav-tabs .nav-link {
        margin-right: 57px;
    }
    .account-form {
        margin-right: 0px;
    }
    .account-btn {
        justify-content: space-evenly;
    }
    .save-btn,
    .reset-btn {
        width: 120px;
    }
}
@media only screen and (max-width: 990px) {
    .account-form {
        margin-right: 0px;
    }
}
/* PROFILE ends */

/* SIDEBAR starts */
.logout-ht {
    height: calc(100vh - 30px);
}
.sidebar {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    padding: 30px 0px 0px 0px;
    min-height: 100vh;
    z-index: 3;
    min-width: 181px;
    position: fixed;
    height: 100%;
    overflow: visible;
    border-top-right-radius: 55px;
}
.sidebar {
    /* background-color: #222E43; */
    background: #020948;
    border-top-right-radius: 0px;
}
.sidebar-recur-logo {
    width: 120px;
    /* width: 70%; */
    margin-bottom: 50px;
    margin-left: 20px;
    margin-top: 10px;
}

.sidebar-recur-logo:hover {
    cursor: pointer;
}

.activeStyle {
    background-color: #7349af;
    text-decoration: none !important;
    opacity: unset !important;
}
.activeStyle-investee {
    background-color: #4957a1;
    text-decoration: none !important;
    opacity: unset !important;
}

.activeStyleCart,
.activeStyleCart:hover,
.activeStyleAutoInvest,
.activeStyleAutoInvest:hover {
    background-color: #ffffff !important;
    color: #261739 !important;
    text-decoration: none !important;
    opacity: unset !important;
}
.activeStyleCart::after,
.activeStyleAutoInvest::after {
    color: #ffffff;
}
.activeStyleCart::before {
    content: url(./assets/shopping-cart-black.svg) !important;
    margin-left: -10px;
    line-height: 0px;
    margin-right: 6px;
}
.activeStyleAutoInvest::before {
    content: url(./assets/ai.png) !important;
}

/* Insider & Checkout css starts for siderbar */
.Insider-inactive,
.Insider-inactive:hover,
.Checkout-inactive,
.Checkout-inactive:hover {
    border: none !important;
    /* background-color: #172462; */
    height: 40px;
    font-size: 14px;
    color: #ffffff;
    /* color: #D4AC4D !important; */
    font-weight: 300 !important;
    margin: 3px 0px 3px 0px;
    padding-top: 8px;
}
.Checkout-inactive span,
.Checkout-active span,
.Insider-inactive span,
.Insider-active span {
    margin-top: 9px;
    position: absolute;
    margin-left: 9px;
}
@mixin checkoutInsiderAfter {
    display: block;
    font-size: 6px;
    font-weight: 300 !important;
    margin-left: 29.8%;
    margin-top: -30px;
    line-height: 14px;
    position: relative;
}
.Checkout-inactive::after,
.Checkout-active::after {
    @include checkoutInsiderAfter;
}

.Insider-inactive::after,
.Insider-active::after {
    content: 'RECUR';
    @include checkoutInsiderAfter;
}
.Checkout-inactive::before,
.Checkout-active::before {
    content: '';
    background: url(./assets/checkout.svg) no-repeat 0 0;
    /* background: url(./assets/insider.svg) no-repeat 0 0; */
    background-size: 100%;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 25px;
    margin-top: 6px;
}
.Insider-inactive::before,
.Insider-active::before {
    content: '';
    background: url(./assets/deal-chest.svg) no-repeat 0 0;
    background-size: 100%;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 25px;
    margin-top: 6px;
}
.Checkout-active,
.Checkout-active:hover,
.Insider-active,
.Insider-active:hover {
    background-color: #4957a1;
    height: 40px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 300 !important;
    margin: 3px 0px 3px 0px;
    padding-top: 8px;
}
.Insights::before {
    content: '';
    background: url(./assets/insights.svg) no-repeat 0 0;
    background-size: 100%;
    display: inline-block;
    width: 20px;
    height: 20px;
    // margin-left: 25px;
    margin-top: 6px;
    margin-right: 8px;
}
.Insights {
    // background-color: #4957a1;
    height: 45px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 300 !important;
    margin: 3px 0px 3px 0px;
    padding: 8px 0;
    border: 1px solid #000000;
}
.Insights-active {
    background-color: #4957a1;
}
.Insights::after {
    content: 'RECUR';
    display: block;
    margin-top: -26px;
    margin-left: 29px;
    font-size: 6px;
    font-weight: 300 !important;
    line-height: 14px;
    position: relative;
}
/* Insider css ends for siderbar */

/* .sidebar a:nth-child(8):hover {
  color: #5e5e5e;
} */

.sidebar-link,
.sidebar-disabled {
    /* color: #9DA1A7; */
    padding: 5% 25px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 300;
    margin: 3px 0px;
}
.sidebar-link {
    color: #ffffff;
}
.sidebar-disabled,
.sidebar-disabled:hover {
    color: #9199ca !important;
}
.sidebar a:nth-child(2):hover,
.sidebar a:nth-child(3):hover,
.sidebar a:nth-child(4):hover,
.sidebar a:nth-child(5):hover,
.sidebar a:nth-child(6):hover,
.sidebar a:nth-child(7):hover,
.sidebar a:nth-child(9):hover,
.sidebar a:nth-child(10):hover,
.sidebar a:nth-child(11):hover {
    opacity: unset;
    font-weight: 300;
    color: #ffffff;
    text-decoration: none !important;
}
.soon {
    font-size: 8px;
}
.disable-modules {
    color: #9199ca;
    padding: 7% 25px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 300;
    margin: 3px 0px;
}
.disable-modules:hover {
    color: #9199ca !important;
}
.sidebar-line,
.sidebar-line:hover {
    border: 0.5px solid #e3e3e3;
    max-width: 220px;
    cursor: default;
    margin: 8% 10px;
    padding: 0px;
    border-top: none;
}
.sidebar-modules,
.sidebar-modules:hover {
    color: #ffffff !important;
    padding: 5% 25px !important;
    font-size: 14px !important;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 300;
    margin: 3px 0px !important;
    border: none !important;
}
/* SIDEBAR ends */

/* SIGNUP starts */
.banner {
    background: url(https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/bg5.svg);
    width: 100%;
    min-height: 100vh;
    background-size: cover !important;
    object-fit: cover !important;
}
.loginBG {
    background-color: #00094b;
}
.carousel-heading {
    color: #fff1e2;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
}
.recur-logo {
    text-align: left;
    padding: 20px;
}
.recur-logo:hover {
    cursor: pointer;
}
.for-companies-with-revenue {
    /* height: 22px; */
    /* width: 450px; */
    color: #83b9ff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 22px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.recur-revenue {
    position: relative;
    top: 145px;
    height: 200px;
    left: 13% !important;
}
.access-to-your-annual {
    color: #ffffff;
    font-size: 48px !important;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 56px;
    text-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
}
.inverted-comma {
    font-family: auto;
}
form h4 {
    color: #222e43;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
}
.recur-allows-you {
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    padding-top: 30px;
}
.recur-card {
    background: #ffffff;
    padding: 41px;
    width: 434px !important;
    height: 590px;
    flex: none;
    margin-left: 60px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px 4px rgba(0, 0, 0, 0.23);
}
.signup-margin {
    margin-top: -25px !important;
    padding: 40px 40px 10px 40px !important;
    margin-bottom: 10px !important;
}
.dont-have-acc {
    margin-top: 20px;
    font-family: gilroy-medium, sans-serif;
    font-size: 14px;
    line-height: 19px;
}
.line {
    height: 1px;
    width: 50%;
    border-top: 0.5px solid #e4e4e4;
}
.abcRioButtonContentWrapper {
    border-radius: 5px !important;
}
.abcRioButtonIcon {
    border-radius: 5px !important;
}
.abcRioButton {
    width: 100% !important;
    border-radius: 5px !important;
}
.loginInput {
    border: 0.5px solid #ffffff;
    border-radius: 6px !important;
    font-weight: 600;
    background-color: #f9f9f9;
    color: #c3c3c3;
    font-size: 12px;
    line-height: 12px;
}
.form-control:focus {
    background-color: #f9f9f9;
    border-color: #ffffff;
    box-shadow: 0 0 0 0.2rem rgba(170, 170, 170, 0.35);
}
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login img {
    position: absolute;
    padding-top: 4px;
}
.login p {
    padding-left: 30px;
    color: #348cff;
    padding-top: 4px;
}
.form-control {
    height: calc(2.5em + 0.75rem + 2px);
}
.button {
    background: #4c8dff;
}
.requesting-access {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 14px;
    margin-top: 30px;
    width: 403px;
    position: relative;
    right: 37px;
}
article p {
    opacity: 0.5;
    color: #348cff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.8px;
    line-height: 17px;
}
/* article img {
  padding-top: 30px;
} */
.btn-block {
    display: block;
    width: 120px;
}
.request-submitted {
    padding-top: 60px;
}
.request-submitted h3 {
    opacity: 0.29;
    color: #000000;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 29px;
}
.request-submitted p {
    opacity: 0.4;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding-top: 9px;
}
.form-group {
    padding-top: 13px;
}
.home {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    position: relative;
    top: 42px;
    cursor: pointer;
}
.recur-card h4 {
    font-family: 'Gilroy';
}
article hr {
    border-top: 1px solid #979797;
    opacity: 0.26;
    margin-top: 30px;
    margin-left: 40px;
    margin-right: 40px;
}
/* .remove-decor:hover{
    text-decoration: none !important;
  }
  .remove-decor{
    font-weight: 300;
  } */
.form-icons {
    padding-top: 11px;
    margin-right: 8px;
}
.abcRioButtonContents {
    font-family: gilroy, sans-serif !important;
}
.clickableArea-in {
    width: 28px;
    height: 28px;
    margin-top: -4px;
}
.by-clicking {
    font-family: gilroy-regular, sans-serif !important;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.08px;
    line-height: 18px;
    font-weight: 500;
}
.by-clicking a {
    color: #2f8fff;
}
.by-clicking a:hover {
    font-weight: 500;
}
input[type='text']:focus {
    color: #000000;
    outline: none;
}
.form-control {
    color: #000000;
}
.email-verified-RS {
    height: 17px;
    width: 99px;
    border-radius: 4px;
    background-color: #e5fcde;
    color: #00c73c;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    padding: 5px 10px;
}
.request-sub-ht {
    height: 580px !important;
}

/* Media queries */

@media only screen and (min-width: 1024px) {
    .display-in-mob {
        display: none;
    }
}
@media only screen and (max-width: 1023px) {
    .display-in-mob {
        display: none;
    }
    .for-companies-with-revenue {
        font-size: 14px !important;
    }
    .recur-allows-you {
        /* margin-top: 52px; */
        height: auto;
        padding: 0px !important;
    }
    .access-to-your-annual {
        font-size: 35px;
        width: auto;
        height: auto !important;
    }
    .quotation {
        position: relative;
        width: 25px;
        left: -4px !important;
    }
    .login-inverted-comma {
        width: 25px;
    }
    .login-btn {
        margin-right: 0px !important;
    }
    .request-access-btn {
        width: 100% !important;
    }
    .requesting-access {
        width: auto !important;
    }
}
@media only screen and (max-width: 770px) {
    .recur-revenue {
        display: none;
    }
    .display-in-mob {
        display: block;
        text-align: center;
        margin: 20px auto;
    }
    .recur-allows-you {
        padding-top: 0px !important;
    }
    .quotation {
        position: relative;
        width: 25px;
        left: -1px !important;
        top: -16px;
    }
    .login-inverted-comma {
        width: 25px;
    }
    .access-to-your-annual {
        height: auto;
    }
}
@media only screen and (max-width: 550px) {
    .recur-card {
        width: 86% !important;
    }
    .login-btn {
        margin: 0px;
    }
    .res-investee-login {
        width: 100%;
    }
    .display-in-mob {
        display: block;
    }
}

/* SIGNUP ends */

/* TABLE starts */
.market h3 {
    color: #29153b;
    font-family: Aktiv-grotesk;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
}
.market p {
    opacity: 0.8;
    color: #303030;
    font-family: Aktiv-grotesk;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}
.listed-companies {
    opacity: 0.8;
    color: #000000;
    font-family: Aktiv-grotesk;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}
.listed-companies h5 {
    opacity: 0.55;
    color: #000000;
    font-family: Aktiv-grotesk;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
}
.horizontal {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #dcdcdc;
    width: 100%;
}

.table-content {
    box-sizing: border-box;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    background-color: #ffffff;
    position: relative;
    top: 27px;
    border-spacing: 0px;
    border-collapse: unset;
}
.table-content-upload {
    box-sizing: border-box;
    border: 1px solid #e9e9e9;
    background-color: #ffffff;
    position: relative;
    top: 27px;
    border-spacing: 0px;
    border-collapse: unset;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: 8px;
}
.table-content th {
    font-weight: 400;
}
.table-company {
    color: #4c8dff;
    font-family: Aktiv-grotesk;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}
.table-range {
    display: flex;
    flex-wrap: wrap;
    margin: 2em;
    width: 349px;
    padding: 25px;
    position: relative;
    left: -183px;
    background-color: #f2f2f2;
}
/* Market Css starts*/
.market-RS {
    margin: 2em;
    position: relative;
    left: -183px;
    border-radius: 6px;
    background-color: #f9f9f9;
}
.market-RS1 {
    /* max-width: 349px; */
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    background-color: #ffffff;
    margin-bottom: 30px;
    margin-top: 20px;
}
.slider-tag,
#output {
    color: #575757;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 300;
}
.sector-tag {
    color: #006dff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 300;
}
.market-contract-table {
    max-height: calc(100vh - 270px) !important;
}
.market-table1 tr th:nth-child(1) {
    width: 33%;
}
.market-table1 tr th:nth-child(2) {
    width: 11%;
    padding-left: 11px;
    text-align: left !important;
}
.market-table1 tr th:nth-child(3) {
    width: 15%;
    padding-left: 17px;
}
.market-table1 tr th:nth-child(4) {
    width: 18%;
    padding-left: 7px;
}
.market-table1 tr th:nth-child(5) {
    width: 20%;
}
.market-table1 tr th:nth-child(6) {
    width: 3%;
}
/* .slider-color{
  background: linear-gradient(
    213.68deg
    , #006DFF 0%, #4C8DFF 51.26%, #002F92 98.64%) !important;
} */
/* Market Css ends */
.advance-filter {
    color: #000000;
    font-family: Aktiv-grotesk;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}
.market {
    padding-left: 40px;
}
/* Separate Table Head Component css starts */
.new-table-head {
    border: 1px solid #e9e9e9;
    border-bottom: none;
    border-radius: 6px;
    padding: 0px;
    border-collapse: unset;
    border-spacing: 0px;
}
.new-table-head thead tr th {
    font-size: 12.5px;
    font-weight: 500;
}
.table-trade-margin {
    margin: 4px 0px 0px;
}
.table-tradebok-margin {
    margin: 0px 25px 0px 25px;
}
.tradebok-m2 {
    margin: 3px 25px;
}
.contract-table-head1 tr th {
    padding: 3px 12px;
}
.contract-table-head1 tr th:nth-child(1) {
    width: 30%;
    padding-left: 10px;
}
/* .market-table1 tr th:nth-child(5) .filter-div {
  padding-left: 0px;
} */
/* .contract-table-head1 tr th:nth-child(1) .filter-div,
.contract-table-head1 tr th:nth-child(2) .filter-div,
.contract-table-head1 tr th:nth-child(3) .filter-div,
.contract-table-head1 tr th:nth-child(4) .filter-div,
.contract-table-head1 tr th:nth-child(5) .filter-div {
  padding-left: 0px !important;
}

.market-table1 tr th:nth-child(5) .filter-div:hover,

.contract-table-head1 tr th:nth-child(1) .filter-div:hover,
.contract-table-head1 tr th:nth-child(2) .filter-div:hover,
.contract-table-head1 tr th:nth-child(3) .filter-div:hover,
.contract-table-head1 tr th:nth-child(4) .filter-div:hover,
.contract-table-head1 tr th:nth-child(5) .filter-div:hover {
  padding-left: 10px !important;
} */
/* .tradebok tr th:nth-child(9) .filter-div{
  padding-left: 0px;
}
.tradebok tr th:nth-child(9) .filter-div:hover{
  padding-left: 10px;
} */
/* .trans-trade tr th:nth-child(2) .filter-div{
  padding-left: 8px;
} */
/* .trans-trade tr th:nth-child(3) .filter-div{
  padding-left: 4px;
} */
/* .trans-trade tr th:nth-child(4) .filter-div{
  padding-left: 4px;
} */
/* .trans-trade tr th:nth-child(5) .filter-div{
  padding-left: 4px;
} */
/* .trans-trade tr th:nth-child(2) .filter-div:hover,
.trans-trade tr th:nth-child(3) .filter-div:hover,
.trans-trade tr th:nth-child(4) .filter-div:hover,
.trans-trade tr th:nth-child(5) .filter-div:hover,
.trans-trade tr th:nth-child(6) .filter-div:hover,
.trans-trade tr th:nth-child(7) .filter-div:hover,
.trans-trade tr th:nth-child(8) .filter-div:hover {
  padding-left: 10px;
}
.trans-trade tr th:nth-child(6) .filter-div,
.trans-trade tr th:nth-child(7) .filter-div,
.trans-trade tr th:nth-child(8) .filter-div {
  padding-left: 0px;
} */
.contract-table-head1 tr th:nth-child(2) {
    padding-left: 5px;
    width: 18%;
    text-align: left;
}
.contract-table-head1 tr th:nth-child(3) {
    padding-left: 7px;
    width: 14%;
}
.contract-table-head1 tr th:nth-child(4) {
    padding-left: 9px;
    width: 18%;
}
.contract-table-head1 tr th:nth-child(5) {
    width: 20%;
    padding-left: 11px;
}
/* .LRP-0 td{
  padding: 10px 0px;
} */
.tradebok tr th:nth-child(1) {
    width: 10%;
}
.tradebok tr th:nth-child(2) {
    width: 10%;
}
.tradebok tr th:nth-child(3) {
    width: 8%;
}
.tradebok tr th:nth-child(4) {
    width: 10%;
}
.tradebok tr th:nth-child(5) {
    width: 8%;
}
.tradebok tr th:nth-child(6) {
    width: 10%;
}
.tradebok tr th:nth-child(7) {
    width: 10%;
}
.tradebok tr th:nth-child(8) {
    width: 10%;
}
.tradebok tr th:nth-child(9) {
    width: 11%;
}
.tradebok tr th:nth-child(10) {
    width: 12%;
}
.tradebok tr th:nth-child(11) {
    width: 2%;
}
.tradebok tr th:nth-child(10) .container .filter-selector {
    height: auto;
    width: min-content !important;
}

.trans tr th:nth-child(1) {
    width: 16%;
}
.trans tr th:nth-child(2) {
    width: 12%;
}
.trans tr th:nth-child(3) {
    width: 8%;
    padding-left: 10px;
}
.trans tr th:nth-child(4) {
    width: 12%;
    padding-left: 14px;
}
.trans tr th:nth-child(5) {
    width: 10%;
    padding-left: 20px;
}
.trans tr th:nth-child(6) {
    width: 12%;
    padding-left: 20px;
}
.trans tr th:nth-child(7) {
    width: 8%;
    padding-left: 20px;
}
.trans tr th:nth-child(8) {
    width: 10%;
    padding-left: 20px;
}
.trans tr th:nth-child(9) {
    width: 12%;
    padding-left: 20px;
}
.trans tr th:nth-child(10) {
    width: 2%;
    padding: 0px !important;
    text-align: end;
}
.trans-trade tr th:nth-child(1) {
    width: 18%;
    padding-left: 5px;
}
.trans-trade tr th:nth-child(2) {
    width: 12%;
}

.trans-trade tr th:nth-child(3) {
    width: 10%;
}

.trans-trade tr th:nth-child(4) {
    width: 10%;
}

.trans-trade tr th:nth-child(5) {
    width: 12%;
}
.trans-trade tr th:nth-child(6) {
    width: 12%;
}
.trans-trade tr th:nth-child(7) {
    width: 12%;
}
.trans-trade tr th:nth-child(8) {
    width: 14%;
}

.trans-investor tr th:nth-child(1) {
    width: 18%;
}
.trans-investor tr th:nth-child(2) {
    width: 10%;
}
.trans-investor tr th:nth-child(3) {
    width: 10%;
}
.trans-investor tr th:nth-child(4) {
    padding-left: 0px;
    width: 12%;
}
.trans-investor tr th:nth-child(5) {
    width: 14%;
}
.trans-investor tr th:nth-child(6) {
    width: 14%;
}
.trans-investor tr th:nth-child(7) {
    width: 12%;
}
.trans-investor tr th:nth-child(8) {
    width: 8%;
}
.trans-investor tr th:nth-child(9) {
    width: 2%;
    padding: 0px !important;
    text-align: end;
}
.trans-investor-repay tr th:nth-child(8) {
    width: 12%;
}
.trans-investor-repay tr th:nth-child(7) {
    width: 12%;
}
.trans-investor-repay tr th:nth-child(6) {
    width: 14%;
}
.trans-investor-repay tr th:nth-child(5) {
    width: 12%;
}
.trans-investor-repay tr th:nth-child(4) {
    width: 12%;
}
.trans-investor-repay tr th:nth-child(3) {
    width: 10%;
}
.trans-investor-repay tr th:nth-child(2) {
    width: 10%;
}
.trans-investor-repay tr th:nth-child(1) {
    width: 18%;
    padding-left: 0px;
}
.trans tr th:nth-child(9) .filter-selector,
.trans tr th:nth-child(8) .filter-selector,
.trans-investor tr th:nth-child(8) .filter-selector,
.trans-investor tr th:nth-child(9) .filter-selector,
.trans-investor-repay tr th:nth-child(8) .filter-selector,
.TIInvest tr th:nth-child(6) .filter-selector,
.new-table-head thead tr th:nth-child(6) .filter-selector,
.trans-trade .filter-selector {
    height: auto;
    width: min-content;
}
/* Separate Table Head Component css ends */
/* TABLE ends */

/* TRADEBOOK starts */
.tradebook-head {
    width: 11%;
}

.do-not-display-fa .fas,
.do-not-display-fa .fa {
    display: none !important;
}
.display-fa .fas,
.display-fa .fa {
    display: inline-block !important;
}

/* Dashbboard Drawdown Notif */
.dashboard-drawdown {
    max-width: 1135px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #fcbf07;
    border-radius: 9px;
    background-color: #fffdf0;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 12px;
}

.dashboard-drawdown .view-btn {
    border-radius: 4px;
    background: linear-gradient(213.68deg, #3c89ff 0%, #4c8dff 5.89%, #f700f3 100%);
    padding: 8px 14px;
    font-size: 10px;
    color: white;
}

.view-btn-investor {
    border-radius: 4px;
    background: $investor;
    padding: 8px 14px;
    font-size: 10px;
    color: white;
}

/* Table Spin Loader css starts */
#loading-spinner {
    position: relative;
    z-index: 999999;
}
.deal-doc-spin {
    margin-right: 6px;
    margin-top: 3px;
}

#loading-spinner .spin-icon {
    margin: auto;
    width: 15px;
    height: 15px;

    border: solid 3px transparent;
    border-top-color: #4c8dff;
    border-left-color: #4c8dff;
    border-radius: 80px;

    -webkit-animation: loading-spinner 500ms linear infinite;
    -moz-animation: loading-spinner 500ms linear infinite;
    -ms-animation: loading-spinner 500ms linear infinite;
    -o-animation: loading-spinner 500ms linear infinite;
    animation: loading-spinner 500ms linear infinite;
}

@-webkit-keyframes loading-spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes loading-spinner {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes loading-spinner {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes loading-spinner {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/* TRADEBOOK ends */

/* data-vault css starts */
.dv tr th:nth-child(2) {
    padding-left: 7px !important;
}
.dv tr th:nth-child(3) {
    padding-left: 6px !important;
}
.dv tr th:nth-child(4) {
    padding-left: 6px !important;
}
.dv tr th:nth-child(5) {
    padding-left: 4px !important;
}
.dv tr th:nth-child(6) {
    padding-left: 3px !important;
}
.lastChild div:last-child {
    margin-bottom: 0px !important;
}
.data-vault-modal .modal-dialog {
    max-width: 560px;
}
.DV-upload .upload-img {
    width: 40px !important;
    margin-right: 0px !important;
    margin-left: -4px;
}
/* data-vault css ends */
.update-modal-down {
    margin-left: 35px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
}
/* Demo user css starts */
.acc-locked {
    margin: 5px 34px 20px 25px;
    height: 23px;
    border-radius: 12px 95px 95px 12px;
}
.acc-locked-modal .modal-content {
    width: 540px;
}
.acc-locked-modal1 {
    // overflow-y: hidden !important;
    .modal-content {
        width: 600px;
    }
}
.acc-under-review {
    margin: 20px;
    height: 25px;
    border-radius: 10px 95px 95px 10px;
    justify-content: center;
}
.acc-under-review a:hover {
    color: #4c8dff !important;
}
.acc-under-review button {
    background: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    line-height: 0px;
}
.acc-under-review-hidden {
    visibility: hidden;
}
.SB-tooltips {
    position: relative !important;
    display: contents !important;
    cursor: pointer;
    border-bottom: 1px dotted black !important;
}

.SB-tooltips .SB-tooltiptexts {
    /* visibility: hidden !important; */
    padding: 10px;
    text-align: left;
    position: absolute;
    z-index: 1;
    top: 45px;
    left: 36%;
    width: 254px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.05);
    color: #030303;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
}
.SB-tooltips .SB-tooltiptexts::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
}

/* .SB-tooltips:hover .SB-tooltiptexts{
    visibility: visible !important;
} */
/* Demo user css ends */

/* Common Fonts css starts */
/* font Size Starts */
.fs24 {
    font-size: 24px;
}
.fs20 {
    font-size: 20px;
}
.define1 {
    font-size: 18px;
}
.define {
    font-size: 14px;
}
.fs15 {
    font-size: 15px;
}
.define2 {
    font-size: 16px;
}
.fs12 {
    font-size: 12px;
}
.fs10 {
    font-size: 10px;
}
.fs9 {
    font-size: 9px;
}
.fs8 {
    font-size: 8px;
}

/* font Weight Starts */
.fw6 {
    font-weight: 600;
}
.fw5 {
    font-weight: 500;
}
.fw4 {
    font-weight: 400;
}
.steps,
.fw3 {
    font-weight: 300;
}

/* font Color Starts */
.text-clr1 {
    color: #b9b9b9;
}
.text-clr2 {
    color: #999999;
}
.text-clr3 {
    color: #7c7c7c;
}
.text-clr4 {
    color: #ffffff;
}
.text-clr5 {
    color: #4f4f4f;
}
.clr6 {
    color: #7f7f7f;
}
.clr7 {
    color: #070707;
}
.clr8 {
    color: #414141;
}
.clr9 {
    color: #4c8dff;
}
.clr10 {
    color: #233396;
}
.clr11 {
    color: #4cb71a;
}
.clr13,
.clr12 {
    color: #343434;
}
.clr14 a {
    color: #000000 !important;
}
.clr15 {
    color: #c7c7c7;
}
.clr16 {
    color: #345b9c;
}
.clr17 {
    color: #a2a2a2;
}
.clr18 {
    color: #696969;
}
.clr19 {
    color: #ba0000;
}
.clr20 {
    color: #707070;
}
.clr21 {
    color: #fcbf07;
}
.clr22 {
    color: #ffffff;
}
.clr23 {
    color: #d70505;
}
.clr24 {
    color: #f2b216;
}
.clr25 {
    color: #72ff8c;
}
.clr26 {
    color: #20c1c3;
}
.clr27 {
    color: #d95656;
}
.clr28 {
    color: #20c1c3;
}
.clr29 {
    color: #575757;
}
.clr30 {
    color: #75c2ff;
}
.clr31 {
    color: #626262;
}
.clr32 {
    color: #58d76b;
}
.clr33 {
    color: #b79cdd;
}
.clr34 {
    color: #00457f;
}
.clr35 {
    color: #ce121d;
}
.clr36 {
    color: #00bd00;
}
.clr37 {
    color: #1c1c1c;
}
.clr38 {
    color: #a4a4a4;
}
.clr39 {
    color: #00ad00;
}
.clr40 {
    color: #bcbcbc;
}
.clr-red {
    color: #c80000;
}
.clr-mustard {
    color: #d09413;
}
.clr-grey {
    color: #8d8d8d;
}
.clr-dark-blue {
    color: #14143c;
}
.limit-add-on-btn:disabled {
    background-color: #e6e6e6;
}
.clr-green {
    color: #00aa00;
}
/* Background Color Starts */
.bgclr1 {
    background-color: #ffe234;
}
.bgclr2 {
    background-color: #fff7e9;
}
.bgclr3 {
    background-color: #f1f1f1;
}
.bgclr4 {
    background-color: #4cb71a;
}
.bgclr5 {
    background-color: #f7f7f7;
}
.bgclr6 {
    background-color: #d06d6d;
}
.bgclr7 {
    background-color: #f2ffec;
}
.bgclr8 {
    background-color: #ff6600;
}
.bgclr9 {
    background-color: #f8f8f8;
}
/* Border color */
.bclr1 {
    border: 1px solid #fb4646 !important;
}
/* Border radius */
.br4 {
    border-radius: 4px;
}
.br6 {
    border-radius: 6px;
}
.br8 {
    border-radius: 8px;
}
.br15 {
    border-radius: 15px;
}
/* text-center */
.textc {
    text-align: center;
}
/* height */
.h85vh {
    height: 85vh;
}
/* width */
.w-maxc {
    width: max-content;
}
/* vertical align center */
.vc {
    top: 50%;
    transform: translateY(-50%);
}
/* Common Fonts Css Ends */
/* Phone SVG */
.phone-number {
    color: #070707;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Gilroy';
    letter-spacing: 0em;
}
/* Phone SVG Ends */
.login-page {
    position: absolute;
    width: 100%;
    top: 22%;
}
.login-page-investor {
    position: absolute;
    width: 100%;
}

@media (min-resolution: 120dpi) {
    /* .sidebar, .investor-sidebar{
    min-width: 160px;
  }
  .logged-in-wrapper {
    width: 100%;
    margin: 0px 0px 0px 160px;
  }
  .sidebar-link, .sidebar-disabled,.sidebar-line:hover {
    font-size: 13px !important;
  }
  .disable-modules {
    padding: 1vh 20px;
    font-size: 13px;
  }
  .disable-modules {
    padding: 1vh 20px;
    font-size: 11px;
  }
  .sidebar-modules,
  .sidebar-modules:hover{
    padding: 1vh 20px !important;
    font-size: 13px !important;
  }
  .sidebar-line, .sidebar-line:hover {
    margin: 5% 7px !important;
  }
  .acc-under-review {
    margin: 5px 10px 10px 20px;
  }
  .acc-locked {
    margin: 5px 12px 5px 20px;
  }
  .page-head-btn-logout {
    margin: 0.5vh 0px;
    font-size: 13px !important;
    margin-left: 20px;
  } */
    /* .Insider-inactive,
  .Insider-inactive:hover {
    font-size: 14px;
    margin: 5% 40px 5% 10px;
  }
  .Insider-inactive::after{
    font-size: 6px;
    margin-left: 37.8% !important;
    margin-top: -31px !important;
  }
  .Insider-inactive::before,
  .Insider-active::before{
    width: 25px;
    height: 25px;
  }
  .Insider-active,
  .Insider-active:hover {
    margin: 5% 0px;
    font-size: 14px;
  }
  .Insider-active::after{
    font-size: 6px;
    margin-left: 32.5% !important;
    margin-top: -31px !important;
  } */
    /* .access-to-your-annual,
  .access-to-your-annual-investor {
    font-size: 38px !important;
  }
  .recur-allows-you,.recur-allows-you-investor  {
      font-size: 20px !important;
      padding-top: 5px !important;
  }
  .recur-card {
    padding: 25px !important;
    width: 350px !important;
  }
  .recur-card h4 {
    font-size: 20px;
  }
  .recur-revenue {top: 80px !important;}
  .recur-revenue-investor {top: 41px !important;}
  .form-group,
  .res-investee-login {
    padding-top: 5px !important;
    margin-bottom: 10px;
  }
  .form-group .input-group input{
    height: 35px !important;
    font-size: 10px !important;
  }
  .investor-type-drop{padding: 10px 8px !important; font-size: 12px !important;}
  .dont-have-acc{
    margin-top: 8px !important;
    font-size: 12px;
   }
  .investee-login-signup{
    font-size: 12px !important;
  }
  .login-page{
    margin-top: -30px !important; 
  }
  .login-btn{width: 100px !important; line-height: 20px; font-size: 12px; padding: 0px; margin: 10px 0;}
  .investee-fp,.investor-FP,
  .request-access-btn,.already-acc{font-size: 12px !important;} */
}

/* Maximum resolution */
/* @media (max-resolution: 300dpi) {
  p {
    background: yellow;
  }
} */

.already-acc {
    font-size: 14px;
}

.escrow-account-bg {
    background: linear-gradient(167.94deg, #520e7c 0%, #225ed1 100%);
    box-sizing: border-box;
    /* border: 1px solid #f0f0f0; */
    border-radius: 10px;
    color: #ffffff;
    /* box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2); */
}

.nach-status-Success {
    border-radius: 4px;
    background-color: #e5fcde;
    text-align: center;
    color: #49a71b;
    padding: 1px 28px;
    padding-top: 3px;
    font-size: 11px;
    font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.cursor-pointer {
    cursor: pointer;
}

// Slider mark styles
.MuiSlider-mark {
    width: 1px !important;
    height: 10px !important;
    background-color: #a3a3a3 !important;
    bottom: -13px;
}

.MuiSlider-markLabel {
    top: 44px !important;
    color: #000000 !important;
    font-size: 10px !important;
    font-family: 'Gilroy-Medium' !important;
}
.MuiSlider-markLabel[data-index='1'] {
    top: -16px !important;
}

.MuiSlider-mark[data-index='1'] {
    bottom: 20px !important;
}

.custom_tooltip {
    padding: 10px !important;
    border-radius: 5px !important;
    background-color: #ffffff !important;
    box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.06) !important;
    font-family: 'Gilroy-Regular' !important;
    font-size: 12px !important;
    width: 250px;
    opacity: 1 !important;
}
.overview-tooltip {
    padding: 10px !important;
    border-radius: 5px !important;
    background-color: #ffffff !important;
    font-family: 'Gilroy-Regular' !important;
    font-size: 10px !important;
    width: 250px;
    opacity: 1 !important;
}
.custom_multiselect {
    .item-renderer {
        display: flex;
        align-items: center !important;
    }
}
.overviewcard {
    &:hover {
        span {
            .chevron-right {
                transition: transform 0.2s ease;
                transform: translateX(5px);
            }
        }
    }
}
.welcomeLoginTitle {
    padding-bottom: 1.5rem;
}
.indicativeOfferAmount {
    font-weight: 400;
    color: #00094b;
    font-size: 4rem;
}

.login-video-player {
    position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 1025px) {
    .sidebar {
        display: none;
    }
    .container-fluid {
        padding: 0;
        margin: 0;
    }
    .logged-in-wrapper {
        margin: auto;
        padding: 24px;
        max-width: 700px;
    }
    .page-head-bottom-border {
        display: none;
    }
    .welcomeLoginTitle {
        padding-bottom: 0;
    }
    .indicativeOfferAmount {
        font-weight: 400;
        color: #00094b;
        font-size: 2.5rem;
    }
}
/*bank account pennydrop verification status*/
@mixin BankVerfStatus {
    border-radius: 4px;
    font-weight: 500;
    padding: 5px 0;
    min-width: 100px;
    float: left;
    text-align: center;
    font-size: 10px;
    border: none;
    height: 24px;
}
.Payout-Account-bg {
    @include BankVerfStatus;
    background-color: #f2e8ff;
    color: #6021b3;
}
.VERIFIED-bg {
    @include BankVerfStatus;
    background-color: #e5fcde;
    color: #49a71b;
}
.Verify-Now-bg {
    @include BankVerfStatus;
    background-color: white;
    color: #2f8fff;
    border: 0.5px solid #2f8fff;
    cursor: pointer;
}

.newcarouselwrapper {
    height: 75%;
}
