.OuterContainer {
    position: absolute;
    top: 35px;
    padding: 10px;
    z-index: 1;
    left: 0px;
}
.InnerContainer {
    width: 160px;
    border-radius: 8px;
    padding: 10px 0px;
    background-color: #091436;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
}
.InvesteeSubLinks {
    padding: 10px;
    &:hover {
        background-color: #383f55;
    }
}
.InvestorSubLinks {
    padding: 10px;
    &:hover {
        background-color: #463960;
    }
}
