.BankStatementInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #fff7e9;
    color: #000;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 1rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    img {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
        margin-top: 0.2rem;
    }
    p {
        margin-bottom: 0;
    }
    span {
        font-family: Gilroy-SemiBold;
    }
}
