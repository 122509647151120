$breakpoints: (
    'xs': 320px,
    'sm': 481px,
    'md': 769px,
    'lg': 1025px,
    'xl': 1201px,
);

@mixin xs {
    @media (min-width: map-get($breakpoints, 'xs')) {
        @content;
    }
}

@mixin sm {
    @media (min-width: map-get($breakpoints, 'sm')) {
        @content;
    }
}

@mixin md {
    @media (min-width: map-get($breakpoints, 'md')) {
        @content;
    }
}

@mixin lg {
    @media (min-width: map-get($breakpoints, 'lg')) {
        @content;
    }
}

@mixin xl {
    @media (min-width: map-get($breakpoints, 'xl')) {
        @content;
    }
}

@mixin breakpoint($bp: 0) {
    @media (min-width: $bp) {
        @content;
    }
}
