@mixin btn($border: #ffffff, $bg-color: #ffffff) {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border: $base-border-thickness solid $border;
    border-radius: $base-border-radius * 2;
    background-color: $bg-color;
}

.btn {
    @include btn;
}
.btn-blue-border {
    @include btn(#2f8fff);
}
.btn-grey-border {
    @include btn(#999999);
    cursor: no-drop !important;
}
.btn-blue-bg {
    @include btn(#2f8fff, #2f8fff);
}

@each $key, $val in $colors {
    .btn-#{$key} {
        @include btn($val);
        &:hover {
            background-color: lighten($val, 5);
        }
    }
    .btn-outlined-#{$key} {
        @include btn(#fff);
        border: $base-border-thickness solid $val;
        &:hover {
            background-color: $val;
            color: #ffffff;
        }
    }
}
