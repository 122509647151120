.NewButton {
    border: 1px dashed rgb(130, 130, 130);
    border-radius: 4px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .Drag {
        font-weight: 700;
        font-size: 13.75px;
        line-height: 16px;
        color: #161513;
    }

    .Select {
        margin-top: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: rgba(22, 21, 19, 0.65);
    }

    &:hover {
        border: 1px dashed rgb(20, 20, 20);

        img {
            transform: scale(1.1);
        }
    }
}
.BGColor {
    background-color: #ffffff;
}
