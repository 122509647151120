.formInput {
    padding: 16px 12px;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    border: none;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #f9f9f9;
    .errorBorder {
        border-color: #ce121d !important;
    }
    &:focus,
    &:valid {
        color: #000000;
        border: 0.5px solid #4c8dff;
        background-color: #ffffff;
    }
    &:focus ~ label {
        top: -15px;
        left: 0;
        font-size: 10px;
        color: #8d8d8d;
        font-weight: 300;
    }
    &:valid ~ label {
        top: -15px;
        left: 0;
        font-size: 10px;
        color: #8d8d8d;
        font-weight: 300;
    }
    &:disabled {
        background-color: #f8fff8;
    }
}

select.formInput {
    width: 100%;
    font-size: 14px;
    color: #c3c3c3;
}

.formLabel {
    position: absolute;
    pointer-events: none;
    left: 10px;
    transition: 0.5s;
    top: 16px;
    font-size: 14px;
    font-weight: 300;
    color: #c3c3c3;
    transition: 0.5s;
    margin-bottom: 0px;
    .disabled {
        top: -15px;
        font-size: 12px;
        color: #414141;
    }
}
