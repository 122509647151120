@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url(./fonts/Gilroy-Font/GilroyFont/Gilroy-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Gilroy-Medium';
    src: local('Gilroy'), url(./fonts/Gilroy-Font/GilroyFont/Gilroy-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Gilroy-Light';
    src: local('Gilroy'), url(./fonts/Gilroy-Font/GilroyFont/Gilroy-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'Gilroy-Bold';
    src: local('Gilroy'), url(./fonts/Gilroy-Font/GilroyFont/Gilroy-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Gilroy-SemiBold';
    src: local('Gilroy'), url(./fonts/Gilroy-Font/GilroyFont/Gilroy-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: local('Gilroy'),
        url(./fonts/Gilroy-Font/GilroyFont/Gilroy-ExtraBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Backhand';
    font-style: normal;
    font-weight: 400;
    src: local('Backhand'),
        url('https://fonts.cdnfonts.com/s/77123/BackhandRegular-51RnB.woff') format('woff');
}
body {
    /* margin: 0;
    font-family: 'Gilroy-Medium';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
