.FormDiv {
    position: relative;

    .FormInput {
        padding: 10px;
        margin-bottom: 25px;
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
        outline: none;
        border: none;
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        border-radius: 6px;
        background-color: #f9f9f9;

        &:focus,
        &:valid {
            border: 0.5px solid #4c8dff;
            background-color: #ffffff;
        }

        &:focus ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }

        &:valid ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
        &:disabled ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
    }

    .FormLabel {
        position: absolute;
        top: 11px;
        pointer-events: none;
        left: 10px;
        font-size: 12px;
        font-weight: 300;
        color: #c3c3c3;
        transition: 0.5s;
    }

    .SelectDiv {
        border-radius: 6px;
        background-color: #f8fff8;
        padding: 10px;
        margin-bottom: 40px;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
    }

    .FormSelect {
        padding: 10px;
        margin-bottom: 25px;
        width: 100%;
        box-sizing: border-box;
        box-shadow: none;
        outline: none;
        border: none;
        font-weight: 300;
        color: #c3c3c3;
        background-color: #f9f9f9;
        font-size: 12px;
        border-radius: 6px;
        height: 38px;

        & ~ label {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }

        & * {
            color: #000000;
            background-color: '#FFFFFF';
        }
    }

    .FormSelectValue {
        border: 0.5px solid #4c8dff;
        font-weight: 500;
        color: #000000;
        background-color: #ffffff;
    }
}

.OdfdContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}
.Odfd {
    color: #14143c;
    font-size: 12px;
    font-family: 'Gilroy-Medium';
    line-height: 1.5rem;
    margin: 1.5%;
    align-items: center;
    display: flex;
    font-weight: 600;
}

.OdfdDisabled {
    color: rgba(22, 21, 19, 0.4);
    font-size: 12px;
    font-family: 'Gilroy-Medium';
    line-height: 17px;
    margin: 1.5%;
    align-items: center;
    display: flex;
    font-weight: 600;
}

.OdOrFdContainer {
    margin: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    padding: 24px;
    border-radius: 5px;
}

.DocumentText {
    color: #14143c;
    font-size: 12px;
    font-family: 'Gilroy-Semibold';
    line-height: 20px;
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
}

.Blue {
    color: #4c8dff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
