@use 'sass:math';

$grid-columns: 12;
$grid-gaps: (
    '0': 0,
    '1': 10px,
    '2': 20px,
    '3': 30px,
);
$layout-values: flex-start, flex-end, center, space-between, space-around;
$align-item: stretch, center, flex-start, flex-end, baseline, initial, inherit;
$flex-wrap: nowrap, wrap, wrap-reverse, initial, inherit;

// base layout classes
.container {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}
.row {
    display: flex;
    flex-flow: row wrap;
}

// grid gaps
@each $key, $val in $grid-gaps {
    .gap-#{$key} > * {
        padding: $val;
    }
    .gap-#{$key} {
        margin-left: -$val;
        margin-right: -$val;
    }
}

// justify content classes
@each $val in $layout-values {
    .justify-#{$val} {
        justify-content: $val;
    }
}

// align item classes
@each $val in $align-item {
    .align-item-#{$val} {
        align-items: $val;
    }
}

// flex wrap classes
@each $val in $flex-wrap {
    .flex-wrap-#{$val} {
        flex-wrap: $val;
    }
}

// col classes
@include xs {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-xs {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}
@include sm {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-sm {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}
@include md {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-md {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}
@include lg {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-lg {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}
@include xl {
    @for $i from 1 through $grid-columns {
        .col-#{$i}-xl {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}
