@mixin SelectMember {
    border: none;
    background-color: white !important;
    font-family: 'Gilroy-SemiBold';
    flex-basis: 47.5%;
    font-size: 12px;
    width: 100%;
    border-radius: 6px;
    height: 44px;
    margin-bottom: 25px;
    padding-left: 10px;
    color: #adadad;
}
.TeamInvite {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    background-color: #4c8dff;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    padding: 0px 1.5rem 1.5rem 1.5rem;
}

.InputFilled {
    border: 1.5px solid #4c8dff !important;
    height: 44px;
    padding-left: 10px;
    font-family: 'Gilroy-SemiBold';
    border-radius: 6px;
    outline: none !important;
    font-size: 12px;
    width: 100%;
}

.InputEmpty {
    background: #f9f9f9;
    border: 0px;
    font-size: 12px;
    height: 44px;
    padding-left: 15px;
    font-family: 'Gilroy-SemiBold';
    border-radius: 6px;
    margin-top: 12px;
    margin-bottom: 15px;
    outline: none !important;
    width: 100%;
}

.SendInvite {
    background: #2f8fff;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    border: 0px;
    color: #ffffff;
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
}

.SelectMemberRoleSelected {
    @include SelectMember;
}

.SelectMemberRoleEmpty {
    @include SelectMember;
    margin-top: 12px;
}

.Label {
    font-size: 8px;
    color: #8d8d8d;
}
