.Error-Modal {
    padding: 10px;
    margin: auto;
    .modal-dialog {
        margin: auto !important;
    }
    .modal-content {
        width: 708px;
        height: 304px;
        margin: auto -100px;
        padding: 30px;
        box-shadow: none;
    }
    .modal-body,
    .modal-header {
        padding: 0px;
    }
    .modal-header {
        button {
            position: absolute;
            right: 0.8rem;
            top: 0.4rem;
        }
    }
    .modal-body {
        display: flex;
        align-items: center;
        .LeftImg {
            margin-right: 50px;
        }
        .RightText {
            div {
                color: #23339b;
            }
            .FirstDiv {
                font-family: 'Gilroy-Bold';
                font-size: 34px;
            }
            .SecondDiv {
                font-family: 'Gilroy-Medium';
                line-height: 18px;
                margin: 12px 0;
                font-size: 15px;
            }
            button {
                margin-top: 10px;
                img {
                    margin-right: 8px;
                }
                border: none;
                color: white;
                font-family: 'Gilroy-SemiBold';
                font-size: 10px;
                padding: 6.5px 15px;
                background: #23339b;
                border-radius: 4px;
            }
        }
    }
}

@media only screen and (max-width: 1025px) {
    .Error-Modal {
        .modal-content {
            margin: auto 0px !important;
            width: 450px !important;
            height: 304px !important;
            padding: 10px !important;
            box-shadow: none !important;
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            .LeftImg {
                width: 150px;
                margin-right: 0px;
            }
            .RightText {
                display: flex;
                flex-direction: column;
                align-items: center;
                .FirstDiv {
                    font-family: 'Gilroy-Bold';
                    font-size: 24px;
                    text-align: center;
                }
                .SecondDiv {
                    font-family: 'Gilroy-Medium';
                    line-height: 18px;
                    margin: 12px 0;
                    font-size: 15px;
                    text-align: center;
                }
            }
        }
    }
}
