.Content {
    .Title {
        font-family: 'Gilroy-Semibold', sans-serif;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Subtext {
        margin: 10px 0;
        font-size: 12px;
    }

    .Options {
        margin: 20px 0 30px 0;
        display: flex;
        align-items: center;

        .Option {
            margin: 0 20px 0 0;
            width: 20%;
            max-width: 100px;
            height: 55px;
            border: 1px solid #ebebeb;
            border-radius: 4px;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                border: 1px solid rgb(137, 137, 137);
            }

            .uploadManually {
                color: #000000;
                font-family: 'Gilroy-SemiBold', sans-serif;
                margin-left: 5px;

                .upload {
                    margin-top: 2px;
                    font-size: 10px;
                    line-height: 9px;
                }
            }
        }

        .Integrated {
            position: relative;
            overflow: visible;
            border: 1px solid #4aac4f;
            background-color: #14c51d15;

            &::after {
                content: '';
                height: 10px;
                width: 10px;
                position: absolute;
                right: -2px;
                top: -2px;
                background-image: url(../../../../../../assets/UploadDataJourney/green-tick.svg);
                background-position: center;
                background-size: cover;
            }
        }

        .Selected {
            border: 1px solid rgb(74, 74, 74);
        }

        .Disabled {
            pointer-events: none;
            opacity: 0.2;
            border: 1px solid rgb(137, 137, 137);
        }

        .Unselected {
            opacity: 0.2;
        }
    }

    .Loading {
        margin: 14px 0;
        text-align: center;
        height: 80px;
        padding: 15px 0;
        color: #939393;

        .Text {
            font-size: 11px;
            margin-top: 10px;
        }
    }
}
// .ReviewCard {
//   margin-top: 0;
//   padding: 10px 0 0 0;
//   box-shadow: none;
// }

.commentSmallMonthlyView {
    padding-bottom: 40px;
}

.Next {
    animation: slideLeft 600ms ease-in-out;
}

.Previous {
    animation: slideRight 600ms ease-in-out;
}

@keyframes slideLeft {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }

    40% {
        opacity: 0;
        transform: translateX(-20px);
    }

    60% {
        opacity: 0;
        transform: translateX(20px);
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 0;
        transform: translateX(20px);
    }

    60% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
