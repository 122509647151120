.balanceBox {
    color: #ffffff;
    width: 90%;
    margin: auto;
    padding: 15px;
    background-color: #483a81;
    font-family: gilroy-semibold, sans-serif;
    position: absolute;
    bottom: 73px;
    left: 0;
    right: 0;
    border-radius: 8px;
    .withdraw {
        color: rgb(40, 20, 89);
        padding: 3px 15px 3px 15px;
        border: none;
        border-radius: 4px;
    }
    .details {
        border: none;
        color: #ffffff;
    }
}
