.searchBoxContainer {
    position: relative;
    display: inline-block;
    background-color: #efefef;
    border-radius: 5px;

    span {
        margin-left: 10px;
        margin-right: 4px;
        margin-top: -2px;
    }
    input {
        width: 150px;
        height: 35px;
        padding: 8px;
        transition: width 0.3s ease-in-out;
        background-color: #efefef;
        border-radius: 5px;
        border: none;

        &:focus {
            background-color: #fff;
            width: 300px;
        }
    }

    &:focus {
        background-color: #fff;
        width: 300px;
    }

    &.focused {
        background-color: #fff;
        border: 1px solid #2f8fff;
    }

    .dropdownContainer {
        margin-top: 2px;
        position: absolute;
        left: 0;
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
        padding: 8px;
        z-index: 2;

        .searchResultsContainer {
            overflow: auto;
            max-height: 215px;
        }
        .searchResultContainer {
            padding: 6px 8px;
            cursor: pointer;

            img {
                margin-bottom: 2px;
            }
            &:hover {
                background-color: #eaf4ff;
            }
        }
    }
    hr {
        margin-top: 8px;
        margin-bottom: 8px;
        background-color: #dbe2eb;
    }
}
