.HeadingText {
    color: #233299;
    font-size: 28px;
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    font-family: 'Gilroy-Bold';
}
.HeadingTextNormal {
    color: #233299;
    font-size: 28px;
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-family: 'Gilroy-Bold';
}

.CloseBtn {
    background: transparent;
    border: none;
    margin-right: 1rem;
}
.TdsBanner {
    margin-left: 1rem;
    padding: 0.5rem;
    border-radius: 8px;
    font-size: 12px;
}
