.DisabledUploadCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    color: #bcbcbc;
    & > div {
        display: flex;
        align-items: center;
    }
}
