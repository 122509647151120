.SmallMonthlyView {
    padding: 17px 20px;
    margin: 0 auto 30px auto;
    background: #f9f9f9;
    border-radius: 0 0 8px 8px;
    max-height: 50px;
    overflow: hidden;
    transition: all 300ms ease-in-out;

    .ViewStatus {
        visibility: hidden;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        color: #2f8fff;
        padding: 5px 15px;
        height: 20px;
        overflow: visible;
        margin-top: 10px;

        display: flex;
        justify-content: center;

        .Button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .HideStatus {
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        color: #2f8fff;
        padding: 15px 15px 0 15px;
        display: flex;
        justify-content: center;

        .Button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .Top {
        font-size: 10px;
        line-height: 16px;
        padding: 0px 15px 0px 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .TopLeft {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            img {
                margin-right: 5px;
                margin-bottom: 1px;
            }

            .Bold {
                font-weight: 600;
            }

            .Row {
                display: flex;
                align-items: center;
                width: 100%;
                .SingleSummaryContainer {
                    display: flex;
                    align-items: center;
                }

                .SingleSummary {
                    margin-right: 40px;
                    display: flex;
                    align-items: center;
                    animation: fadeIn 100ms ease-in-out;
                    white-space: nowrap;
                }
            }
        }

        .TopRight {
            color: #818181;
            display: flex;
            align-items: center;

            & > img {
                margin-left: 10px;
                margin-right: 5px;
            }

            & > div {
                white-space: nowrap;
            }
        }
    }
}

.Hover {
    background: #f1f6ff;
    max-height: 70px;

    .ViewStatus {
        visibility: visible;
        animation: fadeIn 150ms linear;
    }

    .TableContainer {
        visibility: hidden;
    }
}

.Expand {
    max-height: 250px;
    margin-bottom: 30px;

    .ViewStatus {
        visibility: hidden;
    }

    .TableContainer {
        visibility: visible;
        display: block;
        // border: 1px solid red;
        // margin-top: 35px;
    }
}

.SmallMonthlyViewLowPad {
    padding: 17px 5px;
}
.SingleSummary {
    display: flex;
    align-items: center;
}

.TableContainer {
    transition: margin-top 10ms linear;

    .Table {
        margin: 0 auto;

        .FirstColumn {
            min-width: 30px;
        }

        .MonthName {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #bcbcbc;
            padding: 5px 11px;
        }

        .Year {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
            text-align: center;
            padding-left: 0 !important;
        }

        .Item {
            align-items: center;

            .Img {
                padding: 12px 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .TableReviewScreen {
        .FirstColumn {
            min-width: 30px;
        }

        .MonthName {
            padding: 5px 10px;
        }

        .Item {
            align-items: center;

            .Img {
                padding: 11px 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.FadeIn {
    animation: fadeIn 500ms linear;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
