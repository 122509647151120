.countryCodeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding-right: 3%;
    text-align: center;
    height: 44px;
    width: 40%;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    color: #000000;
    border-radius: 6px;
    border: 0.5px solid #cecece;
    background: url('../../../../../public/assets/select-dropdown.svg') no-repeat;
    background-size: 10px 12px;
    background-position-x: 95% !important;
    background-position-y: 50% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus,
    &:active,
    &:focus-visible {
        border: 0.5px solid #2f8fff;
    }
    &:disabled {
        background: transparent;
        background-color: #f9f9f9;
        border: 0.5px solid #cecece;
        color: #c3c3c3;
    }
}
.modalCountryCodebox {
    @extend .countryCodeBox;
    height: 40px;
    background-position-x: 65%;
}
