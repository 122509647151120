.modal-dialog {
    margin: 0 auto;
    width: 600px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.000319489px;
    color: #000000;
}

.m-foot {
    padding: 0 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.m-head {
    padding: 20px 20px 0 20px;
}

.m-body {
    font-weight: 600;
}

.Close-btn {
    border: 1px solid #dc3545;
    color: #dc3545;
    border-radius: 4px;
    margin-right: 20px;
    font-size: 10px;
    line-height: 12px;
    padding: 10px 25px;
    cursor: pointer;
    width: fit-content;
}

.Proceed-btn {
    border: 1px solid #4c8dff;
    background-color: #4c8dff;
    color: white;
    border-radius: 4px;
    font-size: 10px;
    line-height: 12px;
    padding: 10px 25px;
    cursor: pointer;
    width: fit-content;
}
