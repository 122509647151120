@each $key, $val in $colors {
    .text-#{$key} {
        color: $val;
    }
    .text-hover-#{$key} {
        &:hover {
            color: $val;
        }
    }
    .bg-#{$key} {
        background-color: $val;
    }
    .bg-hover-#{$key} {
        &:hover {
            background-color: $val;
        }
    }
}
