.storybook-button {
    font-family: 'Gilroy-Medium';
    font-weight: normal;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
}
.storybook-button--primary {
    color: white;
    background-color: #4c8dff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.storybook-button--secondary {
    border: solid 0.5px #4c8dff;
    background-color: #fff;
    color: #4c8dff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.storybook-button--ternary {
    border: none;
    background-color: #fff;
    color: #4c8dff;
    display: flex;
    align-items: center;
}
.mr5 {
    margin-right: 5px;
}
.ml5 {
    margin-left: 5px;
}
.storybook-button--small {
    font-size: 14px;
    padding: 10px 16px;
}
.storybook-button--medium {
    font-size: 16px;
    padding: 11px 20px;
}
.storybook-button--large {
    font-size: 18px;
    padding: 12px 24px;
}
