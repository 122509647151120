@mixin Status {
    height: 18px;
    dominant-baseline: middle;
    border-radius: 8px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin HoverContainer {
    height: 35px;
    padding: 12px 10px;
    font-family: 'Gilroy';
    font-weight: 600;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#MainContainer {
    width: 160px;
    height: 70px;
    padding: 14px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .LabelWithStatus {
        width: 86px;
        .Label {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            margin-bottom: 5px;
        }

        .StartNow,
        .AcceptTerms,
        .SignSanction {
            @include Status;
            background: #f1f8ff;
            color: #2f8fff;
        }
        .UploadData,
        .NeedDataInfo,
        .UploadKyc,
        .SetupCollections {
            @include Status;
            background: #fef7ea;
            color: #cca224;
        }
        .UnderReview,
        .BiddingOngoing {
            @include Status;
            background: #f4f4f4;
            color: #979797;
        }
        .Approved {
            @include Status;
            background: #f1fff8;
            color: #00aa00;
        }
        .NoBids {
            @include Status;
            background: #fff2e9;
            color: #ff6600;
        }
    }
    .ProgressContainer {
        width: 42px;
        height: 42px;
        position: relative;
        text {
            font-size: 22px;
        }
    }
}
.MainContainerSelected {
    background: #f1f8ff !important;
    border: 1px solid #2f8fff;
}
.HoveredWidgetContainer {
    width: 160px;
    height: 70px;
    .DataContainer {
        @include HoverContainer;
        border-radius: 8px 8px 0px 0px;
    }
    .KycContainer {
        @include HoverContainer;
        border-radius: 0px 0px 8px 8px;
    }
    .ImageContainer {
        display: flex;
        .InProcess {
            font-style: italic;
        }
    }
    .OrangeColor {
        color: #ff6600;
    }
    .GreenColor {
        color: #00aa00;
    }
    .BlueColor {
        color: #2f8fff;
    }
    .GreyColor {
        color: #b6b6b6;
    }
    .BlackColor {
        color: #000000;
    }
    .WhiteBG {
        background: #ffffff;
    }
    .BlueBG {
        background: #f1f8ff;
    }
    .GreyBG {
        background: #f2f2f2;
    }

    .HorizontalLine {
        height: 0;
        border: 1px solid #e3e3e3;
    }
}
.CursorDefault {
    cursor: default;
}
