/* Manage Profile Starts */

/* Manage Profile Ends */

/* Tab Css */
.nav-tabs .nav-link {
    font-size: 14px;
    border: none;
    padding: 0px;
    margin-right: 115px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    font-weight: 500;
}
.nav-link.active,
.profile-tabs a:hover {
    color: #000000 !important;
    font-weight: 500;
    text-decoration: none;
}
.nav-link {
    color: lightgray;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 17px;
}
.nav-tabs {
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    padding: 14px 28px;
    font-family: 'Gilroy-Bold';
}
/* Tab Css Ends*/

/* Account css */
.account {
    margin: 0px 20px;
}
.account-btn {
    margin: 0px;
    justify-content: space-between;
    padding-bottom: 80px;
    max-width: 340px;
    min-width: 340px;
}
.account label {
    opacity: 0.37;
    color: #222e43;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 10px;
}
.user-profile-email {
    background-color: #eeeeee;
}
.account input {
    height: 54px;
    width: 340px;
    max-width: 400px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    color: #222e43;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    padding: 10px 20px;
    margin-bottom: 25px;
    outline: none;
    font-weight: 400;
    opacity: 0.7;
}
.account-form {
    margin-right: 35px;
    margin-left: -6px;
    /* margin-top: 30px; */
    /* max-width: 400px; */
}
.acc-form-max-width {
    max-width: 400px;
}
.user-name {
    background-color: #eeeeee;
    opacity: 0.44;
    color: #222e43;
}
.save-btn {
    height: 40px;
    width: 145px !important;
    border-radius: 6px;
    background-color: #4c8dff;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
.save-btn:hover,
.team-invite .send-invite:hover {
    background-color: #002b78;
}
.investor-save-btn {
    height: 40px;
    width: 158px;
    border-radius: 6px;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
.reset-btn {
    box-sizing: border-box;
    height: 40px;
    width: 145px !important;
    border: 1px solid #000000;
    border-radius: 6px;
    background: transparent;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
/* Account css Ends */

/* Company Profile Css Starts */

.border10px {
    border-radius: 10px;
    background-color: #f9f9f9;
}
.company-border2 {
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}
.company-logo {
    height: 47px;
    width: 47px;
    margin: 0px 18px 0px 9px;
    border-radius: 6px;
    background-color: #ffffff;
}
.company-profile-row1 {
    height: 64px;
}
.company-profile-name {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 0px;
}
.company-last-update {
    opacity: 0.51;
    color: #545454;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: left;
    margin-bottom: 0px;
}
.need-update-CP {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    margin-right: 35px;
    cursor: pointer;
}
.need-update-CP span {
    color: #4c8dff;
}
.company-arr-graph {
    height: 200px;
    padding: 20px;
}
.company-arr-graph h3 {
    color: #323232;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}
.graph {
    height: 120px;
}
.graph-waterfall {
    height: 150px;
}
.arr-detail p {
    color: #717070;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
}
.arr-incr {
    height: 11.27px;
    width: 11.5px;
    border: 1px solid #d6d6d6;
    border-radius: 1px;
    background-color: #e4e4e4;
    margin-right: 7px;
}
.founded {
    color: #9d9d9d;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 0px;
}
.founded-subtext {
    color: #1c1c1c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    margin: 6px 0px 26px;
}
.CP-overview {
    font-weight: 300;
    color: #171717;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 6px 0px 0px;
    text-align: justify;
}
.ques-icon {
    height: 12px;
    width: 12px;
    margin-left: 10px;
}
.add-doc p {
    color: #348cff;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 0px;
    margin-top: 20px;
}
.CP-metrics {
    opacity: 0.27;
    color: #000000;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 4px 0px 4px 5px;
    background-color: #d8d8d8;
    height: 22px;
}
.metrics-LT {
    /* opacity: 0.7; */
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 300;
    padding: 10px 0px 10px 32px;
}
.metrics-LT span {
    opacity: unset;
}
.metrics-RT {
    /* opacity: 0.7; */
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    padding: 7px 0px 7px 15px;
}
.metrics-row {
    border-top: 1px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9;
}
/* Company Profile Css Ends */

/* Team Css */
.team-table {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0px;
    min-width: max-content;
}
.team-invite {
    margin-top: 30px;
}
.invite-svg:hover {
    fill: #ffffff;
}
.team-invite button,
.btn-info,
.btn-info:hover,
.btn-info:focus {
    height: 40px;
    width: 119px;
    border: 1px solid #000000;
    border-radius: 6px;
    background: transparent;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    box-shadow: none;
}
.MP-team td {
    opacity: 0.7;
}
.modal-height {
    height: 365px;
    width: 540px;
}

.modal-header {
    border-bottom: none;
    padding: 1.5rem;
}
.modal-header .close {
    padding: 0px;
    margin: 0px;
    border: none;
    width: 14px;
    opacity: 0.46;
    font-size: 25px;
}
.modal-body {
    margin: auto;
    width: 100%;
    padding: 0px 1.5rem 1.5rem 1.5rem;
}
.modal-body input {
    width: 100%;
    margin-bottom: 15px;
    max-width: 100%;
}
.modal-body input:valid,
.modal-body input:focus,
.modal-body select:valid,
.modal-body select:focus {
    border: 1.5px solid rgb(76, 141, 255);
    background-color: rgb(255, 255, 255);
    box-shadow: none;
    color: black;
    outline: none;
}
.modal-body select {
    float: left;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: url('../assets/select-dropdown.svg');
    background-color: #f9f9f9;
    background-repeat: no-repeat;
    background-size: 13px 17px;
    background-position-x: 97.5%;
    background-position-y: 50%;
}

.team-invite .send-invite {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    background-color: #4c8dff;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}
.team-invite-investor .send-invite,
.team-invite .investor-team-send-invite {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    background-color: #000000;
    border: transparent;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}
.team-invite-investor .send-invite:hover,
.team-invite .investor-team-send-invite:hover {
    /* background-color: #39057B; */
    background-color: #7741f2;
}
.investor-send-invite {
    height: 50px;
    width: 280px;
    border-radius: 6px;
    background-color: #000000;
    border: transparent;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-left: 25%;
    margin-right: 25%;
}
/* Team Css Ends */
/* Bank Accounts CSS */
.br-4 {
    border-radius: 4px;
}
.bank-accounts-container {
    max-width: 1160px;
    padding: 30px;
}
.add-button {
    margin-left: 12px;
    padding: 5px;
    border: 0.5px solid black;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.plus-dark-icon {
    height: 10px;
    width: 10px;
}
.button-content {
    font-size: 10px;
    line-height: 14px;
    margin-left: 5px;
}
.tick-img {
    width: 15px;
    height: 15px;
    align-self: center;
}
.second-row {
    margin-top: 80px;
}
.user-details-container {
    font-size: 10px;
}
.modal-form {
    padding: 12px;
}
.two-fields-in-one {
    flex-basis: 47.5%;
}
.encryption {
    color: #adadad;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
}
.icon-arrow-forward {
    height: 15px;
    width: 15px;
}
.lock-icon {
    width: 10px;
    height: 13px;
    margin-bottom: 4px;
}
::placeholder {
    color: #adadad;
}
/* Bank Accounts CSS Ends */
@media only screen and (max-width: 580px) {
    .account-form {
        margin-right: 0px;
    }
    .account {
        margin: 0px;
    }
    .team-body tr td {
        font-size: 11px;
    }
    .table td,
    .table thead th {
        padding: 20px 0px;
    }
    .modal-height {
        width: 100%;
    }
    .team-invite .send-invite {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .save-btn,
    .reset-btn {
        width: 126px;
    }
    .nav-tabs .nav-link {
        padding: 10px 0px;
        margin-right: 0px;
    }
    .nav {
        display: block;
    }
}
@media only screen and (max-width: 820px) {
    .nav-tabs .nav-link {
        margin-right: 57px;
    }
    .account-form {
        margin-right: 0px;
    }
    .account-btn {
        justify-content: space-evenly;
    }
    .save-btn,
    .reset-btn {
        width: 120px;
    }
}
@media only screen and (max-width: 990px) {
    .account-form {
        margin-right: 0px;
    }
}

.account-detail-copy {
    color: #000000;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    font-weight: 300;
    /* flex-basis: 66%; */
}

.pay-via-bank-title {
    color: #0f0b0b;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    font-weight: 600;
    /* flex-basis: 25%; */
}

.pay-via-bank-value {
    color: #000000;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    font-weight: 300;
    max-width: 300px;
    /* flex-basis: 66%; */
}
