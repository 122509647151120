.Tally {
    .Top {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #2f8fff;
        display: flex;
        align-items: center;
        cursor: pointer;

        & > img {
            margin-bottom: 2px;
            cursor: pointer;
            transition: all 50ms ease-in-out;

            &:hover {
                transform: translateX(-2px);
            }
        }
    }

    .Title {
        margin-top: 25px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
    }

    .UploadText {
        margin: 14px 0 20px 0;
        font-size: 12px;

        .Blue {
            color: #4c8dff;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .BottomText {
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
}
