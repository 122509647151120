// body {
//     background-color: #ffffff;
// }

.landscape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 22.75rem;
    height: 16.375rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: #f9f9f9;
    padding: 2rem;
    margin: auto;
    .landscapeImg {
        width: 9rem;
    }
}
