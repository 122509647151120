.ItemStyle {
    padding: 0 5%;
    white-space: nowrap;
    a {
        text-decoration: none;
        color: #5e708b;
        cursor: pointer;
    }
}

.Dot {
    border: 2px solid #5e708b;
    border-radius: 50%;
    width: 2px;
    height: 2px;
}

.TermsOfService {
    font-size: 8px;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-wrap: nowrap;
}

.SidebarUsername {
    min-height: 44px;
    border-radius: 8px;
    margin: 0px 10px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.Sublist {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 13%);
    color: #ffffff;
    font-weight: 200;
    max-height: 178px;
    margin: 0 10px;
    padding: 14px 0;
    a {
        margin: 0px !important;
        padding: 5% 15px !important;
        &:hover {
            margin: 0px !important;
            padding: 5% 15px !important;
            background-color: #383f55;
        }
    }
}
.HiddenSidebar {
    position: absolute;
    bottom: 10px;
    width: 100%;
    z-index: 2;
}
.InvesteeLogout {
    padding: 5% 15px !important;
    margin: 0px !important;
    cursor: pointer;
    font-weight: 300;
    &:hover {
        background-color: #383f55;
    }
}
.NameInitials {
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    height: 23px;
    width: 23px;
    border: 1px solid #ffffff;
    text-align: center;
    padding: 3px 0;
    margin: 0 10px;
    border-radius: 50%;
    position: relative;
    img {
        position: absolute;
        top: -16px;
        left: 2.5px;
    }
}
.UserFirstName {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    max-width: 115px;
    word-break: break-all;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.UserCompany {
    color: #c0c0c0;
    font-size: 8px;
    font-weight: 300;
    max-width: 115px;
    word-break: break-all;
}

.complianceInfoIcon {
    display: flex;
    align-items: center;
    gap: 0.39rem;
    img {
        width: 1rem;
        height: 1rem;
    }
}
