.TabContainer {
    border-radius: 8px;
    font-family: 'Gilroy-Bold';
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 35px;
    margin-top: 18px;
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        list-style: none;
        border: none;
        padding: 0px;
        li {
            margin-right: 65px;
            a {
                width: max-content;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 20px;
                padding: 10px 8px;
                text-align: left;
                display: flex;
                align-items: center;
                margin-right: 0px;
                text-decoration: none;
                transition: 0.2s ease-out;
            }
        }
    }
}
.InvesteeTabLinks {
    color: #c5c5c5;
    &:hover {
        color: #2f8fff;
    }
}
.InvestorTabLinks {
    color: #c5c5c5;
    &:hover {
        color: #521b97;
    }
}
.InvesteeTabLinksActive {
    border-bottom: 3px solid #2f8fff;
    color: #2f8fff;
    &:hover {
        color: #2f8fff !important;
        font-size: 16px !important;
    }
}
.InvestorTabLinksActive {
    border-bottom: 3px solid #521b97;
    color: #521b97;
    &:hover {
        color: #521b97 !important;
        font-size: 16px !important;
    }
}
