.investor-sidebar {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    padding: 30px 0px 0px 0px;
    min-height: 100vh;
    z-index: 3;
    min-width: 181px;
    position: fixed;
    height: 100%;
    overflow: visible;
    // border-top-right-radius: 55px;
    background: #30017e;
}

.investor-sidebar-link {
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: white;
    }
}
