.DirDetHeading {
    font-size: 16px;
    font-family: gilroy-semibold, sans-serif;
    white-space: nowrap;
}
.DirDetSubHeading {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    line-height: 1rem;
    gap: 0.75rem;
    font-family: gilroy-medium, sans-serif;
    margin-bottom: 1.5rem;
    padding: 0.87rem;
    border-radius: 0.3125rem;
    background: #f1f6ff;
    margin-top: 1.5rem;
}
.FormContainer {
    .Field {
        position: relative;
        margin: 2.12rem 0;

        .FormInput {
            padding: 16px 12px;
            width: 100%;
            box-sizing: border-box;
            box-shadow: none;
            outline: none;
            border: none;
            color: #000000;
            font-size: 12px;
            font-weight: 500;
            border-radius: 6px;
            background-color: #f9f9f9;
            border: 0.5px solid transparent;
            z-index: 1;

            // border-color: ${(props) => (props.errorBorder ? '#CE121D !important' : 'none')};
            &:focus,
            &:valid {
                border: 0.5px solid #4c8dff;
                background-color: #ffffff;
            }

            &:focus ~ label {
                top: -15px;
                left: 0;
                font-size: 10px;
                color: #8d8d8d;
                font-weight: 300;
            }

            &:valid ~ label {
                top: -15px;
                left: 0;
                font-size: 10px;
                color: #8d8d8d;
                font-weight: 300;
            }

            &:-webkit-autofill {
                box-shadow: 0 0 0 30px white inset;
                -webkit-box-shadow: 0 0 0 30px white inset;
            }

            &:disabled {
                background-color: #f8fff8 !important;
                border: 0.5px solid white;
                box-shadow: 0 0 0 30px #f8fff8 inset;
            }
        }
        .FormLabel {
            position: absolute;
            pointer-events: none;
            left: 10px;
            transition: 0.5s;
            top: 16px;
            font-size: 12px;
            color: #c3c3c3;
            font-weight: 300;
            transition: 0.5s;
            margin-bottom: 0px;
        }

        .LabelTop {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
    }
    .LabelTop {
        top: -15px;
        left: 0;
        font-size: 10px;
        color: #8d8d8d;
        font-weight: 300;
    }
}
.AddDirText {
    font-size: 12px;
    font-family: gilroy-semibold, sans-serif;
    white-space: nowrap;
}
.AddDirCta {
    font-size: 12px;
    font-family: gilroy-semibold, sans-serif;
    white-space: nowrap;
    color: #4c8dff;
    cursor: pointer;
}
.NewDirRemove {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 1rem;
}
.Banner {
    position: absolute;
    right: 22px;
    top: 16px;
    font-size: 12px;
}
.DisabledBannerText {
    @extend .Banner;
    color: #8d8d8d;
    font-size: 12px;
    font-family: gilroy-semibold, sans-serif;
    white-space: nowrap;
    cursor: no-drop;
    pointer-events: none;
}
.BannerTextVerified {
    @extend .Banner;
    color: #4cb71a;
    font-size: 12px;
    font-family: gilroy-semibold, sans-serif;
    white-space: nowrap;
    cursor: no-drop;
    pointer-events: none;
}
.BannerText {
    @extend .Banner;
    font-size: 12px;
    font-family: gilroy-semibold, sans-serif;
    white-space: nowrap;
    color: #4c8dff;
    cursor: pointer;
}
.LastDateText {
    font-size: 8px;
    margin-right: 0.3rem;
    font-family: gilroy-semibold, sans-serif;
    white-space: nowrap;
    color: #8d8d8d;
    cursor: default;
}

.VerifyButton {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 500;
}

.VerifyEnabled {
    color: #ffffff;
    background-color: #4c8dff;
    cursor: pointer;
}
