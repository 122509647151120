.UploadJourney {
    display: block;
    height: calc(100vh - 80px);
}
.UploadJourneyFooter {
    height: 78px;
    border-top: 1px solid #dcdcdc;
    background: white;
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    align-items: center;
}
.videoContainer {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 8.8px 0px rgba(0, 0, 0, 0.1);

    .videoLabel {
        padding: 12px 20px;
        display: flex;
        gap: 15px;
        align-items: center;
        p {
            font-family: Gilroy-Bold, san-serif;
            font-size: 12px;
            line-height: 17px;
            color: #000000;
            margin: auto;
        }
    }
}

.UploadJourneyCentered {
    display: flex;
    justify-content: center;
    transform: translateX(-100px);
}

.StepContainer {
    position: relative;

    .Title {
        color: rgb(222, 222, 222);
        font-size: 26px;
        font-family: Gilroy-Bold;

        margin-bottom: 30px;
    }

    .Card {
        // width: 565px;
        margin-top: 30px;
        padding: 40px;
        border-radius: 8px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(112, 144, 176, 0.16) 0px 1px 10px 0px;
    }
    .ReviewCard {
        margin-top: 0;
        padding: 10px 0 20px 0;
        box-shadow: none;
    }

    .BackNextButtons {
        position: absolute;
        top: 68px;
        left: 100%;
        height: calc(100% - 68px);

        align-self: stretch;
        max-height: 700px;
        width: 200px;
        padding: 0 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: 'Gilroy-SemiBold', sans-serif;
        font-size: 14px;
        line-height: 14px;

        .Back {
            color: #2f8fff;
            cursor: pointer;
        }

        .BackDisabled {
            color: #b4b4b4;
            pointer-events: none;
        }

        .ProgressSaved {
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 10px;
            line-height: 12px;
            color: #a8a8a8;
            margin: 10px 0;
            font-style: italic;
        }

        .Next {
            padding: 10px 20px 8px 20px;
            color: white;
            background-color: #2f8fff;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
        }

        .NextDisabled {
            background-color: #d9d9d9;
            color: white;
            pointer-events: none;
        }
    }
}

.ReviewStep {
    .Title {
        display: none;
    }

    .Card {
        padding: 0;
        // border-radius: 8x;
        background-color: rgb(255, 255, 255);
        box-shadow: none;
    }
}
