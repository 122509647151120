@mixin BeforeValue {
    border-radius: 6px;
    border: 1px solid #ffffff !important;
    background-color: #f9f9f9;
    color: #adadad;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    padding: 10px;
}
@mixin AfterValue {
    border: 1px solid #2f8fff !important;
    background-color: white;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    outline: none;
}
@mixin LabelDesign {
    font-family: 'Gilroy-Medium';
    font-size: 8px;
    height: 13px;
    width: 100%;
    margin-bottom: 0px;
}
@mixin Btn {
    height: 44px;
    width: 100%;
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
    border-radius: 6px;
}
@mixin ModalInput {
    padding: 10px;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    color: #adadad;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    border-radius: 6px;
    border: 0.5px solid #f9f9f9;
    background-color: #f9f9f9;
    &:focus,
    &:valid {
        color: #000000;
        font-family: 'Gilroy-SemiBold';
        border: 0.5px solid #4c8dff;
        background-color: #ffffff;
    }
}
@mixin ModalLabel {
    position: absolute;
    color: #adadad;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    pointer-events: none;
    left: 35px;
    transition: 0.5s;
}
.ListModal,
.WithdrawModal {
    .modal-content {
        width: 330px;
        margin: auto;
    }
}
.DocModal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 93vw;
        height: 90vh;
    }
    .modal-content {
        width: 100%;
        height: 100%;
    }
    .modal-header {
        display: flex;
        align-items: center;
    }
}
.docViewerBtn {
    background-color: #fff;
    box-shadow: 2px 2px 3px #00000033;
    color: black;
    margin-left: 90%;
    font-family: gilroy-medium, sans-serif;
}
#pdf-download {
    display: none;
}
.docViewerBtn:hover,
.docViewerBtn:active,
.docViewerBtn:focus {
    background-color: #000;
    box-shadow: 2px 2px 3px #00000033;
    color: #fff;
    margin-left: 90%;
    font-family: gilroy-medium, sans-serif;
}
.withdraw_fund {
    color: #75ca70;
    font-family: 'Gilroy-Bold';
    font-size: 22px;
    margin-left: 8px;
}
.Add_New_BA {
    color: #2f8fff;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    margin-left: 70px;
}
.modal_input {
    margin-top: 25px;
    input {
        @include BeforeValue;
        margin-bottom: 10px !important;
        &:valid,
        &:focus-visible,
        &:focus-within,
        &:focus {
            @include AfterValue;
        }
    }
    label {
        color: #2f8fff;
        @include LabelDesign;
    }
}
.modal_select {
    label {
        color: #2f8fff;
        @include LabelDesign;
    }
    select {
        width: 100%;
        @include BeforeValue;
        &:valid,
        &:focus-visible,
        &:focus-within,
        &:focus,
        & * {
            @include AfterValue;
        }
    }
}
.confirm_WD {
    border-radius: 8px;
    background-color: #f9fafc;
    padding: 20px;
    margin-top: 25px;
    margin-bottom: 40px;
    .confirm_WD_values {
        display: flex;
        align-items: center;
        margin: 15px 0;
        &:nth-child(1) {
            margin-top: 0px;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        div {
            font-family: 'Gilroy-Medium';
            font-size: 14px;
            &:nth-child(1) {
                color: #999999;
                width: 40%;
            }
            &:nth-child(2) {
                color: #000000;
                width: 60%;
            }
        }
    }
}
.UseNetBank {
    text-align: center;
    font-family: 'Gilroy-Medium';
    color: #919191;
    font-size: 10px;
    margin: 20px 0 5px 0;
}
.Payvia {
    color: #2f8fff;
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
    margin: 25px auto 10px auto;
    width: max-content;
    &:hover {
        color: #002b78;
    }
}
.depositViaBT {
    text-align: center;
    font-family: 'Gilroy-Bold';
    font-size: 12px;
    margin: 20px 0;
}
.depositViaNB {
    font-family: 'Gilroy-Bold';
    font-size: 12px;
    margin: 25px auto 10px auto;
    width: max-content;
    span {
        color: #2f8fff;
        &:hover {
            color: #002b78;
        }
    }
}
.ViaBT {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    &:nth-child(1) {
        margin-top: 0px;
    }
    &:last-child {
        margin-bottom: 0px;
    }
    div {
        font-family: 'Gilroy-Medium';
        font-size: 12px;
        &:nth-child(1) {
            color: #999999;
            width: 40%;
        }
        &:nth-child(2) {
            font-family: 'Gilroy-SemiBold';
            width: 60%;
        }
    }
}
.modal-submit-btn-investor,
.modal-submit-btn-investee {
    border-radius: 4px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
}
.modal-submit-btn-investee {
    background-color: #4c8dff;
}
.modal-submit-btn-investee:hover {
    background-color: #002b78;
}
.modal-submit-btn-investor {
    background-color: #521b97;
}
.modal-submit-btn-investor:hover {
    background-color: #7741f2;
}
.InviteTeamModalNew {
    .modal-content {
        width: 380px;
        margin: auto;
    }

    .modal-dialog {
        margin-top: 8%;
    }
}
.InviteTeamModalNew {
    #inviteRole {
        border: none;
        background-color: white !important;
    }
}

.KYCModalNew {
    .modal-content {
        width: 610px;
        margin: auto;
    }
    .modal-header {
        color: #1c1c1c;
        font-family: 'Gilroy-Bold';
        font-size: 16px;
        padding: 30px;
    }
    .modal-body {
        padding: 10px 30px 30px 30px;
    }
    .BodyPadding {
        padding: 0 2px;
    }
    .Subheading {
        font-family: 'Gilroy-SemiBold';
        font-size: 16px;
    }
    .smalltext {
        color: #696969;
        font-family: 'Gilroy-Medium';
        font-size: 10px;
        margin-bottom: 30px;
    }
    .KYCFor {
        color: #000000;
        font-family: 'Gilroy-Medium';
        font-size: 10px;
    }
    .Header2ndLine {
        color: #696969;
        font-family: 'Gilroy-Medium';
        font-size: 10px;
        margin: 5px 0 0px 0;
    }
}
.AdditionalDataModal {
    .modal-content {
        width: 610px;
        margin: auto;
        max-height: 500px;
        overflow: scroll;
        .modal-header {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 999999;
            background: white;
        }
    }
    .Header1stText {
        font-family: 'Gilroy-SemiBold';
        font-size: 16px;
    }
    .Header2ndText {
        color: #696969;
        font-family: 'Gilroy-Medium';
        font-size: 10px;
        margin: 5px 0 10px 0;
    }
    .AdditionDataModalSelect {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        padding-right: 3%;
        text-align: center;
        min-height: 48px;
        height: 100%;
        width: 265px;
        text-align: left;
        padding-left: 3%;
        font-family: gilroy-semibold, sans-serif;
        font-size: 12px;
        color: #000000;
        border-radius: 6px;
        border: 0.5px solid #cecece;
        background: url('../../public/assets/select-dropdown.svg') no-repeat;
        background-size: 10px 12px;
        background-position-x: 95% !important;
        background-position-y: 50% !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &:focus,
        &:active,
        &:focus-visible {
            border: 0.5px solid #2f8fff;
        }
        &:disabled {
            background: transparent;
            background-color: #f9f9f9;
            border: 0.5px solid #cecece;
            color: #c3c3c3;
        }
    }
    .AdditionDataModalInput {
        border: 0.5px solid #d6d0d0;
        border-radius: 6px;
        background-color: #f9f9f9;
        width: 265px !important;
        color: #adadad;
        font-family: 'Gilroy-Medium';
        font-size: 10px;
        margin-bottom: 0px !important;
        padding: 12px;
        &:valid,
        &:focus {
            color: black;
            font-family: 'Gilroy-SemiBold';
            border: 0.5px solid #2f8fff;
            background-color: #ffffff;
        }
        &:disabled {
            border: 0.5px solid #f9f9f9;
        }
    }
    .AdditionDataModalUpload {
        width: 265px;
    }
    .additionalUploadedValue {
        width: 100%;
    }
    .AdditionDocModalBtn {
        color: #4c8dff;
        font-family: 'Gilroy-Medium';
        font-size: 12px;
        background-color: #ffffff;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
        padding: 9px;
        border: none;
        border-radius: 6px;
    }
}
.AuthSignatoryModal {
    .modal-body {
        padding: 0 30px 30px 30px;
    }
    .modal-content {
        width: 538px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0 1px 10px 0 rgba(112, 144, 176, 0.16);
    }
    .modal-header {
        padding: 30px 30px 0 30px;
    }
    .modal-title {
        color: #000000;
        font-family: 'Gilroy-Bold';
        font-size: 16px;
    }
    .Sub_Para {
        color: #696969;
        font-family: 'Gilroy-Medium';
        font-size: 10px;
        margin-bottom: 30px;
    }
    .Learn_More {
        color: #4b8dff;
        cursor: pointer;
    }
    .Check_Box {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        color: #8a8a8a;
        font-family: 'Gilroy-Medium';
        font-size: 12px;
        input {
            margin: 0 10px 0 0;
            width: 14px !important;
            height: 14px;
            border-radius: 1px;
        }
        label {
            width: max-content !important;
            margin-bottom: 0px;
        }
    }
    .Proceed_btn {
        background: linear-gradient(213.68deg, #3c89ff 0%, #4c8dff 5.89%, #f700f3 100%);
        color: #ffffff;
        font-family: 'Gilroy-SemiBold';
        font-size: 14px;
        width: 100%;
        height: 44px;
        border: none;
        border-radius: 6px;
    }
}
.AddBOModal {
    .modal-content {
        width: 330px;
        margin: auto;
    }
    .modal-title {
        color: #000000;
        font-family: 'Gilroy-Bold';
        font-size: 21px;
    }
    .smallText {
        margin-top: 10px;
        color: #696969;
        font-family: 'Gilroy-Medium';
        font-size: 10px;
        span {
            font-family: 'Gilroy-Bold';
        }
    }
    .modal-footer {
        padding: 0 1.5rem 1.5rem 1.5rem;
        border: 0;
        button {
            margin: 0px;
            height: 44px;
            width: 100%;
            border: none;
            color: #ffffff;
            font-family: 'Gilroy-SemiBold';
            font-size: 12px;
            border-radius: 6px;
            background-color: #2f8fff;
        }
    }
    input {
        margin-top: 10px;
    }
}
.FetchGstModal {
    .modal-content {
        width: 330px;
        margin: auto;
    }
    .modal-title {
        color: #1c1c1c;
        font-family: 'Gilroy-Bold';
        font-size: 16px;
    }
    .Place {
        margin: 35px 0px;
        color: #000000;
        font-family: 'Gilroy-SemiBold';
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        span {
            color: #aeaeae;
            font-family: 'Gilroy-Medium';
        }
    }
    .RequestOTP {
        @include Btn;
        border: 1px solid #4b8dff;
        background-color: #ffffff;
        color: #4c8dff;
    }
    .RequestOTPDis {
        @include Btn;
        color: #aaaaaa;
        background-color: white;
        border: 1px solid #aaaaaa;
    }
    .Proceed {
        @include Btn;
        background-color: #2f8fff;
        border: none;
        color: white;
    }
    .ProceedDis {
        @include Btn;
        background-color: #aaaaaa;
        border: none;
        color: white;
        cursor: not-allowed;
    }
    .FormLabel {
        @include ModalLabel;
        margin-top: -66px;
    }
    .FormInput {
        @include ModalInput;
        margin-bottom: 35px;
        &:focus ~ .FormLabel,
        &:valid ~ .FormLabel {
            margin-top: -93px;
            left: 24px;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }
    }
}
.FormLabel {
    @include ModalLabel;
    margin-top: -46px;
}
.SelectLabel {
    left: 0;
    font-size: 10px;
    color: #8d8d8d;
    font-weight: 300;
}
.FormSelect,
.FormInput {
    @include ModalInput;
    margin-bottom: 25px;
    &:focus ~ .FormLabel,
    &:valid ~ .FormLabel {
        margin-top: -74px;
        left: 24px;
        font-size: 10px;
        color: #8d8d8d;
        font-weight: 300;
    }
}

.LimitAddOnModal {
    .modal-content {
        width: 330px;
        margin: auto;
    }

    .modal-body {
        padding-bottom: 10px;
    }

    .AddOnReq {
        color: #20b92e;
        font-family: 'Gilroy-Bold';
        font-size: 26px;
    }

    .AmountToWord {
        height: 10px;
        color: #2f8fff;
        font-family: 'Gilroy-Medium';
        font-size: 8px;
        text-align: left;

        &:nth-child(4) {
            color: #8d8d8d;
        }
    }

    input {
        color: #adadad;
        font-family: 'Gilroy-Medium';
        padding: 10px;
        font-size: 12px;
        height: 44px;
        width: 100%;
        background-color: #f9f9f9;
        border: 0.5px solid #f9f9f9;
        border-radius: 6px;

        &:valid {
            color: #000000;
            font-family: 'Gilroy-SemiBold' !important;
            background-color: white;
            border: 0.5px solid #f9f9f9;
        }
        &:focus {
            color: #000000;
            font-family: 'Gilroy-SemiBold' !important;
            background-color: white;
            border: 0.5px solid #2f8fff !important;
        }
        &:active {
            border: 0.5px solid #f9f9f9;
        }
    }
}

.modalSubscript {
    margin-top: 10px;
    color: #aaaaaa;
    font-family: 'gilroy';
    font-size: 12px;
}

.DealDocModal {
    .modal-content {
        width: 560px;
        margin: auto;
    }
}

// multiple tot modal (Select Trading Terms)
.selected-tot-modal {
    .modal-content {
        width: 900px;
        margin-left: -150px;
        padding: 10px;
        background: #f9f9f9;
        box-shadow: 0px 1px 10px rgba(112, 144, 176, 0.16);
        border-radius: 8px;
    }
}

.single-tot-modal {
    .modal-content {
        width: 550px;
        padding: 10px;
        background: #f9f9f9;
        box-shadow: 0px 1px 10px rgba(112, 144, 176, 0.16);
        border-radius: 8px;
    }
}

@media only screen and (max-width: 768px) {
    .modal_input {
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .LimitAddOnModal {
        width: 90%;
        margin: 0 auto;
        top: 10%;
        left: 0;
        right: 0;
        transform: none;
    }

    .modal-dialog {
        margin: 0;
        max-width: 100%;
    }

    .modal_input,
    .modal_select {
        width: 100%;
    }

    .ModalFooter {
        padding: 20px;
    }
}

@media (max-width: 576px) {
    .LimitAddOnModal {
        width: 100%;
        top: 5%;
        padding: 10px;
    }
}
